import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

CreateOrderModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default function CreateOrderModal({ onSubmit }) {
  const [phone, setPhone] = useState('');

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit(phone);
      }}
      style={{ paddingBottom: '16px' }}>
      <TextField
        fullWidth
        label=" Номер телефона"
        value={phone}
        onChange={e => setPhone(e.target.value)}
        style={{ marginBottom: '24px' }}
      />
      <Button
        fullWidth
        type="submit"
        disabled={phone.length < 9}
        variant="contained"
        color="primary">
        Создать заказ
      </Button>
    </form>
  );
}
