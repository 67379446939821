import React, { useState } from 'react';
import { Field } from 'redux-form';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Autosuggest from 'lib/react-autosuggest';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.fullTitle, query);
  const parts = parse(suggestion.fullTitle, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span
            key={part.id}
            style={{
              fontWeight: part.highlight ? 500 : 400,
              color: part.highlight ? 'var(--color-1)' : 'inherit',
            }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.fullTitle;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    maxHeight: '300px',
    overflow: 'auto',
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function IntegrationAutosuggest({ getData, inputProps }) {
  const classes = useStyles();
  const [valueText, setValueText] = useState(inputProps.value || '');

  const [stateSuggestions, setSuggestions] = useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    getData(value).then(data => {
      setSuggestions(data);
    });
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = (event, { newValue }) => {
    if (!newValue) {
      inputProps.onChange('');
    }
    setValueText(newValue);
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected: (e, { suggestion, suggestionValue }) => {
      inputProps.onChange(suggestionValue);
    },
  };

  const removingProps = ({ onChange, ...rest }) => rest;

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          ...removingProps(inputProps),
          value: valueText,
          onChange: handleChange,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} style={{ position: 'absolute' }} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
}

const renderAutocompleteField = ({
  label,
  input,
  getData,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <IntegrationAutosuggest
    getData={getData}
    inputProps={{
      label,
      placeholder: label,
      error: touched && invalid,
      helperText: touched && error,
      margin: 'normal',
      ...input,
      ...custom,
    }}
  />
);

export default props => <Field component={renderAutocompleteField} {...props} />;
