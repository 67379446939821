import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import modalManager from '../../../utils/modalManager';
import { closeModal } from '../../../modules/Modal';
import SingleModal from './SingleModal';

const ModalUniversal = ({ modalsState, closeModalAction }) =>
  modalsState
    ? Object.keys(modalsState).map(key => {
        const { options } = modalManager.modals[modalsState[key].modalId];
        const { open, modalId } = modalsState[key];
        return (
          <SingleModal
            key={modalId}
            modalId={modalId}
            open={open}
            options={options}
            closeModalAction={closeModalAction}
          />
        );
      })
    : null;

ModalUniversal.propTypes = {
  closeModalAction: PropTypes.func.isRequired,
  modalsState: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ modal }) => ({ modalsState: modal });

const mapDispatchToProps = {
  closeModalAction: closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalUniversal);
