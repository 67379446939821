import { toastr } from 'lib/react-redux-toastr';
import EventBus from 'eventing-bus';
import isMobile from 'ismobilejs';
import { getFetchWrapper, postFetchWrapper, errorActionHandler } from '../utils/apiHelper';
import wsManager from '../utils/websockets';
import history from '../store/history';
import UserProfilesStorage from '../database/UserProfilesStorage';
import historyManager from '../utils/historyManager';
import {
  ADD_RELATION,
  ADD_APPEAL,
  CLOSE_APPEAL,
  CREATE_RELATION,
  CLOSE_RELATION,
  JIVOSITE_GET_APPEAL,
  ATTACH_APPEAL,
  ADD_CHAT_MESSAGE,
} from '../config/eventsList';
import Item from './chat-whatsapp-instances/route';

const MAX_MESSAGE_APPEAL = 100;

const SET_COLOR_THEME = 'SET_COLOR_THEME';
const SET_MENU_POSITION = 'SET_MENU_POSITION';
const SET_THEME = 'SET_THEME';
const MENU_IS_OPEN = 'MENU_IS_OPEN';
const COLOR = 'COLOR';
const THEME = 'THEME';
const SET_ANIMATE_ACTIVITY = 'SET_ANIMATE_ACTIVITY';
const SET_MANAGER_LIST = 'SET_MANAGER_LIST';
const SET_STOREKEEPERS_LIST = 'SET_STOREKEEPERS_LIST';
const SYS_WS_STATUS = 'SYS_WS_STATUS';
export const ANIMATE_ACTIVITY = 'ANIMATE_ACTIVITY';
export const MESSAGE_APPEAL_ADD = 'MESSAGE_APPEAL_ADD';
export const REMINDER_ADD = 'REMINDER_ADD';
export const REMINDERS_ADD = 'REMINDERS_ADD';
export const REMINDER_DELETE = 'REMINDER_DELETE';
export const INIT_APPEAL_ADD = 'INIT_APPEAL_ADD';
export const INIT_APPEAL_AUTOTAKE_SOURCES = 'INIT_APPEAL_AUTOTAKE_SOURCES';
export const MESSAGE_APPEAL_DELETE = 'MESSAGE_APPEAL_DELETE';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const UPDATE_CANNED_PHRASE = 'emailWriter/updateCannedPhrase';

const initialState = {
  color: localStorage.getItem(COLOR) || '#83b0ff',
  theme: localStorage.getItem(THEME) || 'dark',
  menuIsOpen: localStorage.getItem(MENU_IS_OPEN) || true,
  animation: localStorage.getItem(ANIMATE_ACTIVITY) || true,
  managerList: {},
  storekeepersList: {},
  messageAppeal: [],
  autoTakeSources: [],
  reminders: [],
  sysWS: false,
  isMobile: isMobile(window.navigator).any,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      };

    case SYS_WS_STATUS:
      return {
        ...state,
        sysWS: payload,
      };

    case SET_COLOR_THEME:
      return {
        ...state,
        color: payload,
      };

    case INIT_APPEAL_AUTOTAKE_SOURCES:
      return {
        ...state,
        autoTakeSources: payload,
      };

    case SET_THEME:
      return {
        ...state,
        theme: payload,
      };

    case SET_ANIMATE_ACTIVITY:
      return {
        ...state,
        animation: payload,
      };

    case SET_MENU_POSITION:
      return {
        ...state,
        menuIsOpen: payload,
      };

    case SET_MANAGER_LIST:
      return {
        ...state,
        managerList: payload,
      };

    case SET_STOREKEEPERS_LIST:
      return {
        ...state,
        storekeepersList: payload,
      };

    case INIT_APPEAL_ADD:
      return {
        ...state,
        messageAppeal: payload,
      };

    case MESSAGE_APPEAL_ADD:
      return {
        ...state,
        messageAppeal:
          state.messageAppeal.length > MAX_MESSAGE_APPEAL
            ? [...state.messageAppeal.slice(1), payload]
            : [...state.messageAppeal, payload],
      };

    case REMINDER_ADD:
      return {
        ...state,
        reminders: [...state.reminders, payload],
      };

    case REMINDERS_ADD:
      return {
        ...state,
        reminders: payload,
      };

    case MESSAGE_APPEAL_DELETE: {
      const ind = state.messageAppeal.findIndex(item => item.appealId === payload);

      if (ind !== -1) {
        const msAppeal = [...state.messageAppeal];
        msAppeal.splice(ind, 1);

        return {
          ...state,
          messageAppeal: msAppeal,
        };
      }

      return {
        ...state,
      };
    }

    case REMINDER_DELETE: {
      const ind = state.reminders.findIndex(item => item.notificationId === payload);

      if (ind !== -1) {
        const rmReminds = [...state.reminders];
        rmReminds.splice(ind, 1);

        return {
          ...state,
          reminders: rmReminds,
        };
      }

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export const getActiveReminders = () => dispatch =>
  getFetchWrapper('/notifications/active')
    .then(data => dispatch({ type: REMINDERS_ADD, payload: data }))
    .catch(errorActionHandler(dispatch, '/notifications/active'));

export const addReminder = data => dispatch =>
  postFetchWrapper('/notifications/add', data).catch(
    errorActionHandler(dispatch, '/notifications/add'),
  );

export const transferReminderToArhive = reminder => dispatch =>
  postFetchWrapper(`/notifications/${reminder.notificationId}/archive`)
    .then(data => {
      dispatch({ type: REMINDER_DELETE, payload: reminder.notificationId });
      historyManager.saveHistory('reminders', {
        ...reminder,
        id: reminder.notificationId,
        date: reminder.activationTime,
      });
      toastr.success('Успех!', 'Напоминание убрано в архив');
    })
    .catch(errorActionHandler(dispatch, `/notifications/${reminder.notificationId}/archive`));

export const relationAttach = appeal => (dispatch, getState) => {
  if (window.location.pathname !== '/crm/next') {
    history.push('/crm/next');
  }

  EventBus.publish(ADD_RELATION, appeal, dispatch, getState);

  return true;
};

export const deleteReminders = id => dispatch => {
  dispatch({
    type: REMINDER_DELETE,
    payload: id,
  });
};

const connectWS = () =>
  wsManager.registerWebsocket(
    'system',
    process.env.REACT_APP_SYSTEM_WEBSOCKET,
    JSON.stringify({ apiKey: localStorage.getItem('USER_KEY') || '' }),
  );

// const mockData = {
//   type: 'someType',
//   payload: someData
// }
const actionWs = (websocket, dispatch, timeout = 1000, getState) => {
  let ws = websocket;
  let message = 0;
  ws.onclose = () => {
    dispatch({ type: SYS_WS_STATUS, payload: false });
    wsManager.unRegisterWebsocket(process.env.REACT_APP_SYSTEM_WEBSOCKET);

    setTimeout(() => {
      ws = connectWS();

      actionWs(ws, dispatch, message || timeout * 2, getState);
    }, timeout);
  };

  ws.onmessage = res => {
    message = 1000;

    if (res.data === '.') {
      ws.send('.');
      return;
    }

    let data = null;

    try {
      data = JSON.parse(res.data.split(/\r?\n/)[0]);
    } catch (error) {
      console.error(error);
    }

    if (data.module === 'crm') {
      console.log(`====> crm WS! action: ${data.action}`, data);
    }

    if (data.module !== 'widget') {
      // console.info('ws message ==>', data);
    }

    if (data.statusCode === 200) {
      dispatch({ type: SYS_WS_STATUS, payload: true });
      return;
    }

    if (data.payload.source === 'phones') {
      return;
    }

    if (data.payload.appealId && data.payload.action === 'new') {
      if (data.payload.source === 'jivochat') {
        pushNotification(
          'Jivochat',
          {
            tag: 'jivochat',
            body: data.payload.message,
          },
          { data: data.payload },
          dispatch,
        );
      }

      dispatch({ type: MESSAGE_APPEAL_ADD, payload: data.payload });

      const isAutoTake = localStorage.getItem('IS_AUTO_TAKE')
        ? JSON.parse(localStorage.getItem('IS_AUTO_TAKE'))
        : false;

      if (data.payload.source.includes(getState().system.autoTakeSources) && isAutoTake) {
        EventBus.publish(ADD_RELATION, data.payload, dispatch, getState);
      }

      return;
    }

    if (data.module === 'emailWriter' && data.action === 'updateCannedPhrase') {
      dispatch({ type: `${data.module}/${data.action}`, payload: data.payload });
      return;
    }

    if (data.payload.appealId && data.payload.action === 'open') {
      dispatch({ type: MESSAGE_APPEAL_DELETE, payload: data.payload.appealId });
      return;
    }

    // eslint-disable-next-line no-unused-vars
    const moduleEvents = {
      new: [], // jivochat??
      crm: [
        'addAppeal', // пришел новый апил - входящий или исходящий
        'closeAppeal', // апил отмечен как отвеченный
        'createRelation', // пришло новое прикрепление клиента к менеджеру (может не к текущему менеджеру)
        'updateRelation', // чето обновилось в прикреплении, надо уточнить
        'closeRelation', // клиент откреплен от менеджера
        'addChatMessage', // пришло новое сообщение в апил (апил хз какой, может он и не отображен на фронте в данный моменто)
        'addClientAccount', // у клиента появился новый аккаунт
        'mergeRelatedClients', // инфо о слиянии клиентов (пока оставить)
        'relatedOrder', // ордер привязанный к клиенту обновился или появился. Он не вызывается из парсилки ордеров. Это нужно дописать на беке.
      ],
      header: [
        'attachAppeal', // ?????
      ],
      emailWriter: ['updateCannedPhrase'],
      widget: [
        'email_appeals_count',
        'call_task_counter_orders_ct',
        'call_task_counter_replacement_ct',
        'call_task_counter_click_ct',
        'call_task_counter_undelivered_ct',
        'call_task_counter_withdraw_ct',
      ],
      custom: [
        // приходит без типа....
        // bus
        'relatedChatMessageEvent', // уведомление о сообщении в системный сокет
        'refresh_instagram_media',
        'refresh_instagram_push_setting',
      ],
      notifications: [
        'notification_catch', //  напоминания
      ],
    };

    // console.info(`ws: ${data.module}/${data.action}`, data.payload);

    if (data.action === 'addChatMessage') {
      EventBus.publish(ADD_CHAT_MESSAGE, data.payload, dispatch, getState);
      return;
    }

    if (data.action === 'addAppeal') {
      EventBus.publish(ADD_APPEAL, data.payload.appealId, dispatch, getState);
      return;
    }

    if (data.action === 'closeAppeal') {
      // fromSocketUpdateAppealInRelation(data.payload)(dispatch);
      EventBus.publish(CLOSE_APPEAL, data.payload, dispatch, getState);
      return;
    }

    if (data.action === 'createRelation') {
      EventBus.publish(CREATE_RELATION, data.payload.relation, dispatch, getState);
      // fromSocketCreateRelation(data.payload.relation)(dispatch);
      return;
    }

    if (data.action === 'closeRelation') {
      EventBus.publish(CLOSE_RELATION, data.payload.relation, dispatch, getState);
      return;
    }

    if (data.action === 'updateRelation') {
      EventBus.publish(CLOSE_RELATION, data.payload.relation, dispatch, getState);
      EventBus.publish(CREATE_RELATION, data.payload.relation, dispatch, getState);
      // EventBus.publish(UPDATE_RELATION, data.payload.relation, dispatch, getState);
    }

    if (data.module === 'widget') {
      EventBus.publish(data.action, data.payload);
    }

    if (data.module === 'bus') {
      EventBus.publish(data.action, data.payload, dispatch, getState);
    }

    // Test
    if (data.module === 'crm') {
      EventBus.publish(data.action, data.payload, dispatch, getState);
    }

    if (data.module === 'notifications') {
      dispatch({ type: REMINDER_ADD, payload: data.payload });
      // EventBus.publish(data.action, data.payload, dispatch, getState);
    }
  };
};

const pushNotification = (name, options, data, dispatch) => {
  const notification = new Notification(name, options);
  notification.onclick = e => {
    e.preventDefault();
    history.push('/crm');
    EventBus.publish(JIVOSITE_GET_APPEAL, data.payload.appealId);
  };
};

export const connectSysWS = () => (dispatch, getState) => {
  const ws = connectWS();

  if (ws) {
    actionWs(ws, dispatch, 1000, getState);
  }
};

/**
 *
 * @param {string} color
 */
export const setsystem = color => dispatch => {
  dispatch({
    type: SET_COLOR_THEME,
    payload: color,
  });

  localStorage.setItem(COLOR, color);
};

/**
 *
 * @param {string} theme
 */
export const setTheme = theme => dispatch => {
  dispatch({
    type: SET_THEME,
    payload: theme,
  });

  localStorage.setItem(THEME, theme);
};

/**
 *
 * @param {string} status
 */
export const setAnimation = status => dispatch => {
  dispatch({
    type: SET_ANIMATE_ACTIVITY,
    payload: status,
  });

  localStorage.setItem(ANIMATE_ACTIVITY, status);
};

/**
 *
 */
export const getMenu = () => dispatch =>
  getFetchWrapper('/app/menu')
    .then(data => data)
    .catch(errorActionHandler(dispatch, '/app/menu'));

/**
 *
 * @param isOpen
 */
export const setMenuPosition = isOpen => dispatch => {
  dispatch({
    type: SET_MENU_POSITION,
    payload: isOpen || 'close',
  });

  localStorage.setItem(MENU_IS_OPEN, isOpen || 'close');
};

export const getManagersList = () => dispatch =>
  getFetchWrapper('/user/managers/list')
    .then(data => {
      UserProfilesStorage.bulkGet(data).then(managerNames => {
        const managers = {};

        data.forEach(manager => {
          if (managerNames.find(n => n.managerId === manager)) {
            managers[manager] = managerNames.find(n => n.managerId === manager).managerName;
          }
        });

        dispatch({
          type: SET_MANAGER_LIST,
          payload: managers,
        });
      });

      return data;
    })
    .catch(errorActionHandler(dispatch, '/user/managers/list'));

export const getChats = () => dispatch =>
  getFetchWrapper('/crm/appeals/store/subscribed').then(payload =>
    dispatch({ type: INIT_APPEAL_ADD, payload }),
  );

export const getAutoTakeSources = () => dispatch =>
  getFetchWrapper('/crm/appeals/store/autotake').then(payload =>
    dispatch({ type: INIT_APPEAL_AUTOTAKE_SOURCES, payload }),
  );

/**
 * @returns {function(*=): *}
 */
export const getStorekeepersList = () => dispatch =>
  getFetchWrapper('/user/storekeepers/list')
    .then(data => {
      UserProfilesStorage.bulkGet(data).then(managerNames => {
        const storekeepers = {};

        data.forEach(manager => {
          if (managerNames.find(n => n.managerId === manager)) {
            storekeepers[manager] = managerNames.find(n => n.managerId === manager).managerName;
          }
        });

        dispatch({
          type: SET_STOREKEEPERS_LIST,
          payload: storekeepers,
        });
      });
    })
    .catch(errorActionHandler(dispatch, '/user/storekeepers/list'));

/**
 * @param {string} appealId appealId
 */
export const getClientIdByAppealId = appealId => dispatch =>
  getFetchWrapper(`/crm/appeal/${appealId}`)
    .then(data => data.clientId)
    .catch(err => errorActionHandler(dispatch, `/crm/appeal/${appealId}`));

/**
 * TODO: доработать!
 * @param appealId
 */
export const attachAppeal = appealId => (dispatch, getState) => {
  if (window.location.pathname === '/crm/next') {
    return new Promise(resolve => {
      EventBus.publish(ATTACH_APPEAL, appealId, dispatch, getState);
      resolve(true);
    });
  }
  return postFetchWrapper(`/crm/relation/attach/${appealId}`)
    .then(res => {
      toastr.success('Успех!', 'Обращение успешно закреплено');
      history.push('/crm/next');

      return true;
    })
    .catch(errorActionHandler(dispatch, '/crm/relation/attach'));
};

export const getPermissions = () => dispatch =>
  getFetchWrapper('/access-user')
    .then(res => {
      dispatch({
        type: SET_PERMISSIONS,
        payload: res.reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: { permission: item.permission, resource: item.resource },
          }),
          {},
        ),
      });
    })
    .catch(errorActionHandler(dispatch, '/access-user'));
