import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import routes from '../../../modules';
import NestedList from './NestedList';
import icons from './icons';
import './asideNew.scss';

const useStyles = makeStyles(theme => ({
  listItemRoot: {
    padding: '0 5px',
  },
}));

ListItemAside.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
};

export default function ListItemAside({ item, index }) {
  const classes = useStyles();

  const renderUrl = urlId => {
    if (routes[urlId]) {
      return routes[urlId].url || routes[urlId].route;
    }
    return '/';
  };

  if (item.children.length) {
    return (
      <NestedList
        key={item.url}
        url={item.url}
        label={item.label}
        renderUrl={renderUrl}
        data={item.children}
      />
    );
  }

  return (
    <ListItem button>
      <NavLink
        className="sidebar__link-main"
        style={{ display: 'flex', width: '100%', alignItems: 'center' }}
        to={renderUrl(item.url)}>
        <ListItemIcon style={{ minWidth: 36, color: 'inherit' }}>
          {icons[item.url] || icons.default}
        </ListItemIcon>
        <ListItemText
          classes={{
            root: classes.listItemRoot,
          }}
          primary={item.label}
        />
      </NavLink>
    </ListItem>
  );
}
