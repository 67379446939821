import set from 'lodash/set';
import has from 'lodash/has';

const storage = localStorage.getItem('History');
const history = storage ? JSON.parse(storage) : {};
let instance = null;

class HistoryManager {
  constructor() {
    if (!instance) {
      console.info('init HistoryManager');
      instance = this;
    } else {
      console.info('History manager has been register!');
    }

    this.subscribers = {};

    return instance;
  }

  getHistory = name => history[name];

  getHistoryCategories = () => Object.keys(history);

  registerSubscriber = (category, name, cb) => {
    set(this.subscribers, [category, name], cb);

    return () => this.unRegisterSubscriber(category, name);
  };

  unRegisterSubscriber = (category, name) => {
    if (has(this.subscribers, [category, name])) {
      delete this.subscribers[category][name];
    } else {
      console.warn(`ERP: call history id - "${name} is not register in history manager"`);
    }
  };

  updateHistory = (category, updateValue) => {
    let index = -1;
    if (!history[category]) {
      history[category] = [];
    } else {
      index = history[category].findIndex(item => item.id === updateValue.id);
    }
    if (index > -1) {
      history[category][index] = { ...history[category][index], ...updateValue };
    } else {
      history[category].unshift(updateValue);
    }

    if (history[category].length > 50) {
      history[category].pop();
    }

    // history[category] = history[category].map(item =>
    //   item.id === updateValue.id ? { ...item, ...updateValue } : item,
    // );

    this.notifySubscribers(category);

    localStorage.setItem('History', JSON.stringify(history));
  };

  notifySubscribers = category => {
    for (const cb in this.subscribers[category]) {
      if (Object.prototype.hasOwnProperty.call(this.subscribers[category], cb)) {
        this.subscribers[category][cb](history[category]);
      }
    }
  };

  saveHistory = (category, value) => {
    if (!history[category]) {
      history[category] = [];
    }

    const historyItem = value;

    // проверка даты для старых записей
    if (!historyItem.date) {
      const d = new Date();

      historyItem.date = d.toString();
    }

    history[category].unshift(historyItem);

    if (history[category].length > 50) {
      history[category].pop();
    }

    this.notifySubscribers(category);

    localStorage.setItem('History', JSON.stringify(history));
  };
}

export default new HistoryManager();
