import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lightbox from '../../../lib/react-image-lightbox';
import '../../../lib/react-image-lightbox/style.css';
import { closePreview as closePreviewAction } from '../../../modules/AttachViewer';
import './attachments-viewer.scss';

const staticUrl = process.env.REACT_APP_STATIC_URL;

AttachmentsViewer.propTypes = {
  attachViewer: PropTypes.instanceOf(Object).isRequired,
  closePreview: PropTypes.func.isRequired,
};

function AttachmentsViewer({ attachViewer, closePreview }) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setPhotoIndex(attachViewer.currentImage);
    setImages(attachViewer.images);
  }, [attachViewer]);

  function handlePrev() {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  }

  function handleNext() {
    setPhotoIndex((photoIndex + 1) % images.length);
  }

  let path = '';

  if (images[(photoIndex + 0) % images.length]) {
    path = images[(photoIndex + 0) % images.length].includes('https') ? '' : staticUrl;
  }

  return attachViewer.open ? (
    <Lightbox
      mainSrc={`${path}${images[(photoIndex + 0) % images.length]}`}
      nextSrc={images.length > 1 ? `${path}${images[(photoIndex + 1) % images.length]}` : null}
      prevSrc={
        images.length > 1
          ? `${path}${images[(photoIndex + images.length - 1) % images.length]}`
          : null
      }
      onMovePrevRequest={images.length > 1 ? handlePrev : undefined}
      onMoveNextRequest={images.length > 1 ? handleNext : undefined}
      onCloseRequest={closePreview}
      enableRotate
    />
  ) : null;
}

const mapStateToProps = ({ attachViewer }) => ({ attachViewer });
const mapDispatchToProps = { closePreview: closePreviewAction };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttachmentsViewer);
