import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field } from 'redux-form';

function renderTextField({
  label,
  input,
  TextFieldProps,
  meta: { touched, invalid, error },
  ...custom
}) {
  const { data = [], getData, dataSourceConfig, onNewRequest, ...othersProps } = custom;

  const convertValue = val => {
    if (Array.isArray(val)) {
      const res = data.filter(i => val.includes(i[dataSourceConfig.value]));
      return res.map(i => i[dataSourceConfig.text]);
    }

    const res = data.find(i => i[dataSourceConfig.value] === val);
    return res ? res[dataSourceConfig.text] : undefined;
  };

  return (
    <>
      <Autocomplete
        clearText="Очистить"
        noOptionsText="Нет доступных вариантов"
        options={data}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option[dataSourceConfig.text]
        }
        renderOption={option => option[dataSourceConfig.text]}
        getOptionSelected={(option, val) => option[dataSourceConfig.text] === val}
        {...input}
        {...othersProps}
        value={convertValue(input.value)}
        onChange={(e, val) => {
          if (!val) {
            return null;
          }

          if (Array.isArray(val)) {
            return input.onChange(
              val.map(i => {
                if (i[dataSourceConfig.value]) {
                  return i[dataSourceConfig.value];
                }

                const textToVal = data.find(item => item[dataSourceConfig.text] === i);
                return textToVal[dataSourceConfig.value];
              }),
            );
          }

          input.onChange(val[dataSourceConfig.value]);
        }}
        onBlur={e => {
          const val = e.target.value;
          // input.onBlur(val ? val[dataSourceConfig.value] : null);
        }}
        renderInput={params => (
          <TextField
            label={label}
            error={touched && invalid}
            helperText={touched && error}
            margin="normal"
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            {...TextFieldProps}
          />
        )}
      />
    </>
  );
}

renderTextField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
};

renderTextField.defaultProps = {
  label: '',
  children: null,
};

export default props => <Field component={renderTextField} {...props} />;
