const GET_PREVIEW = 'GET_PREVIEW';
const CLOSE_PREVIEW = 'CLOSE_PREVIEW';

const initialState = {
  images: [],
  currentImage: 0,
  open: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PREVIEW:
      return {
        images: payload.images,
        currentImage: payload.currentImage,
        open: true,
        absolutePath: payload.absolutePath,
      };

    case CLOSE_PREVIEW:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

/**
 * @param imgUrl
 * @param images
 */
export const getPreview = (imgUrl, images) => dispatch => {
  dispatch({
    type: GET_PREVIEW,
    payload: {
      images: images || [imgUrl],
      currentImage: images ? images.indexOf(imgUrl) : 0,
    },
  });
};

export const closePreview = () => dispatch => dispatch({ type: CLOSE_PREVIEW });
