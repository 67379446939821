import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import DevBoard from '../dev-board';
import HotkeysInfo from '../../../modules/options/components/hotKeys';
import './help-panel.scss';

class HelpPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleToggle = () => this.setState(oldState => ({ open: !oldState.open }));

  render() {
    const debug = localStorage.getItem('DEBUG') === 'true';
    const dev = process.env.NODE_ENV === 'development';
    return (
      <>
        <div
          className="help-button"
          style={{ position: this.props.isMobile ? 'relative' : 'fixed' }}>
          <IconButton onClick={this.handleToggle}>
            <HelpIcon style={{ color: '#b9b9b9' }} />
          </IconButton>
        </div>
        <Drawer anchor="right" openSecondary open={this.state.open}>
          <div style={{ maxWidth: 800, width: '100%' }}>
            <IconButton
              style={{
                position: 'absolute',
                right: 0,
                bottom: 50,
              }}
              touch
              tooltipPosition="bottom-left"
              onClick={this.handleToggle}>
              <HelpIcon color="#b9b9b9" />
            </IconButton>
            <div style={{ height: '100%', marginRight: this.props.isMobile ? 0 : '56px' }}>
              {!debug || !dev ? <DevBoard /> : <HotkeysInfo />}
            </div>
          </div>
        </Drawer>
      </>
    );
  }
}

HelpPanel.propTypes = {
  isMobile: PropTypes.bool,
};

HelpPanel.defaultProps = {
  isMobile: false,
};

export default HelpPanel;
