import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import Raven from 'raven-js';
import store from 'store';
import history from 'store/history';
import App from 'pages/App/App';
import 'assets/styles/main.scss';
import Login from './pages/Login/Login';

if (
  process.env.NODE_ENV === 'production' &&
  localStorage.getItem('DISABLED_SENTRY_LOG') !== 'true'
) {
  Raven.config(process.env.REACT_APP_SENTRY_URL, {
    tags: {
      name: localStorage.getItem('USER_NAME') || 'noName',
      build: process.env.REACT_APP_BUILD || 'unknown',
    },
    release: process.env.REACT_APP_VERSION || 'unknown',
  }).install();
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/" component={location => <App history={history} location={location} />} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
// registerServiceWorker();
