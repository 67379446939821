import { getFetchWrapper, postFetchWrapper, errorActionHandler } from '../utils/apiHelper';
import history from '../store/history';

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_NAME = 'USER_NAME';
export const USER_KEY = 'USER_KEY';
export const USER_ID = 'USER_ID';

const initialState = {
  username: localStorage.getItem(USER_NAME) || '',
  userId: localStorage.getItem(USER_ID) || '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_LOGGED_IN:
      return {
        ...state,
        username: payload.username,
        userId: payload.userId,
      };

    case USER_LOGGED_OUT:
      return {
        username: '',
        userId: '',
        chatId: '',
      };

    default:
      return state;
  }
};

/**
 *
 * @param authData
 * @param location
 */
export const logIn = (authData, location = '/') => dispatch =>
  postFetchWrapper('/login', authData)
    .then(({ username, userId, apiKey }) => {
      localStorage.setItem(USER_KEY, apiKey);
      localStorage.setItem(USER_NAME, username);
      localStorage.setItem(USER_ID, userId);

      dispatch({ type: USER_LOGGED_IN, payload: { username, userId } });

      history.push(location);
    })
    .catch(errorActionHandler(dispatch));

/**
 *
 */
export const logOut = () => dispatch =>
  getFetchWrapper('/logout')
    .then(() => {
      localStorage.clear();

      dispatch({ type: USER_LOGGED_OUT });

      history.push('/login');
    })
    .catch(err => console.error(err));

export const clearUser = () => dispatch => {
  dispatch({ type: USER_LOGGED_OUT });
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(USER_NAME);
  localStorage.removeItem(USER_ID);
};
