import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { range, chunk } from 'lodash';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import './calendar.scss';

const Day = ({ i, w, d, className, ...props }) => {
  const prevMonth = w === 0 && i > 7;
  const nextMonth = w >= 4 && i <= 14;
  const cls = cx({
    'prev-month': prevMonth,
    'next-month': nextMonth,
    'current-day': !prevMonth && !nextMonth && i === d,
  });

  return (
    <td className={cls} {...props}>
      {i}
    </td>
  );
};

Day.propTypes = {
  className: PropTypes.string,
  i: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  d: PropTypes.number.isRequired,
};

Day.defaultProps = {
  className: '',
};

function Calendar({ moment, style, className, onChange }) {
  function selectDate(i, w) {
    if (w === 0 && i > 7) moment.subtract(1, 'month'); // prevMonth
    if (w >= 4 && i <= 14) moment.add(1, 'month'); // nextMonth

    moment.date(i);

    onChange(moment);
  }

  function prevMonth(e) {
    e.preventDefault();
    onChange(moment.subtract(1, 'month'));
  }

  function nextMonth(e) {
    e.preventDefault();
    onChange(moment.add(1, 'month'));
  }

  const d = moment.date();
  const d1 = moment
    .clone()
    .subtract(1, 'month')
    .endOf('month')
    .date();

  const d2 = moment
    .clone()
    .date(1)
    .day();

  const d3 = moment
    .clone()
    .endOf('month')
    .date();

  const days = [].concat(range(d1 - d2 + 1, d1 + 1), range(1, d3 + 1), range(1, 42 - d3 - d2 + 1));

  const weeks = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

  return (
    <div className={cx('m-calendar', className)} style={style}>
      <div className="toolbar">
        <button type="button" className="prev-month" onClick={prevMonth}>
          <LeftArrowIcon style={{ fill: '#fff', marginTop: '3px' }} />
        </button>
        <span className="current-date">{moment.format('MMMM YYYY')}</span>
        <button type="button" className="next-month" onClick={nextMonth}>
          <RightArrowIcon style={{ fill: '#fff', marginTop: '3px' }} />
        </button>
      </div>

      <table>
        <thead>
          <tr>
            {weeks.map(day => (
              <td key={day}>{day}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {chunk(days, 7).map((row, w) => (
            <tr key={w}>
              {row.map(i => (
                <Day key={i} i={i} d={d} w={w} onClick={() => selectDate(i, w)} />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

Calendar.propTypes = {
  moment: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
  className: '',
  style: null,
};

export default Calendar;
