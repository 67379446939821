import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import ReminderIcon from '@material-ui/icons/EventNote';
import ModalHellIcon from '@material-ui/icons/Accessibility';
import ReminderCloseIcon from '@material-ui/icons/EventBusy';
import EventBus from 'eventing-bus';
import Popover from '@material-ui/core/Popover';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import ModalReminders from 'components/composite/modal-reminders';
import modalManager from 'utils/modalManager';
import Preloader from '../../simple/preloader';
import dateMaker from '../../../utils/dataMaker';
import {
  relationAttach,
  getClientIdByAppealId,
  transferReminderToArhive as transferReminderToArhiveAction,
} from '../../../modules/System';
import './reminders.scss';
import { FIND_INFO } from '../../../config/eventsList';

const styles = {
  badgeStyle: {
    top: 1,
    right: 1,
    fontSize: '16px',
    minHeight: '22px',
    height: 'auto',
    minWidth: '22px',
    width: 'auto',
    fontWeight: 500,
    color: '#444',
    backgroundColor: 'var(--color-1)',
  },
  list: {
    maxHeight: '60vh',
    overflow: 'auto',
    padding: '0',
    backgroundColor: '#ff00002e',
  },
};

const useStyles = makeStyles(theme => ({
  badge: {
    top: '11px',
    right: '14px',
    fontSize: '14px',
    height: 'auto',
    minHeight: '18px',
    minWidth: '18px',
    width: 'auto',
    fontWeight: 900,
    color: '#111',
    backgroundColor: '#ff7055',
  },
  popover: {
    width: '450px',
  },
  list: {
    maxHeight: '60vh',
    overflow: 'auto',
    padding: '0',
  },
}));

const Reminders = props => {
  const { sysWS, transferReminderToArhive } = props;

  const [reminders, setReminders] = useState(props.reminders);
  const [open, setOpen] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const btn = useRef(null);

  useEffect(() => {
    if (reminders.length < props.reminders.length) {
      setOpen(true);
      setAnchorEl(btn.current);
    }

    setReminders(props.reminders);
  }, [props.reminders]);

  const classes = useStyles();

  const handleTouchTap = event => {
    if (!open) {
      setOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setOpen(false);
  };

  const handleTransferReminderToArhive = reminder => {
    setIsLoad(true);
    transferReminderToArhive(reminder).finally(() => setIsLoad(false));
  };

  const handleClickHellModal = phone => {
    setOpen(false);
    EventBus.publish(FIND_INFO, phone);
  };

  const changeReminder = oldReminder => newReminder => {
    return props.addReminder(newReminder).then(() => {
      handleTransferReminderToArhive(oldReminder);
      setOpen(true);
    });
  };

  const handleOpenReminders = reminder => {
    setOpen(false);
    modalManager.registerModal(
      'clientInfoModalReminders',
      <ModalReminders
        textDefault={reminder.message}
        clientId={reminder.marks.clientId}
        clientPhone={reminder.marks.clientPhone}
        closeModal={props.closeModal}
        addReminder={changeReminder(reminder)}
      />,
      {
        title: 'Изменить напоминание',
      },
    );

    props.openModal('clientInfoModalReminders');
  };

  const rightIconMenu = reminder => (
    <>
      {reminder.marks && reminder.marks.clientPhone && (
        <IconButton onClick={() => handleClickHellModal(reminder.marks.clientPhone)}>
          <ModalHellIcon />
        </IconButton>
      )}
      <Button color="primary" onClick={() => handleTransferReminderToArhive(reminder)}>
        В архив
      </Button>
    </>
  );

  if (!sysWS) {
    return (
      <Tooltip title="Напоминания. Соединение с сервером не установлено">
        <IconButton>
          <ReminderCloseIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <>
      <Badge
        style={{ cursor: 'pointer' }}
        color="secondary"
        badgeContent={reminders.length}
        classes={{ badge: classes.badge }}
        onClick={handleTouchTap}>
        <Tooltip title="Напоминания">
          <IconButton ref={btn} onClick={handleTouchTap}>
            {open ? (
              <CloseIcon className="search__icon" />
            ) : (
              <ReminderIcon className="search__icon" />
            )}
          </IconButton>
        </Tooltip>
      </Badge>
      <Popover
        open={open}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: classes.popover }}>
        {isLoad && <Preloader absolute background />}
        {reminders.length ? (
          <List className="scroll" dense style={styles.list}>
            {reminders.map((item, ind) => (
              <ListItem key={item.notificationId} button>
                <ListItemAvatar onClick={() => handleOpenReminders(item)}>
                  <Avatar alt="ava">
                    <ReminderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${item.message}`}
                  secondary={
                    <span
                      style={{
                        display: 'block',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}>
                      {dateMaker(item.activationTime)}
                    </span>
                  }
                />
                <ListItemSecondaryAction>{rightIconMenu(item)}</ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <p style={{ padding: '15px' }}>нет напоминаний</p>
        )}
      </Popover>
    </>
  );
};

Reminders.propTypes = {
  reminders: PropTypes.instanceOf(Object).isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  addReminder: PropTypes.func.isRequired,
  transferReminderToArhive: PropTypes.func.isRequired,
  sysWS: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ system: { reminders, sysWS, autoTakeSources } }) => ({
  reminders,
  sysWS,
  autoTakeSources,
});

const mapDispatchProps = {
  transferReminderToArhive: transferReminderToArhiveAction,
  getClientIdByAppealId,
  relationAttach,
};

export default connect(
  mapStateToProps,
  mapDispatchProps,
)(Reminders);
