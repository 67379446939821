import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const AnimationContainer = ({ animation, location, children }) =>
  animation ? (
    <TransitionGroup component="main" className="scroll">
      <CSSTransition
        key={location.pathname.split('/')[1]}
        timeout={200}
        classNames="anim"
        mountOnEnter
        unmountOnExit>
        {children}
      </CSSTransition>
    </TransitionGroup>
  ) : (
    <main className="scroll">{children}</main>
  );

AnimationContainer.propTypes = {
  animation: PropTypes.bool,
  location: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
};

AnimationContainer.defaultProps = {
  animation: false,
};

export default AnimationContainer;
//sms/{id}
