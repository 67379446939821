import Dexie from 'dexie';

const db = new Dexie('erp');

db.version(1).stores({
  orders:
    'orderId, orderNumber, clientId, orderType, orderMark, paymentStatus, processStatus, status, killMePleaseAt',
  user_profiles: 'managerId, managerName, killMePleaseAt',
});

db.version(2).stores({
  orders:
    'orderId, orderNumber, clientId, orderType, orderMark, paymentStatus, processStatus, status, killMePleaseAt',
  user_profiles: 'managerId, managerName, killMePleaseAt',
  instagram_posts: 'pk, id, userId, mediaType, killMePleaseAt',
});

export default db;
