import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ActionAccessibility = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <g>
      <path
        d="M22,0C9.869,0,0,9.869,0,22s9.869,22,22,22s22-9.869,22-22S34.131,0,22,0z M22,42C10.972,42,2,33.028,2,22S10.972,2,22,2
				s20,8.972,20,20S33.028,42,22,42z"
      />
      <path
        d="M9.707,20.192l3.536-3.536l3.536,3.536c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293
				c0.391-0.391,0.391-1.023,0-1.414l-3.536-3.536l3.536-3.536c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-3.536,3.536
				l-3.536-3.536c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l3.536,3.536l-3.536,3.536c-0.391,0.391-0.391,1.023,0,1.414
				C8.488,20.388,8.744,20.485,9,20.485S9.512,20.388,9.707,20.192z"
      />
      <path
        d="M35.707,10.293c-0.391-0.391-1.023-0.391-1.414,0l-3.536,3.536l-3.536-3.536c-0.391-0.391-1.023-0.391-1.414,0
				s-0.391,1.023,0,1.414l3.536,3.536l-3.536,3.536c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293
				s0.512-0.098,0.707-0.293l3.536-3.536l3.536,3.536c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293
				c0.391-0.391,0.391-1.023,0-1.414l-3.536-3.536l3.536-3.536C36.098,11.316,36.098,10.684,35.707,10.293z"
      />
      <path
        d="M32,26H10c-0.553,0-1,0.447-1,1s0.447,1,1,1h11v6.801c0,2.757,2.243,5,5,5h1c2.757,0,5-2.243,5-5V28c0.553,0,1-0.447,1-1
				S32.553,26,32,26z M30,34.801c0,1.654-1.346,3-3,3h-1c-1.654,0-3-1.346-3-3v-6.799l1.233,0.002
				c0.362,0.001,0.746,0.001,1.137,0.002c0.109,0.724,0.164,2.211,0.163,4.44v0.165c0,0.553,0.447,1,1,1s1-0.447,1-1v-0.164
				c0-0.9,0.001-2.262-0.067-3.435c-0.019-0.325-0.04-0.669-0.077-1.004c0.966,0.001,1.886,0.001,2.612-0.002V34.801z"
      />
    </g>
  </SvgIcon>
));

ActionAccessibility.displayName = 'ActionAccessibility';
ActionAccessibility.muiName = 'SvgIcon';

export default ActionAccessibility;
