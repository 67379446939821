import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';

const renderDateField = ({
  label,
  input,
  formatTime,
  meta: { touched, invalid, error },
  children,
  ...custom
}) => (
  <KeyboardTimePicker
    label={label || ''}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    format={formatTime}
    {...input}
    {...custom}>
    {children}
  </KeyboardTimePicker>
);

renderDateField.propTypes = {
  label: PropTypes.string,
  formatTime: PropTypes.string,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
};

renderDateField.defaultProps = {
  label: '',
  formatTime: '',
  children: null,
};

// перегрузка пропса format т.к. Field его перехватывает
export default ({ format, ...props }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
    <Field component={renderDateField} {...props} formatTime={format} />
  </MuiPickersUtilsProvider>
);
