import React from 'react';
import MailIcon from '@material-ui/icons/MailOutline';
import Phone from '@material-ui/icons/Phone';
import MailViewIcon from '@material-ui/icons/Drafts';
import CommentIcon from '@material-ui/icons/Comment';
import SmsIcon from '@material-ui/icons/Textsms';
import Telegram from '../../components/simple/icons/telegram';
import Viber from '../../components/simple/icons/viber';
import WhatsApp from '../../components/simple/icons/whatsapp';
import Instagram from '../../components/simple/icons/instagram';
import Facebook from '../../components/simple/icons/facebook';
import Vk from '../../components/simple/icons/vk';
import Jivosite from '../../components/simple/icons/jivosite';

let sourceList = null;

const icons = {
  emails: {
    title: 'Писемки',
    icon: MailIcon,
  },
  sms: {
    title: 'SMS',
    icon: SmsIcon,
  },
  phones: 'Звоночки',
  facebook: 'Facebook',
  telegram: 'Telegram',
  viber: 'Viber',
  whatsapp: 'Whatsapp',
  vkontakte: 'Vkontakte',
  instagram: 'Instagram',
  jivochat: 'Jivochat',
};

class SourceList {
  chatList = ['facebook', 'telegram', 'viber', 'whatsapp', 'vkontakte', 'instagram', 'jivochat'];

  sourceList = [
    'emails',
    'sms',
    'phones',
    'facebook',
    'telegram',
    'viber',
    'whatsapp',
    'vkontakte',
    'instagram',
    'jivochat',
  ];

  sourceNames = {
    emails: 'Писемки',
    sms: 'SMS',
    phones: 'Звоночки',
    facebook: 'Facebook',
    telegram: 'Telegram',
    viber: 'Viber',
    whatsapp: 'Whatsapp',
    vkontakte: 'Vkontakte',
    instagram: 'Instagram',
    jivochat: 'Jivochat',
  };

  icons = [
    {
      source: 'sms',
      replied: <SmsIcon />,
      default: <SmsIcon />,
    },
    {
      source: 'phones',
      replied: <Phone />,
      default: <Phone />,
    },
    {
      source: 'viber',
      replied: <Viber />,
      default: <Viber />,
    },
    {
      source: 'emails',
      replied: <MailViewIcon />,
      default: <MailIcon />,
    },
    {
      source: 'default',
      replied: <CommentIcon />,
      default: <CommentIcon />,
    },
    {
      source: 'facebook',
      replied: <Facebook />,
      default: <Facebook />,
    },
    {
      source: 'telegram',
      replied: <Telegram />,
      default: <Telegram />,
    },
    {
      source: 'whatsapp',
      replied: <WhatsApp />,
      default: <WhatsApp />,
    },
    {
      source: 'vkontakte',
      replied: <Vk />,
      default: <Vk />,
    },
    {
      source: 'instagram',
      replied: <Instagram />,
      default: <Instagram />,
    },
    {
      source: 'jivochat',
      replied: <Jivosite />,
      default: <Jivosite />,
    },
  ];

  constructor() {
    if (!sourceList) {
      sourceList = this;
    }

    return sourceList;
  }

  getChatList = () => this.chatList;

  getSourceList = () => this.sourceList;

  getSourceIcons = () => this.icons;

  getSourceIcon = (source, replied) => {
    const icon = this.icons.find(i => i.source === source);

    if (!icon) {
      const defaultIcon = this.icons.find(i => i.source === 'default');
      return replied ? defaultIcon.replied : defaultIcon.default;
    }

    return replied ? icon.replied : icon.default;
  };
}

export default new SourceList();
