import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { toastr } from 'lib/react-redux-toastr';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationIcon from '@material-ui/icons/NotificationsNone';
import NotificationAlertIcon from '@material-ui/icons/NotificationsPaused';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import dateMaker from '../../../utils/dataMaker';
import DeadIcon from '../../simple/icons/dead';
import {
  relationAttach as relationAttachAction,
  getClientIdByAppealId as getClientIdByAppealIdAction,
} from '../../../modules/System';
import history from '../../../store/history';
import Preloader from '../../simple/preloader';
import HotKey from '../hot-key';
import './notification.scss';
import SourceList from '../../../modules/crm/source.list';
// import { JIVOSITE_GET_APPEAL } from '../../../config/eventsList';

const useStyles = makeStyles(theme => ({
  badge: {
    top: '11px',
    right: '14px',
    fontSize: '14px',
    height: 'auto',
    minHeight: '18px',
    minWidth: '18px',
    width: 'auto',
    fontWeight: 900,
    color: '#111',
    backgroundColor: '#ff7055',
  },
  popover: {
    width: '450px',
  },
  list: {
    maxHeight: '60vh',
    overflow: 'auto',
    padding: '0',
  },
}));

const Notification = props => {
  const classes = useStyles();

  const { messageAppeal, sysWS, autoTakeSources, relationAttach, getClientIdByAppealId } = props;

  const autoTakeMassages = messageAppeal.filter(appeal => appeal.source.includes(autoTakeSources));

  const [open, setOpen] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // useEffect(() => {
  //   EventBus.on(JIVOSITE_GET_APPEAL, relationAttach);
  // }, [relationAttach]);

  const handleTouchTap = event => {
    if (!open) {
      setOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setOpen(false);
  };

  const handleClickAppeal = appealId => {
    setIsLoad(true);

    getClientIdByAppealId(appealId)
      .then(clientId => history.push(`/crm/show/${clientId}`))
      .finally(() => setIsLoad(false));
  };

  const rightIconMenu = appeal => (
    <Button color="primary" style={{ marginTop: '10px' }} onClick={() => relationAttach(appeal)}>
      Взять
    </Button>
  );

  const getLastChatAppeal = () => {
    if (autoTakeMassages.length) {
      relationAttach(autoTakeMassages[0]);
    } else {
      toastr.info(
        'Нет новых обращений',
        sysWS ? 'В данный момент список пуст' : 'Соединение с сервером не установлено',
      );
    }
  };

  const hotKeys = {
    getLastChatAppeal: {
      keyMap: { getLastChatAppeal: 'alt+r' },
      description: { getLastChatAppeal: 'Взять свежее обращение' },
      handlers: { getLastChatAppeal: () => getLastChatAppeal() },
      moduleName: 'Cистемные уведомдения',
    },
  };

  return (
    <>
      {sysWS ? (
        <>
          {!!autoTakeMassages.length && (
            <>
              <HotKey keysObj={hotKeys} key={new Date()} />
              <Tooltip title="Быстро взять обращение (alt+r)">
                <IconButton onClick={getLastChatAppeal}>
                  <AddCircleOutlineIcon style={{ color: '#ff7055' }} />
                </IconButton>
                {/* <Fab
                  size="small"
                  style={{ backgroundColor: '#ff5252', cursor: 'pointer' }}
                  aria-label="add"
                  onClick={getLastChatAppeal}>
                  <AddIcon style={{ fill: 'white' }} />
                </Fab> */}
              </Tooltip>
            </>
          )}
          <Badge
            style={{ cursor: 'pointer' }}
            color="secondary"
            badgeContent={messageAppeal.length}
            classes={{ badge: classes.badge }}
            onClick={handleTouchTap}>
            <Tooltip title="Уведомления">
              <IconButton onClick={handleTouchTap}>
                {open ? (
                  <CloseIcon className="search__icon" />
                ) : (
                  <NotificationIcon className="search__icon" />
                )}
              </IconButton>
            </Tooltip>
          </Badge>
        </>
      ) : (
        <Tooltip title="Уведомления. Соединение с сервером не установлено">
          <IconButton>
            <NotificationAlertIcon />
          </IconButton>
        </Tooltip>
      )}

      <Popover
        open={open}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: classes.popover }}>
        {isLoad && <Preloader absolute background />}
        {messageAppeal.length ? (
          <List className="scroll" dense classes={{ root: classes.list }}>
            {messageAppeal.map((item, ind) =>
              item.appealId ? (
                <ListItem
                  button
                  onClick={() => handleClickAppeal(item.appealId)}
                  key={item.appealId}>
                  <ListItemAvatar>
                    <Avatar alt="ava">{SourceList.getSourceIcon(item.source, item.replied)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.title}
                    secondary={
                      <span
                        style={{
                          display: 'block',
                          height: '40px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}>
                        {dateMaker(item.receivedAt)}
                        <br />
                        текст:
                        {item.description || '--'}
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>{rightIconMenu(item)}</ListItemSecondaryAction>
                </ListItem>
              ) : (
                <ListItem key={`error${String(ind)}`}>
                  <ListItemAvatar>
                    <Avatar alt="ava">
                      <DeadIcon viewBox="0 0 44 44" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.title}
                    secondary={
                      <p
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}>
                        {dateMaker(item.receivedAt)}
                        <br />
                        текст:
                        {item.description || '--'}
                      </p>
                    }
                  />
                  <ListItemSecondaryAction>{rightIconMenu(item)}</ListItemSecondaryAction>
                </ListItem>
              ),
            )}
          </List>
        ) : (
          <p style={{ padding: '15px' }}>нет уведомлений</p>
        )}
      </Popover>
    </>
  );
};

Notification.propTypes = {
  getClientIdByAppealId: PropTypes.func.isRequired,
  messageAppeal: PropTypes.instanceOf(Object).isRequired,
  relationAttach: PropTypes.func.isRequired,
  autoTakeSources: PropTypes.instanceOf(Array).isRequired,
  sysWS: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ system: { messageAppeal, sysWS, autoTakeSources } }) => ({
  messageAppeal,
  sysWS,
  autoTakeSources,
});

const mapDispatchProps = {
  getClientIdByAppealId: getClientIdByAppealIdAction,
  relationAttach: relationAttachAction,
};

export default connect(
  mapStateToProps,
  mapDispatchProps,
)(Notification);
