import React from 'react';
import { Field } from 'redux-form';

import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const renderSelectField = ({
  label,
  input,
  children,
  meta: { touched, invalid, error },
  onClick,
  style,
  ...custom
}) => (
  <FormControl style={style}>
    {label && <InputLabel htmlFor={input.id}>{label}</InputLabel>}
    <Select {...custom} onOpen={onClick} inputProps={{ ...input }}>
      {children}
    </Select>
    {touched && invalid && <FormHelperText>{touched && error}</FormHelperText>}
  </FormControl>
);

export default props => (
  <Field
    component={renderSelectField}
    // SelectProps={{
    //   MenuProps: {
    //     style: { zIndex: 9999 },
    //   },
    // }}
    {...props}>
    {props.children}
  </Field>
);
