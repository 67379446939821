import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import 'moment/locale/ru';
import Preloader from 'components/simple/preloader';
import Calendar from 'components/composite/calendar';
import Time from 'components/composite//time';

const styles = {
  calendar: {
    flex: '0 0 50%',
    maxWidth: '50%',
    flexDirection: 'column',
    padding: '0 15px',
  },
  container: {
    height: '265px',
    display: 'flex',
    alignSelf: 'stretch',
  },
};

function ModalReminders({ addReminder, clientId, clientPhone, closeModal, textDefault }) {
  const initMoment = moment()
    // .add(1, 'days')
    .hours(12)
    .minutes(0)
    .seconds(0);
  const [m, setMoment] = useState(initMoment);
  const [text, setText] = useState(textDefault);
  const [isLoad, setIsLoad] = useState(false);

  const changeTime = time => setMoment(moment(time));

  const onHandleSubmit = () => {
    if (text) {
      setIsLoad(true);
      addReminder({
        marks: { clientId, clientPhone },
        activateAt: moment(m)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        message: text,
      })
        .then(() => closeModal('clientInfoModalReminders'))
        .catch(() => setIsLoad(false));
    }
  };

  return (
    <div style={{ padding: '0 15px' }}>
      <Preloader isLoad={isLoad} absolute background />
      <TextField
        placeholder="Текст Напоминания"
        fullWidth
        value={text}
        onChange={({ target }) => setText(target.value)}
        margin="normal"
      />

      <Typography
        style={{
          fontSize: '16px',
          margin: '15px 0',
        }}>
        {`Напоминание сработает ${moment(m).format('Do MMMM HH:mm')}`}
      </Typography>

      <div style={styles.container}>
        <Calendar
          moment={m}
          style={{ ...styles.calendar, padding: '0 15px' }}
          onChange={changeTime}
        />
        <Time
          moment={m}
          style={{ ...styles.calendar, padding: '45px 15px 0' }}
          onChange={changeTime}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '15px',
        }}>
        <Button color="primary" variant="contained" onClick={onHandleSubmit}>
          Создать
        </Button>
      </div>
    </div>
  );
}

ModalReminders.propTypes = {
  addReminder: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  clientPhone: PropTypes.string,
  textDefault: PropTypes.string,
};

ModalReminders.defaultProps = {
  clientPhone: '',
  textDefault: '',
};

export default ModalReminders;
