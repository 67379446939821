import React, { useState, useRef, useLayoutEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { Rnd } from 'react-rnd';

const initialOptions = {
  bottomLeft: false,
  bottomRight: true,
  left: true,
  right: true,
  top: true,
  bottom: true,
};

const getSize = cssStyleDeclaration => {
  if (cssStyleDeclaration.boxSizing === 'border-box') {
    return {
      h: parseInt(cssStyleDeclaration.height, 10),
      w: parseInt(cssStyleDeclaration.width, 10),
    };
  }

  return {
    h:
      parseInt(cssStyleDeclaration.height, 10) +
      parseInt(cssStyleDeclaration.marginTop, 10) +
      parseInt(cssStyleDeclaration.marginBottom, 10),
    w:
      parseInt(cssStyleDeclaration.width, 10) +
      parseInt(cssStyleDeclaration.marginLeft, 10) +
      parseInt(cssStyleDeclaration.marginRight, 10),
  };
};

function resizableAndDragHOC(options) {
  // resizableAndDragComponent.propTypes = {};

  return function resizableAndDragComponent(props) {
    const [{ width, height, x, y }, setState] = useState(() => {
      const localOptions = localStorage.getItem(`${options.name}_MODAL`);

      if (localOptions) {
        return JSON.parse(localOptions);
      }

      const state = {};

      if (options.width || options.height) {
        state.width = options.width || 600;
        state.height = options.height || 600;
      }

      if (options.x || options.y) {
        state.x = options.x || 0;
        state.y = options.y || 0;
      }

      return state;
    });

    const refContainer = useRef();
    const rnd = useRef();

    useLayoutEffect(() => {
      if (!x || !y) {
        const childSize = getSize(getComputedStyle(refContainer.current));
        const x1 = Math.floor(window.innerWidth / 2 - childSize.w / 2);
        const y1 = Math.floor(window.innerHeight / 2 - childSize.h / 2);

        rnd.current.updatePosition({ x: x1, y: y1 });
      }
    }, []);

    useLayoutEffect(() => {
      localStorage.setItem(`${options.name}_MODAL`, JSON.stringify({ width, height, x, y }));
    }, [width, height, x, y]);

    const onDragStop = (e, d) => {
      setState(old => ({ ...old, x: d.x, y: d.y }));
    };

    const onResizeStop = (e, direction, ref, delta, position) => {
      setState({
        width: ref.style.width,
        height: ref.style.height,
        ...position,
      });
    };

    return (
      <Rnd
        ref={rnd}
        enableResizing={options.resizable ? initialOptions : false}
        disableDragging={!options.draggable}
        dragHandleClassName="MuiDialogTitle-root"
        size={width && height && { width, height }}
        position={x && y && { x, y }}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        {...options.rndOptions}>
        <Paper
          ref={refContainer}
          {...props}
          style={{ margin: 0, width: '100%', height: '100%', maxHeight: '100%' }}
        />
      </Rnd>
    );
  };
}

export default resizableAndDragHOC;
