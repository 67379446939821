import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactJson from 'react-json-view';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as fetchActions from 'utils/apiHelper';
import Preloader from '../../simple/preloader';
import Ping from './Ping';
import './dev-board.scss';

class DevBoard extends Component {
  state = {
    value: 'getFetchWrapper',
    url: '',
    body: '',
    isLoad: false,
    data: null,
    err: false,
  };

  handleChange = (e, value) => {
    this.setState({
      value,
    });
  };

  handleChangeInput = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  handleSendRequest = e => {
    e.preventDefault();
    if (!this.state.url) return;

    this.setState({ isLoad: true });
    let bodyJSON = {};

    if (this.state.body) {
      try {
        bodyJSON = JSON.parse(this.state.body);
      } catch (error) {
        this.setState({
          data: { name: error.name, message: error.message, stack: error.stack },
          err: true,
          isLoad: false,
        });
        return;
      }
    }

    fetchActions[this.state.value](this.state.url, bodyJSON)
      .then(data => {
        this.setState({ data, err: false, isLoad: false });
      })
      .catch(err => {
        if (err.json) {
          try {
            err.json().then(error => this.setState({ data: error, err: true, isLoad: false }));
          } catch {
            this.setState({ data: 'none', err: true, isLoad: false });
          }
        } else {
          this.setState({ data: err, err: true, isLoad: false });
        }
      });
  };

  handleClear = () => {
    this.setState({
      url: '',
      body: '',
      isLoad: false,
      data: null,
      err: false,
    });
  };

  render() {
    return (
      <div className="scroll" style={{ overflow: 'auto' }}>
        <Preloader absolute background isLoad={this.state.isLoad} />
        <Tabs
          scrollButtons="auto"
          textColor="primary"
          value={this.state.value}
          onChange={this.handleChange}>
          <Tab label="GET" value="getFetchWrapper" />
          <Tab label="POST" value="postFetchWrapper" />
          <Tab label="PUT" value="putFetchWrapper" />
          <Tab label="DELETE" value="deleteFetchWrapper" />
          <Tab label="TEST" value="test" />
        </Tabs>
        {this.state.value === 'test' ? (
          <Ping />
        ) : (
          <>
            <div style={{ padding: 15 }}>
              <form onSubmit={this.handleSendRequest}>
                <TextField
                  style={{
                    marginBottom: '10px',
                  }}
                  value={this.state.url}
                  name="url"
                  onChange={this.handleChangeInput}
                  label="Url"
                  fullWidth
                />
                {this.state.value !== 'getFetchWrapper' && (
                  <TextareaAutosize
                    style={{ margin: '16px 0' }}
                    disabled={this.state.value === 'getFetchWrapper'}
                    value={this.state.body}
                    name="body"
                    onChange={this.handleChangeInput}
                    label="Body"
                    fullWidth
                    multiline
                    rows={4}
                    rowsMax={40}
                  />
                )}

                <div>
                  <Button variant="outlined" color="primary" style={{ margin: 8 }} type="submit">
                    Send
                  </Button>

                  <Button variant="outlined" style={{ margin: 8 }} onClick={this.handleClear}>
                    Clear
                  </Button>
                </div>
              </form>
            </div>
            <div>
              {this.state.err && (
                <h3
                  style={{
                    padding: 10,
                    backgroundColor: '#ff6868',
                    color: '#fff',
                  }}>
                  Error
                </h3>
              )}
              {this.state.data && (
                <ReactJson
                  theme={localStorage.getItem('THEME') === 'dark' ? 'monokai' : 'bright:inverted'}
                  collapsed
                  style={{ padding: 15 }}
                  src={this.state.data}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

DevBoard.propTypes = {};

export default DevBoard;
