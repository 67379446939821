import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const styleLink = {
  display: 'block',
  textAlign: 'center',
  lineHeight: '1.4em',
};

FilterButton.propTypes = {
  checked: PropTypes.instanceOf(Array),
  localStorage: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
};

FilterButton.defaultProps = {
  checked: [],
};
function FilterButton(props) {
  const [checked, setChecked] = useState(props.checked);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.localStorage && localStorage.getItem(props.localStorage)) {
      setChecked(JSON.parse(localStorage.getItem(props.localStorage)));
      handleSubmit(JSON.parse(localStorage.getItem(props.localStorage)));
    } else {
      handleDeSelectAll();
    }
  }, [props.localStorage]);

  const updateCheck = (name, bool) => {
    const collect = new Set(checked);

    bool ? collect.add(name) : collect.delete(name);

    if (props.localStorage) {
      localStorage.setItem(props.localStorage, JSON.stringify([...collect.keys()]));
    }

    setChecked([...collect.keys()]);
  };

  const handleSubmit = data => {
    props.handleSubmit(props.field, data);
    handleClose();
  };

  const handleSelectAll = () => {
    if (props.localStorage) {
      localStorage.removeItem(props.localStorage);
    }

    setChecked(Object.keys(props.data));
  };

  const handleDeSelectAll = () => setChecked([]);

  const handleFilter = checked.length;
  const fullFilter = Object.keys(props.data).length;
  const filterCount = handleFilter === 0 || handleFilter === fullFilter ? '' : handleFilter;

  return (
    <>
      {filterCount}
      <IconButton onClick={handleClick} style={{ height: '36px', width: '36px', padding: 0 }}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <List>
          <a className="link" onClick={handleSelectAll} style={styleLink}>
            выделить все
          </a>
          <a className="link" onClick={handleDeSelectAll} style={styleLink}>
            снять все
          </a>
          {Object.keys(props.data).map(list => (
            <ListItem
              dense
              button
              key={list}
              onClick={e => updateCheck(list, !checked.includes(list))}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  name={list}
                  checked={checked.includes(list)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={props.data[list]} />
            </ListItem>
          ))}
        </List>
        <div style={{ padding: '0 16px' }}>
          <Button size="small" variant="contained" fullWidth onClick={() => handleSubmit(checked)}>
            применить
          </Button>
        </div>
      </Menu>
    </>
  );
}

FilterButton.propTypes = {};

export default FilterButton;
