import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './header.scss';
import history from '../../../store/history';

function HeaderMobile({ title, toolbar }) {
  const [show, setShow] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (['/menu', '/widgets', '/search', '/options'].includes(location.pathname)) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location]);

  const handleBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/menu');
    }
  };

  return show ? (
    <>
      <header className="header fixed" style={{ width: '100%', padding: 0 }}>
        <AppBar
          position="static"
          style={{ background: 'var(--color-background-f)', height: '56px' }}>
          <Toolbar
            style={{
              minHeight: '56px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 8px 0 0',
            }}>
            <div className="header__title">
              <IconButton onClick={handleBack}>
                <ArrowBackIosIcon />
              </IconButton>
              <Typography style={{ padding: '0 12px 0 0' }} variant="h6" noWrap>
                {title || 'Gepur: Менеджер'}
              </Typography>
              {toolbar}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }} />
          </Toolbar>
        </AppBar>
      </header>
      <div className="header__fake" style={{ height: '56px' }} />
    </>
  ) : null;
}

HeaderMobile.propTypes = {
  title: PropTypes.string,
  toolbar: PropTypes.element,
};

HeaderMobile.defaultProps = {
  title: 'Gepur',
  toolbar: '',
};

const mapState = state => ({
  title: state.header.title,
  toolbar: state.header.toolbar,
});

export default connect(mapState)(HeaderMobile);
