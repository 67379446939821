import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextField from '@material-ui/core/TextField';

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  children,
  ...custom
}) => (
  <TextField
    label={label || ''}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    margin="normal"
    {...input}
    {...custom}>
    {children}
  </TextField>
);

renderTextField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
};

renderTextField.defaultProps = {
  label: '',
  children: null,
};

export default props => <Field component={renderTextField} {...props} />;
