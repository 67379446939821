import React from 'react';
import { Field } from 'redux-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const renderCheckbox = ({ input, label, style, ...props }) => (
  <div style={style}>
    <FormControlLabel
      control={<Checkbox checked={!!input.value} onChange={input.onChange} {...props} />}
      label={label}
    />
  </div>
);

export default props => <Field component={renderCheckbox} {...props} />;
