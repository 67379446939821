/**
 * Конвертирование в плоский массив
 * @param arr
 * @return {Array}
 */
export const convertToFlatArray = arr => {
  const linkArr = [];

  function mapping(items) {
    items.map(item => {
      if (item.children.length) {
        mapping(item.children);
      }
      return linkArr.push(item);
    });
  }

  mapping(arr);

  return linkArr;
};
