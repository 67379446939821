const SET_HEADER_ELEMENTS = 'SET_HEADER_ELEMENTS';
const SET_HEADER_TITLE = 'SET_HEADER_TITLE';
const SET_HEADER_SUBHEADER = 'SET_HEADER_SUBHEADER';
const SET_HEADER_TOOLBAR = 'SET_HEADER_TOOLBAR';
const RESET_HEADER = 'RESET_HEADER';

const initialState = {
  title: 'Gepur',
  subHeaderActions: null,
  toolbar: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HEADER_ELEMENTS:
      return {
        ...state,
        title: payload.title || 'Gepur',
        subHeaderActions: payload.subHeaderActions || null,
        toolbar: payload.toolbar || null,
      };

    case SET_HEADER_TITLE:
      return {
        ...state,
        title: payload,
      };

    case SET_HEADER_SUBHEADER:
      return {
        ...state,
        subHeaderActions: payload,
      };

    case SET_HEADER_TOOLBAR:
      return {
        ...state,
        toolbar: payload,
      };

    case RESET_HEADER:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

/**
 *
 * @param obj
 */
export const setHeaderElements = (obj = {}) => dispatch => {
  dispatch({ type: SET_HEADER_ELEMENTS, payload: obj });
};

/**
 *
 * @param title
 */
export const setHeaderTitle = title => dispatch =>
  dispatch({ type: SET_HEADER_TITLE, payload: title });

/**
 *
 * @param toolbar
 */
export const setHeaderToolbar = toolbar => dispatch =>
  dispatch({ type: SET_HEADER_TOOLBAR, payload: toolbar });

/**
 *
 * @param setSubHeader
 */
export const setHeaderSubHeader = setSubHeader => dispatch =>
  dispatch({ type: SET_HEADER_SUBHEADER, payload: setSubHeader });

/**
 *
 */
export const resetHeader = () => dispatch => dispatch({ type: RESET_HEADER });
