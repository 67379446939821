import React, { Component } from 'react';
import { getFetchWrapper } from './utils/apiHelper';

const GetWidgetsHOC = WidgetComponent =>
  class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        widgets: [],
      };
    }

    componentDidMount() {
      const chat = localStorage.getItem('ENABLED_ROCKET_CHAT')
        ? 'unanswered_chat_message_count'
        : null;

      getFetchWrapper('/modules')
        .then(widgets => {
          this.setState({
            widgets: [
              'clientInfo_widget', // hell modal
              ...widgets,
              /* inject temp-widget don`t delete!!! */
              'history_widget',
              // 'chat',
              // 'chat_widget',
              chat,
            ],
          });
        })
        .catch(err => console.error(err));
    }

    render() {
      const { widgets } = this.state;
      return widgets ? <WidgetComponent {...this.props} widgets={widgets} /> : null;
    }
  };

export default GetWidgetsHOC;
