import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { toastr } from 'lib/react-redux-toastr';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import './copy-link.scss';

function CopyLink({ currentLink, hoverColor, style }) {
  const [hoveredTooltip, setHoveredTooltip] = useState(false);
  const copyLink = useRef(null);

  const onCopyLink = e => {
    e.stopPropagation();
    e.preventDefault();

    if (typeof currentLink === 'string') {
      return copyTextToClipboard(currentLink);
    }

    const range = document.createRange();
    const sel = window.getSelection();

    range.selectNode(copyLink.current);

    sel.removeAllRanges();
    sel.addRange(range);
    try {
      document.execCommand('copy');
    } catch (error) {
      console.error('Error!', error);
    }
    window.getSelection().removeAllRanges();
    toastr.success('Успех!', 'Текст скопирован в буфер обмена');
  };

  return (
    <Tooltip
      title={<span style={{ fontSize: 12 }}>Скопировать в буфер обмена</span>}
      placement="right">
      <span
        className="copy-link"
        onClick={onCopyLink}
        ref={copyLink}
        onMouseEnter={() => setHoveredTooltip(true)}
        onMouseLeave={() => setHoveredTooltip(false)}
        style={
          hoveredTooltip && hoverColor
            ? { ...style, color: hoverColor, borderBottom: `1px dotted ${hoverColor}` }
            : { ...style }
        }>
        {currentLink}
      </span>
    </Tooltip>
  );
}

CopyLink.propTypes = {
  style: PropTypes.instanceOf(Object),
  currentLink: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  hoverColor: PropTypes.string,
};

CopyLink.defaultProps = {
  style: null,
  hoverColor: 'var(color-1)',
};

export default CopyLink;
