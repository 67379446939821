import React, { useState } from 'react';
import { string, instanceOf, func } from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

import Preloader from 'components/simple/preloader';
import SelectInput from './select-input';

AsyncSelectBox.propTypes = {
  defaultValue: string,
  defaultData: instanceOf(Array),
  getData: func.isRequired,
};

AsyncSelectBox.defaultProps = {
  defaultValue: '',
  defaultData: null,
};

export default function AsyncSelectBox({ defaultValue, defaultData, getData, ...props }) {
  const [data, setData] = useState(defaultData);

  function handleClick() {
    if (!data) {
      getData().then(setData);
    }
  }

  return (
    <SelectInput {...props} onClick={handleClick}>
      {!data && <MenuItem value={defaultValue}>{defaultValue}</MenuItem>}
      {data ? (
        data.map(size => (
          <MenuItem key={size} value={size.toUpperCase()}>
            {size}
          </MenuItem>
        ))
      ) : (
        <Preloader isLoad={!data} absolute background />
      )}
    </SelectInput>
  );
}
