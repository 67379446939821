import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';

const ActionAccessibility = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 490 290">
    <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,53.68649,897.18964)">
      <g id="g12" transform="matrix(5.8427196,0,0,5.8427196,-1583.8366,-2391.086)">
        <g id="g14" clipPath="url(#clipPath18)">
          <g id="g20" transform="translate(296.8092,497.8276)">
            <path
              d="m 0,0 c -1.215,0.081 -2.616,0.294 -3.721,0.614 -0.207,0.06 -1.064,0.375 -1.282,0.475 -3.847,1.782 -6.527,5.672 -6.527,10.185 0,6.19 5.036,11.232 11.23,11.232 6.139,0 11.141,-4.956 11.227,-11.076 h 4.057 C 14.898,19.786 8.077,26.558 -0.3,26.558 c -8.427,0 -15.286,-6.855 -15.286,-15.284 0,-5.811 3.268,-10.865 8.053,-13.447 0.864,-0.457 2.104,-0.928 2.525,-1.057 1.364,-0.406 3.038,-0.676 4.528,-0.789 0.506,-0.038 2.273,-0.058 2.788,-0.06 h 27.938 v 4.041 H 2.355 C 1.984,-0.038 0.363,-0.024 0,0 m 2.355,6.345 v 0 c -0.295,0 -1.838,0.01 -2.123,0.034 -0.483,0.041 -1.183,0.113 -1.633,0.216 -0.508,0.136 -0.985,0.316 -1.2,0.434 -1.507,0.815 -2.534,2.409 -2.534,4.245 0,2.671 2.163,4.837 4.835,4.837 2.616,0 4.743,-2.081 4.828,-4.681 h 3.898 c -0.082,4.75 -3.954,8.578 -8.726,8.578 -4.825,0 -8.736,-3.91 -8.736,-8.734 0,-3.402 1.95,-6.34 4.79,-7.779 C -3.809,3.239 -2.987,2.971 -2.753,2.91 -1.951,2.699 -0.874,2.555 0,2.491 0.323,2.464 1.905,2.452 2.239,2.45 V 2.444 H 30.245 V 6.345 Z M -18.086,-4.064 h -4.084 v 26.561 h -5.968 v 4.085 h 10.052 z m -14.536,24.52 v -3.928 h 4.484 V -4.064 h 3.924 v 24.52 z"
              id="path22"
            />
          </g>
          <g id="g24" transform="translate(319.8388,524.1243)">
            <path
              d="m 0,0 c -1.471,0 -2.691,-1.173 -2.691,-2.645 0,-1.472 1.22,-2.692 2.691,-2.692 1.516,0 2.689,1.22 2.689,2.692 C 2.689,-1.173 1.516,0 0,0 m 0,-4.903 c -1.215,0 -2.262,0.955 -2.262,2.258 0,1.237 1.047,2.216 2.262,2.216 1.214,0 2.256,-0.979 2.256,-2.216 0,-1.303 -1.042,-2.258 -2.256,-2.258"
              id="path26"
            />
          </g>
          <g id="g28" transform="translate(320.8634,520.7545)">
            <path
              d="m 0,0 c 0,0.297 -0.044,0.573 -0.384,0.682 0.426,0.148 0.468,0.427 0.468,0.596 0,0.683 -0.62,0.77 -0.875,0.77 h -1.173 v -2.645 h 0.489 v 1.085 h 0.385 c 0.554,0 0.6,-0.255 0.6,-0.488 0,-0.451 0,-0.536 0.106,-0.597 h 0.49 C 0,-0.49 0.042,-0.407 0,0 m -0.898,0.874 h -0.577 v 0.833 h 0.494 c 0.342,0 0.574,-0.108 0.574,-0.429 0,-0.169 -0.083,-0.404 -0.491,-0.404"
              id="path30"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
));

ActionAccessibility.displayName = 'ActionAccessibility';
ActionAccessibility.muiName = 'SvgIcon';

ActionAccessibility.propTypes = {
  style: PropTypes.instanceOf(Object),
};

ActionAccessibility.defaultProps = {
  style: {},
};

export default ActionAccessibility;
