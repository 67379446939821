import { toastr } from 'lib/react-redux-toastr';

export default function copyTextToClipboard(text) {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(text).then(
      () => toastr.info('Успех!', 'Текст скопирован в буфер обмена'),
      err => {
        toastr.error('Ой!', 'Что-то произошло при копировании текста');
        console.error('Async: Could not copy text: ', err);
      },
    );
  } else {
    const el = document.createElement('textarea');

    el.value = text.trim();
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    document.body.appendChild(el);

    const selected =
      document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;

    el.select();

    document.execCommand('copy');
    document.body.removeChild(el);

    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }

    toastr.info('Успех!', 'Текст скопирован в буфер обмена');
  }
}
