export const phrases = {
  retail: 'розница',
  wholesale: 'опт',
  // history
  sendemail: 'Исходящее письмо',
  sendsms: 'sms',
  noConnection: 'Нет связи',
  comment: 'комментарий',
  callsuccess: 'Обработано',
  lock: 'Задача взята в работу',
  defer: 'Отложенный звонок',
  call: 'Звонок',
  complete: 'Обработан',
  autodeffer: 'Автоматически отложен',
  auto_deferred: 'Автоматически отложен',
  noconnection: 'Нет связи',
  unanswer: 'Недозвон',
  merge: 'Объединение заказов',
  transfer: 'Передача менеджеру',

  FAILED: 'ошибка',
  ANSWERED: 'отвечено',
  'NO ANSWER': 'нет ответа',
  BUSY: 'занято',
  pending: 'в обработке',

  // status
  bonus: 'Бонус',
  fresh: 'Свежий',
  one_click_new: 'Один клик (новый)',
  replace_task_data: 'Замена',
  review: 'Отзыв по товару',
  question: 'Вопрос по товару',
  reviewComment: 'Комментарий на отзыв',
  questionComment: 'Комментарий на вопрос',
  payment_on_delivery: 'Наложка',
  action_retail: 'Розничный',
  action_wholesale: 'Оптовый',
  replacement: 'Замены',
  undelivered_package: 'Забрать посылку',
  fittingFromSite: 'Примерка',
  usualFromSite: 'Один клик',
  lead_form: 'Лид-форма',
  // order-detail
  // order-detail client
  email: 'email',
  phone: 'телефон',
  firstName: 'имя',
  lastName: 'фамилия',
  middleName: 'отчество',
  // order-detail payment
  typePrice: 'тип оплаты',
  totalCost: 'общая сумма в гривне',
  currency: 'валюта',
  costCurrency: 'сумма в валюте',
  costOfDelivery: 'сумма доставки',
  // order-detail delivery
  country: 'страна',
  region: 'область',
  city: 'город',
  postCode: 'индекс',
  warehouse: 'отделение',
  street: 'улица',
  service: 'служба',
  type: 'тип',
  passport: 'пасспорт',
  quantity: 'количество',
  declaration: 'декларация',
  payedBy: 'кто платит',
  // order-detail delivery
  cost: 'сумма',
  method: 'метод',
  confirmed: 'подтвержден',
  approved: 'подтверждено',
  // order-detail product
  article: '№',
  size: 'размер',
  assembled: 'собран',
  price: 'стоимость',
  // order-detail comments
  operator: 'коммент оператора',
  manager: 'коммент офиса',
  store: 'коммент магазина',
  payment: 'коммент оплаты',
  payment_one: 'коммент оплаты1',
  payment_two: 'коммент оплаты2',
  payment_three: 'коммент оплаты2',
  courier: 'коммент курьера',

  // allTask
  reset: 'Сброшено',
  new: 'Новый',
  email_sent: 'Отправка письма',
  merged_to: 'Слияние заказов',
  deferred: 'Отложено',
  click: 'Один клик',
  one_click: 'Один клик',
  unanswered: 'неотвеченные',
  started: 'В обработке',
  force_closed: 'Закрыта приложением',
  all: 'Все задачи',
  my: 'Мои задачи',
  my_opened: 'Мои задачи в работе',
  in_process: 'Задачи в работе',
  MissedCalls: 'Пропущенные звонки - обработка',
  archived: 'Архив',
  completed: 'Обработан',
  no_connection: 'Нет связи',
  unAnswer: 'недозвон',
  confirmed_old: 'Подтверждённые без оплаты',
  withdraw: 'Выводы средств',
  order_task_data: 'Заказ',
  changeorderfield: 'Изменен статус',
  // новые статусы в 1клик задачах
  doubleByPhone: 'Дубляж клиент',
  mergedByPhone: 'Объединена с другой задачей',
  reserved: 'Отправлена бронь',
  persuaded_completed: 'Условный отказ',
  persuadedcomplete: 'Условный отказ',
  waitReserveConfirmation: 'Ожидает подтверждения из магазина',
  reserveReceived: 'Получен ответ из магазина',

  recipientName: 'ФИО держателя',
  recipientCardNumber: 'Карта держателя',

  status: 'статус',
  addressCourier: 'Адрес для курьера',

  phoneSecond: 'телефон 1С',
  costToPayCurrency: 'К ОПЛАТЕ',
  pay_of_delivery: 'Наложенный платеж',

  // unanswer component
  answerphoneNight: 'ночной автоответчик',
  no_answer: 'неотвеченный',
  failed: 'ошибка',
  busy: 'занято',
  broken: 'сорван звонок',

  warehouseAddress: '№',
  rejected: 'Отклонен',
  reject: 'Отказ',

  // withdraw
  approvewithdrawaction: 'Вывод подтверждён',
  userdeclinewithdrawaction: 'Отказ клиента',
  closeotherreasonwithdrawaction: 'Закрыто по другой причине',

  confirm_replacement: 'Замена подтверждена',
  confirmreplacement: 'Замена подтверждена',
  unconfirmreplacement: 'Отмена подтверждения замены',
  un_confirm_replacement: 'Отмена подтверждения замены',
  waitcomplete: 'Ожидает завершения',
  wait_complete: 'Ожидает завершения',
  restart: 'Задача не была выполнена и запущена заново',
  igr_task_data: 'Instagram - напоминание',
  changefittingshop: 'Изменен магазин примерки',
  remaketofittingtype: 'Изменено на примерку',
  remaketooneclicktype: 'Изменено на один клик',
  abandoned_cart: 'брошенная корзина',
  double: 'Дубляж',

  doublebyphone: 'Дубляж форс',
  mergebyphone: 'Объединена с другой клик-задачей',
  addedgoodsfromanothertask: 'Добавлены товары из объединенной задачи',
  addedgoodsfromanotherrequest: 'Добавлены товары из нового запроса',
  reserve: 'Отправлена бронь в магазин на подтверждение',
  reserveconfirmed: 'Бронь подтверждена',
  reserverejected: 'В резерве ОТКАЗАНО',
  transformtofitting: 'Преобразована в примерку',

  fittingconfirm: 'Подтверждена (ждем на примерку)',
  fittingdefer: 'Примерка: перезвон',
  fittingorderreceived: 'Заказ по примерке принят в магазине',
  fittingreject: 'Примерка Отказ',
  fittingunanswer: 'Примерка: недозвон',

  fittingOrderReceived: 'Заказ по примерке принят в магазине',
  startedFitting: 'В обработке (прозвон Примерки)',
  fittingConfirmed: 'Подтверждена (ждем на примерку)',
  fittingDeferred: 'Примерка: перезвон',
  fittingUnanswered: 'Примерка: недозвон',
  fittingRejected: 'Примерка: недозвон',

  odesa_riviera: 'Одесса, ТРЦ Riviera',
  kyiv_lavina_mall: 'Киев, ТРЦ Lavina',
  odesa_city_center_nebesnoy_sotni: 'Одесса, ТРЦ  Ситицентр',
  kyiv_blockbuster: 'Киев, ТРЦ Blockbuster',
  kyiv_river: 'Киев, ТРЦ River',

  // reject reasons
  no_cash_on_delivery: 'Нет наложенного платежа',
  pre_pay: 'Предоплата',
  long_delivery: 'Долгая доставка',
  expensive_delivery: 'Дорогая доставка',
  long_contacted: 'Долго связывались',
  bought_already: 'Уже купил(а)',
  bad_reviews: 'Плохие отзывы',
  no_size_needed: 'Нет нужного размера',
  not_available: 'Нет в наличии',
  mistakenly_ordered: 'Ошибочно оформил(а)',
  test: 'Тест',
  wrong_phone: 'Некорректный номер',

  changeorder: 'Данные заказа изменены',

  callLater: 'Перезвонить позже',
  unanswerAndEmail: 'Недозвон + e-mail',
  unanswerAndSMS: 'Недозвон + SMS',
  willPickup: 'Клиент заберет посылку',
  willNotPickup: 'Клиент НЕ заберет посылку',
  lostPackage: 'Посылка утеряна',

  calllater: 'Перезвонить позже',
  unanswered_email: 'Недозвон, отпр. E-mail',
  unanswerandemail: 'Недозвон + e-mail',
  unanswerandsms: 'Недозвон + SMS',
  unanswered_sms: 'Недозвон, отпр. SMS',
  will_pickup: 'Заберет посылку',
  willpickup: 'Клиент заберет посылку',
  will_not_pickup: 'НЕ заберет посылку',
  willnotpickup: 'Клиент НЕ заберет посылку',
  received: 'Клиент уже забрал заказ',
  lost_package: 'Посылка утеряна',
  lostpackage: 'Посылка утеряна',
  pickup_confirmed: 'Посылка получена',
  confirmpickup: 'Посылка получена',
  reject_confirmed: 'Посылка уехала обратно',
  confirmreject: 'Посылка уехала обратно',
  back_call: 'Обратный звонок',

  boughtInShop: 'Уже купила в магазине',
  didNotFit: 'Не подошло, уже была в магазине',
  longDelivery: 'Долго ехала примерка, уже не актуально',
  rejectGotOver: 'Охрана, отмена! Перехотела!',
  timeOut: 'Время примерки истекло',
  orderDouble: 'Дубль заказа',
  other: 'Другое (указать в комменте)',

  offeradditionalsale: 'Доп. продажа',
  changeproducts: 'Изменены товары',

  RIV: 'Ривьера',
  CIT: 'Ситицентр',
  LVN: 'Лавина',
  BLO: 'Блокбастер',
  RVR: 'Ривер',
  home: 'склад',

  oneclickrequestcreated: 'Создан запрос в 1клик',
  transformintooneclick: 'Преобразована в 1клик',
};

export default text => (phrases[text] ? phrases[text] : text);
