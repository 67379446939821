import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CopyLink from '../../simple/copy-link';

const measurePhrases = {
  breast: {
    ru: 'Грудь',
    uk: 'Груди',
    en: 'Chest',
  },
  waist: {
    ru: 'Талия',
    uk: 'Талія',
    en: 'Waist',
  },
  hips: {
    ru: 'Бедра',
    uk: 'Стегна',
    en: 'Hip',
  },
  strap: {
    ru: 'Длина бретелей',
    uk: 'Довжина бретелей',
    en: 'Straps length',
  },
  shoulderWidth: {
    ru: 'Ширина спинки по плечам',
    uk: 'Ширина спинки по плечах',
    en: 'Back width over shoulders',
  },
  backWidth: {
    ru: 'Ширина спинки',
    uk: 'Ширина спинки',
    en: 'Back width',
  },
  backLength: {
    ru: 'Длина по спинке',
    uk: 'Довжина спинки',
    en: 'Back length',
  },
  lengthBySideSeam: {
    ru: 'Длина по бок.шву',
    uk: 'Довжина бічного шва',
    en: 'Side seam',
  },
  lengthByStepperSeam: {
    ru: 'Дл.рукава по внутр.шву',
    uk: 'Довжина рукава по внутрішньому шву',
    en: 'Inner seam of sleeve',
  },
  sleeveOuterSeam: {
    ru: 'Дл.рукава по внешн.шву',
    uk: 'Довжина рукава по зовнішньому шву',
    en: 'Outside seam of sleeve',
  },
  lengthOfShot: {
    ru: 'shot',
    uk: 'shot',
    en: 'shot',
  },
  height: {
    ru: 'Высота',
    uk: 'Висота',
    en: 'Height',
  },
  width: {
    ru: 'Ширина',
    uk: 'Ширина',
    en: 'Width',
  },
  length: {
    ru: 'Длина',
    uk: 'Довжина',
    en: 'Length',
  },
  diameter: {
    ru: 'Диаметр',
    uk: 'Діаметр',
    en: 'Diameter',
  },
};

const MeasurementText = ({ item, lang }) => (
  <>
    {`${item.size}: `}
    {item.measurements &&
      Object.entries(item.measurements).map(([key, value]) =>
        measurePhrases[key] ? (
          <Fragment key={key}>
            {`${measurePhrases[key][lang]} : ${value}`}
            <br />
          </Fragment>
        ) : null,
      )}
  </>
);

MeasurementText.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

const StyledTableCell = color =>
  withStyles(theme => ({
    head: { color },
    body: { color },
  }))(TableCell);

function ProductTableMeasurements({ product, currentSize, style, colorText, lang }) {
  const Cell = StyledTableCell(colorText);
  const hasAnyMeasurement = (product.sizes || []).find(item => item.measurements);

  return hasAnyMeasurement ? (
    <Table size="small" style={style}>
      <TableHead>
        <TableRow>
          <Cell padding="none">Разм.</Cell>
          <Cell>Замеры</Cell>
        </TableRow>
      </TableHead>
      <TableBody>
        {product.sizes.map(item => (
          <TableRow
            hover
            key={item.size}
            style={item.size === currentSize ? { backgroundColor: 'var(--color-1-light)' } : null}>
            <Cell padding="none" align="center" component="th" scope="row">
              {item.size}
            </Cell>
            <Cell component="th">
              <CopyLink
                style={{ margin: 0 }}
                currentLink={<MeasurementText lang={lang} item={item} />}
              />
            </Cell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : null;
}

ProductTableMeasurements.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
  currentSize: PropTypes.string,
  colorText: PropTypes.string,
  lang: PropTypes.string,
};

ProductTableMeasurements.defaultProps = {
  currentSize: '',
  lang: 'ru',
  colorText: '#fff',
  style: {},
};

export default ProductTableMeasurements;
