import store from '../store';
import module from '../store/module';
import { clientExtendedInfo } from '../modules';

const register = module(store);

/**
 * Регистрация всех модулей
 * register (
 *   'name', - уникальное имя модуля
 *   import( ... ), - динамический импорт
 *   [ module1, module2 ], - зависимости
 * )
 *
 */

/* inject register-widget don`t delete!!! */
export const myAdditionalSales = () =>
  register(
    'myAdditionalSales',
    import(/* webpackChunkName: "myAdditionalSales" */ './my-additional-sales/index'),
  );

export const history = () =>
  register('history', import(/* webpackChunkName: "history" */ './history/index'));

export const synchronizationInfo = () =>
  register(
    'synchronization_info',
    import(/* webpackChunkName: "synchronizationInfo" */ './synchronization_info/index'),
  );

export const clientInfo = () =>
  register('client-info', import(/* webpackChunkName: "client-info" */ './client-info/index'));

// export const chat = () => register('chat', import(/* webpackChunkName: "chat" */ './chat/index'));

export const myJob = () =>
  register('myJob', import(/* webpackChunkName: "myJob" */ './my-job/index'));

export const unansweredWidget = () =>
  register(
    'unansweredWidget',
    import(/* webpackChunkName: "unansweredWidget" */ './email-appeal-count/index'),
  );

export const myRelationsWidget = () =>
  register(
    'myRelationsWidget',
    import(/* webpackChunkName: "myRelationsWidget" */ './my-relations/index'),
  );

export const callCenterWidget = () =>
  register(
    'callCenterWidget',
    import(/* webpackChunkName: "callCenterWidget" */ './call-center/index'),
    [clientExtendedInfo],
  );

export const unansweredChatMessageWidget = () =>
  register(
    'unansweredChatMessageWidget',
    import(/* webpackChunkName: "unansweredChatMessageWidget" */ './unanswered-chat-message/index'),
  );

export const callCenterCounterWidget = () =>
  register(
    'callCenterCounterWidget',
    import(/* webpackChunkName: "callCenterCounterWidget" */ './call-center-counter/index'),
  );

export const callCenterCounterNewWidget = () =>
  register(
    'callCenterCounterNewWidget',
    import(/* webpackChunkName: "callCenterCounterNewWidget" */ './call-center-counter-order-ct/index'),
  );

export const callCenterCounterReplacementWidget = () =>
  register(
    'callCenterCounterReplacementWidget',
    import(/* webpackChunkName: "callCenterCounterReplacementWidget" */ './call-center-counter-replacement-ct/index'),
  );

export const callCenterCounterClickWidget = () =>
  register(
    'callCenterCounterClickWidget',
    import(/* webpackChunkName: "callCenterCounterClickWidget" */ './call_task_counter_click_ct/index'),
  );

export const callCenterCounterUndeliveredWidget = () =>
  register(
    'callCenterCounterUndeliveredWidget',
    import('./call-task-counter-undelivered-ct/index'),
  );

export const callCenterCounterWithdrawWidget = () =>
  register(
    'callCenterCounterWithdrawWidget',
    import(/* webpackChunkName: "callCenterCounterWithdrawWidget" */ './call_task_counter_withdraw_ct/index'),
  );

export const chatAppealsCountWidget = () =>
  register(
    'chatAppealsCountWidget',
    import(/* webpackChunkName: "chatAppealsCountWidget" */ './chat-appeals-count/index'),
  );

export const questionComment = () =>
  register(
    'questionComment',
    import(/* webpackChunkName: "questionComment" */ './question-comment/index'),
  );

export default {
  /* inject export-widget don`t delete!!! */
  // chat_widget: {
  //   module: chat,
  // },
  chat_appeals_count: {
    module: chatAppealsCountWidget,
  },
  widget_my_add_sales: {
    module: myAdditionalSales,
  },
  history_widget: {
    module: history,
  },
  synchronization_info_widget: {
    module: synchronizationInfo,
  },

  clientInfo_widget: {
    module: clientInfo,
  },
  widget_question_comment: {
    module: questionComment,
  },
  widget_my_job_count: {
    module: myJob,
  },

  email_appeals_count: {
    module: unansweredWidget,
  },

  my_relations_count: {
    module: myRelationsWidget,
  },
  telephony: {
    module: callCenterWidget,
  },
  unanswered_chat_message_count: {
    module: unansweredChatMessageWidget,
  },
  call_task_counter: {
    module: callCenterCounterWidget,
  },
  call_task_counter_orders_ct: {
    module: callCenterCounterNewWidget,
  },
  call_task_counter_replacement_ct: {
    module: callCenterCounterReplacementWidget,
  },
  call_task_counter_click_ct: {
    module: callCenterCounterClickWidget,
  },
  call_task_counter_undelivered_ct: {
    module: callCenterCounterUndeliveredWidget,
  },
  call_task_counter_withdraw_ct: {
    module: callCenterCounterWithdrawWidget,
  },
};
