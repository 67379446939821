import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import summerBg from '../../assets/images/Summer.jpg';
import fallBg from '../../assets/images/fall.jpg';
import springBg from '../../assets/images/spring.jpg';
import winterBg from '../../assets/images/winter.png';
import halloweenBg from '../../assets/images/halloween.jpg';
// import fluid from './fluid';
import easter from '../../assets/images/easter.jpg';
import valentine from '../../assets/images/velentine.jpg';
import Winter from './winterBackground';
import Valentine from './valentineBackground';

const date = new Date();
const month = date.getMonth() + 1;
const day = date.getDate();

function getSeason() {
  switch (month) {
    case 12:
    case 1:
    case 2:
      return winterBg;
    case 3:
    case 4:
    case 5:
      return springBg;
    case 6:
    case 7:
    case 8:
      return summerBg;
    case 9:
    case 10:
    case 11:
      return fallBg;
    default:
      return winterBg;
  }
}

const compose = (...functions) => args => functions.reduceRight((arg, fn) => fn(arg), args);

/**
 *
 * @param {String} interval Date interval 04.03 - 05.11 or 01.12
 * @returns {Boolean}
 */
const checkDate = (interval, img) => bg => {
  try {
    const [from, to] = interval.split('-').map(i => i.trim().split('.'));

    const startDate = Number(from[1]) * 31 + Number(from[0]);
    const endDate = to ? Number(to[1]) * 31 + Number(to[0]) : startDate;
    const currentDate = month * 31 + day;

    return currentDate >= startDate && currentDate <= endDate ? img : bg;
  } catch (error) {
    console.warn(error);
    return bg;
  }
};

const background = compose(
  checkDate('30.09', halloweenBg), // хэллоуин
  checkDate('14.02', valentine), // св. валентина
  checkDate('08.03', <Valentine />), // 8 марта
  checkDate('01.05 - 04.05', easter), // пасха 2021
)(getSeason());

const styles = {
  main: {
    width: '100%',
    height: '100%',
    backgroundColor: '#2e303c',
  },
};
function DefaultBackground({ children }) {
  useEffect(() => {
    // fluid();
  }, []);

  if (typeof background === 'string') {
    return (
      <div
        style={{
          ...styles.main,
          background: `#2e303c url(${background}) center center / cover no-repeat `,
        }}>
        {children}
      </div>
    );
  }

  return (
    <div style={styles.main}>
      {background}
      {children}
    </div>
  );
}

DefaultBackground.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DefaultBackground;
