import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Instances from './components/instances';

const Item = props => (
  <Switch>
    <Route
      exact
      strict
      path="/chat/whatsapp/instances/index"
      component={routeProps => (
        <Instances {...props} queryParams={routeProps.location.search} title="Инстансы" />
      )}
    />
  </Switch>
);

export default Item;
