import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import routes from '../../../modules';
import icons from './icons';
import './asideNew.scss';

const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  docked: {
    backgroundColor: 'var(--color-sidebar)',
    borderRight: 0,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '&:-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerOpen: {
    backgroundColor: 'var(--color-sidebar)',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerClose: {
    backgroundColor: 'var(--color-sidebar)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '56px',
    color: '#eee',
  },
  listItemRoot: {
    padding: '0 5px',
  },
  collapseRoot: {
    backgroundColor: 'var(--color-sidebar-d)',
    padding: '10px 0',
  },
  listItemPrimary: {
    color: 'inherit',
    fontWeight: 'inherit',
  },
  listItemTextRoot: {
    padding: '0 10px',
  },
}));

const NestedList = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => setOpen(!open);

  return (
    <>
      <ListItem button onClick={handleClick} style={{ color: '#eee' }}>
        <ListItemIcon style={{ minWidth: 36, color: '#eee' }}>
          {icons[props.url] || icons.default}
        </ListItemIcon>
        <ListItemText
          classes={{
            root: classes.listItemTextRoot,
            primary: classes.listItemPrimary,
          }}
          primary={props.label}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse className={classes.collapseRoot} in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          <ListItem
            classes={{
              root: classes.listItemRoot,
            }}
            button>
            <NavLink className="sidebar__link" to={props.renderUrl(props.url)}>
              <ListItemText
                classes={{
                  primary: classes.listItemPrimary,
                }}
                primary={props.label}
              />
            </NavLink>
          </ListItem>
          {props.data.map(item => (
            <ListItem
              key={item.url}
              classes={{
                root: classes.listItemRoot,
              }}
              button>
              <NavLink className="sidebar__link" to={props.renderUrl(item.url)}>
                <ListItemText
                  classes={{
                    primary: classes.listItemPrimary,
                  }}
                  primary={item.label}
                />
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

NestedList.propTypes = {
  data: PropTypes.instanceOf(Array),
  url: PropTypes.string,
  label: PropTypes.string,
  renderUrl: PropTypes.func.isRequired,
};

NestedList.defaultProps = {
  data: [],
  url: '',
  label: '',
};

function MiniDrawer(props) {
  const classes = useStyles();

  const renderUrl = urlId => {
    if (routes[urlId]) {
      return routes[urlId].url || routes[urlId].route;
    }
    return '/';
  };

  const renderListItem = (item, ind, subItem) =>
    item.children.length ? (
      <NestedList
        key={item.url}
        url={item.url}
        label={item.label}
        renderUrl={renderUrl}
        data={item.children}
      />
    ) : (
      <ListItem button key={item.label + ind}>
        <NavLink
          className="sidebar__link-main"
          style={{ display: 'flex', width: '100%', alignItems: 'center' }}
          to={renderUrl(item.url)}>
          <ListItemIcon style={{ minWidth: 36, color: 'inherit' }}>
            {icons[item.url] || icons.default}
          </ListItemIcon>
          <ListItemText
            classes={{
              root: classes.listItemRoot,
            }}
            primary={item.label}
          />
        </NavLink>
      </ListItem>
    );

  const { open, handleToggle, data } = props;

  return (
    <Drawer
      variant="persistent"
      classes={{
        paperAnchorLeft: open ? classes.drawerOpen : classes.drawerClose,
      }}
      open={open}>
      <div className={classes.header}>
        <IconButton onClick={handleToggle} style={{ color: '#eee' }}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />

      <div className="scroll" style={{ height: 'calc(100% - 85px)', overflow: 'hidden auto' }}>
        <List>{data && data.map(renderListItem)}</List>
      </div>
      <div className="sidebar__version">
        <span>{`v ${process.env.REACT_APP_VERSION}`}</span>
        <span>{`${process.env.REACT_APP_BUILD}`}</span>
      </div>
    </Drawer>
  );
}

MiniDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array),
};

MiniDrawer.defaultProps = {
  data: [],
};

export default MiniDrawer;
