import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const renderSwitch = ({ input, style, label, labelPlacement, ...props }) => (
  <div style={style}>
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      control={<Switch checked={!!input.value} onChange={input.onChange} {...props} />}
    />
  </div>
);

renderSwitch.propTypes = {
  label: PropTypes.string,
  input: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
};

renderSwitch.defaultProps = {
  style: null,
};

renderSwitch.defaultProps = {
  label: '',
};

export default props => <Field component={renderSwitch} {...props} />;
