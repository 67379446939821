import { useState, useLayoutEffect } from 'react';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

const check = debounce((entries, cb) => {
  for (const entry of entries) {
    const width = entry.contentBoxSize
      ? entry.contentBoxSize[0].inlineSize
      : entry.contentRect.width;

    // const height = entry.contentBoxSize
    //   ? entry.contentBoxSize[0].blockSize
    //   : entry.contentRect.height;

    cb(width /* height */);
  }
}, 10);

function resizeObserve(cb) {
  return new ResizeObserver(entries => {
    // Обход ошибки ResizeObserver loop limit exceeded
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      check(entries, cb);
    });
  });
}

export default function useResizeObserver(containerRef, defaultState) {
  const [width, setWidth] = useState(null);

  useLayoutEffect(() => {
    if (defaultState) {
      setWidth(defaultState);
    }

    const ro = resizeObserve(debounce(setWidth, 300));

    ro.observe(containerRef.current);

    return () => ro.unobserve(containerRef.current);
  }, []);

  return width;
}
