import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import NavigationExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UserIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import './user-menu.scss';

function UserMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        style={{
          padding: 0,
          width: '60px',
          height: '60px',
        }}>
        <Avatar style={{ width: 32, height: 32 }}>
          <UserIcon />
        </Avatar>
        <NavigationExpandMoreIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {props.children}
      </Menu>
    </>
  );
}

UserMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default memo(UserMenu);
