import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
// import { routerReducer as router, routerMiddleware } from 'react-router-redux';
import { reducer as toastr } from 'lib/react-redux-toastr';
import { reducer as form } from 'redux-form';
import thunk from 'redux-thunk';
import user from '../modules/Login';
import system from '../modules/System';
import reducersAll from './reducers';
import history from './history';
import { deleteModule } from './module';

const defaultStore = {};

// const middlewareHistory = routerMiddleware(history);

/**
 * Отключение hot-reloada для нормальной работы тостов и прочих стороних компонентов
 * при динамической подгрузке редьюсеров
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      shouldHotReload: false,
      trace: true,
      traceLimit: 25,
    })
  : compose;

const storeInstance = (() => {
  const store = createStore(
    createReducer(),
    defaultStore,
    composeEnhancers(applyMiddleware(thunk)),
  );

  store.async = {};

  return store;
})();

export default storeInstance;

export function registerReducer(store, name, reducer) {
  store.async[name] = reducer;
  store.replaceReducer(createReducer(store.async));
}

export function unRegisterReducer(name) {
  delete storeInstance.async[name];
  storeInstance.replaceReducer(createReducer(storeInstance.async));
  deleteModule(name);
}

function createReducer(reducers) {
  return combineReducers({
    ...reducersAll,
    toastr,
    system,
    // router,
    form,
    user,
    root: (state = null) => state,
    ...reducers,
  });
}
