const REGISTER_HOT_KEYS = 'SET_HOT_KEYS';

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REGISTER_HOT_KEYS: {
      return {
        ...state,
        ...payload,
      };
    }

    default:
      return state;
  }
};

/**
 *
 * @param keyObj
 */
export const registerHotKeys = keyObj => (dispatch, getState) => {
  dispatch({
    type: REGISTER_HOT_KEYS,
    payload: keyObj,
  });
};
