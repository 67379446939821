import header from '../modules/Header';
import hotKeys from '../modules/HotKeys';
import attachViewer from '../modules/AttachViewer';
import modal from '../modules/Modal';

export default {
  header,
  hotKeys,
  attachViewer,
  modal,
};
