import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import icons from './icons';
import './asideNew.scss';

const useStyles = makeStyles(theme => ({
  listItemRoot: {
    padding: '0 5px',
  },
  collapseRoot: {
    backgroundColor: 'var(--color-sidebar-d)',
    padding: '10px 0',
  },
  listItemPrimary: {
    color: 'inherit',
    fontWeight: 'inherit',
  },
  listItemTextRoot: {
    padding: '0 10px',
  },
}));

export default function NestedList(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => setOpen(!open);

  return (
    <>
      <ListItem button onClick={handleClick} style={{ color: '#eee' }}>
        <ListItemIcon style={{ minWidth: 36, color: '#eee' }}>
          {icons[props.url] || icons.default}
        </ListItemIcon>
        <ListItemText
          classes={{
            root: classes.listItemTextRoot,
            primary: classes.listItemPrimary,
          }}
          primary={props.label}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse className={classes.collapseRoot} in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          <ListItem
            classes={{
              root: classes.listItemRoot,
            }}
            button>
            <NavLink className="sidebar__link" to={props.renderUrl(props.url)}>
              <ListItemText
                classes={{
                  primary: classes.listItemPrimary,
                }}
                primary={props.label}
              />
            </NavLink>
          </ListItem>
          {props.data.map(item => (
            <ListItem
              key={item.url}
              classes={{
                root: classes.listItemRoot,
              }}
              button>
              <NavLink className="sidebar__link" to={props.renderUrl(item.url)}>
                <ListItemText
                  classes={{
                    primary: classes.listItemPrimary,
                  }}
                  primary={item.label}
                />
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

NestedList.propTypes = {
  data: PropTypes.instanceOf(Array),
  url: PropTypes.string,
  label: PropTypes.string,
  renderUrl: PropTypes.func.isRequired,
};

NestedList.defaultProps = {
  data: [],
  url: '',
  label: '',
};
