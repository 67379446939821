/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

FilterInput.propTypes = {
  filterCb: PropTypes.func,
};

FilterInput.defaultProps = {
  filterCb: null,
};

export default function FilterInput({ filterCb }) {
  const [text, setText] = useState('');
  const [dirty, setDirty] = useState(false);

  const handleChange = ({ target }) => {
    setText(target.value);
  };

  const handleClear = () => {
    setDirty(false);
    filterCb('');
    setText('');
  };

  const handleSubmit = ({ key }) => {
    if (key && key !== 'Enter') {
      return false;
    }

    filterCb(text);
    setDirty(true);
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      disabled={!filterCb}
      inputProps={{ style: { padding: '4px 8px' } }}
      style={{ opacity: !filterCb ? 0.3 : 1 }}
      value={text}
      onChange={handleChange}
      onKeyPress={handleSubmit}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {text && !dirty && (
              <IconButton onClick={handleSubmit} size="small" edge="end">
                <SearchIcon />
              </IconButton>
            )}
            {text && dirty && (
              <IconButton onClick={handleClear} size="small" edge="end">
                <CloseIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}
