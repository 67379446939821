import store from '../store';
import module from '../store/module';

const register = module(store);

/**
 * Регистрация всех модулей
 * register (
 *   'name', - уникальное имя модуля
 *   import( ... ), - динамический импорт
 *   [ module1, module2 ], - зависимости
 * )
 *
 */

export const bonuses = () =>
  register('bonuses', import(/* webpackChunkName: "bonuses" */ './bonuses/index'));

export const orderItem = () =>
  register('order-item', import(/* webpackChunkName: "order-item" */ './order-item/index'));

export const odinAss = () =>
  register('odin-ass', import(/* webpackChunkName: "odin-ass" */ './odin-ass/index'));

export const rafflePrizes = () =>
  register(
    'raffle-prizes',
    import(/* webpackChunkName: "raffle-prizes" */ './raffle-prizes/index'),
  );

export const smsPattern = () =>
  register('smsPattern', import(/* webpackChunkName: "smsPattern" */ './sms-pattern/index'));

export const loyalty = () =>
  register('loyalty', import(/* webpackChunkName: "loyalty" */ './loyalty/index'));

export const balance = customName =>
  register(customName || 'balance', import(/* webpackChunkName: "balance" */ './balance/index'));

export const refunds = customName =>
  register(customName || 'refunds', import(/* webpackChunkName: "refunds" */ './refunds/index'));

export const callCenterDynamicList = () =>
  register(
    'callCenterDynamicList',
    import(/* webpackChunkName: "callCenterDynamicList" */ './call-center-dynamic-list/index'),
  );

export const chatInstagramPartnersMedia = () =>
  register(
    'chatInstagramPartnersMedia',
    import(/* webpackChunkName: "chatInstagramPartnersMedia" */ './chat-instagram-partners-media/index'),
  );

export const ChatInstagramAccounts = () =>
  register(
    'ChatInstagramAccounts',
    import(/* webpackChunkName: "ChatInstagramAccounts" */ './chat-instagram-accounts/index'),
  );

export const ChatInstagramPushSources = () =>
  register(
    'ChatInstagramPushSources',
    import(/* webpackChunkName: "ChatInstagramPushSources" */ './chat-instagram-push-sources/index'),
  );

export const clientInfoStatus = () =>
  register(
    'clientInfoStatus',
    import(/* webpackChunkName: "clientInfoStatus" */ './client-info-status/index'),
  );

export const ChatInstagramTags = () =>
  register(
    'ChatInstagramTags',
    import(/* webpackChunkName: "ChatInstagramTags" */ './chat-instagram-tags/index'),
  );

export const missedCalls = () =>
  register(
    'missedCalls',
    import(/* webpackChunkName: "missed-missedCalls" */ './missed-calls/index'),
  );

export const tariff = () =>
  register('tariff', import(/* webpackChunkName: "tariff" */ './tariff/index'));

export const home = () => register('home', import(/* webpackChunkName: "home" */ './home/index'));

export const currencies = () =>
  register('currencies', import(/* webpackChunkName: "currencies" */ './currencies/index'));

export const access = () =>
  register('access', import(/* webpackChunkName: "access" */ './access/index'));

export const reports = () =>
  register('reports', import(/* webpackChunkName: "reports" */ './reports/index'));

export const newEmail = () =>
  register('emailWriter', import(/* webpackChunkName: "newEmail" */ './email-writer/index'), [
    mediaUploader,
  ]);

export const responses = () =>
  register('responses', import(/* webpackChunkName: "responses" */ './responses/index'));

export const spam = () => register('spam', import(/* webpackChunkName: "spam" */ './spam/index'));

export const options = () =>
  register('options', import(/* webpackChunkName: "options" */ './options/index'));

export const crm = () =>
  register('crm', import(/* webpackChunkName: "crm" */ './crm/index'), [callCenter, clientCard]);

export const crmXZibitNew = () =>
  register('crm2', import(/* webpackChunkName: "crm-x-zibit-new" */ './crm-x-zibit-new/index'), [
    callCenter,
  ]);

export const crmXZibit = () =>
  register('crm-x-zibit', import(/* webpackChunkName: "crm-x-zibit" */ './crm-x-zibit/index'), [
    callCenter,
  ]);

export const crmRelations = () =>
  register('crmRelations', import(/* webpackChunkName: "crmRelations" */ './crm-relations/index'));

export const crmRelationsUnanswered = () =>
  register(
    'crmRelationsUnanswered',
    import(/* webpackChunkName: "crmRelationsUnanswered" */ './crm-email-unanswered/index'),
  );

export const telegram = () =>
  register('telegram', import(/* webpackChunkName: "telegram" */ './telegram/index'));

export const callCenter = (customName = null) =>
  register(
    customName || 'callCenter',
    import(/* webpackChunkName: "callCenter" */ './call-center/index'),
    [productOrder, abandonedCart, crm],
  );

export const callCenterAdmin = () =>
  register(
    'callCenterAdmin',
    import(/* webpackChunkName: "callCenterAdmin" */ './call-center-admin/index'),
  );

export const callCenterOperators = () =>
  register(
    'callCenterOperators',
    import(/* webpackChunkName: "callCenterOperators" */ './call-center-operators/index'),
  );

export const clientExtendedInfo = () =>
  register(
    'clientExtendedInfo',
    import(/* webpackChunkName: "clientExtendedInfo" */ './client-extended-info/index'),
    [clientCard],
  );

export const callCenterSources = () =>
  register(
    'callCenterSources',
    import(/* webpackChunkName: "callCenterSources" */ './call-center-tasks/index'),
  );

export const callCenterTasks = () =>
  register(
    'callCenterTasks',
    import(/* webpackChunkName: "callCenterTasks" */ './call-center-call-tasks/index'),
  );

export const instagramReminders = () =>
  register(
    'instagramReminders',
    import(/* webpackChunkName: "instagramReminders" */ './chat-instagram-reminders/index'),
    [crm],
  );

export const chatInstagramMedia = () =>
  register(
    'chatInstagramMedia',
    import(/* webpackChunkName: "chatInstagramMedia" */ './chat-instagram-media/index'),
  );

export const chatWhatsappInstances = () =>
  register(
    'chatWhatsappInstances',
    import(/* webpackChunkName: "chatWhatsappInstances" */ './chat-whatsapp-instances/index'),
  );

export const callsReasonGroup = () =>
  register(
    'callsReasonGroup',
    import(/* webpackChunkName: "callsReasonGroup" */ './call-center-reasons/index'),
  );

export const mediaUploader = () =>
  register(
    'mediaUploader',
    import(/* webpackChunkName: "mediaUploader" */ './media-uploader/index'),
  );

export const salaries = () =>
  register('salaries', import(/* webpackChunkName: "salaries" */ './salaries/index'));

export const dynamicCallList = () =>
  register(
    'dynamicCallList',
    import(/* webpackChunkName: "dynamicCallList" */ './dynamic-call-list/index'),
  );

export const callСenterSourcesTemplates = () =>
  register(
    'callСenterSourcesTemplates',
    import(/* webpackChunkName: "callСenterSourcesTemplates" */ './call-center-sources-templates/index'),
  );

export const finesAndBonuses = () =>
  register(
    'finesAndBonuses',
    import(/* webpackChunkName: "finesAndBonuses" */ './fines-and-bonuses/index'),
  );

export const finesAndBonusesConstructor = () =>
  register(
    'finesAndBonusesConstructor',
    import(/* webpackChunkName: "finesAndBonusesConstructor" */ './fines-and-bonuses-constructor/index'),
  );

export const collisions = () =>
  register('collisions', import(/* webpackChunkName: "collisions" */ './collisions/index'));

export const staff = () =>
  register('staff', import(/* webpackChunkName: "staff" */ './staff/index'));

export const productOrder = (customName = null) =>
  register(
    customName || 'productOrder',
    import(/* webpackChunkName: "productOrder" */ './product-order/index'),
  );

export const abandonedCart = (customName = null) =>
  register(
    customName || 'abandonedCart',
    import(/* webpackChunkName: "abandonedCart" */ './abandoned-cart/index'),
  );

export const help = () => register('help', import(/* webpackChunkName: "help" */ './help/index'));

export const clientCard = (customName = null) =>
  register(
    { name: 'clientCard', nameSpace: customName },
    import(/* webpackChunkName: "clientCard" */ './client-card/index'),
  );

export const mailingBot = () =>
  register('mailingBot', import(/* webpackChunkName: "mailingBot" */ './mailing-bot/index'));

export const shopsList = () =>
  register('shopsList', import(/* webpackChunkName: "shopsList" */ './shops-list/index'));

export const paymentRecipients = () =>
  register(
    'paymentRecipients',
    import(/* webpackChunkName: "paymentRecipients" */ './payment-recipients/index'),
  );

export default {
  /* inject export-module don`t delete!!! */
  '/bonus/actions-filter': {
    route: '/bonus/actions-filter',
    module: bonuses,
    exact: false,
  },

  '/bonus/reasons-list': {
    route: '/bonus/reasons-list',
    module: bonuses,
    exact: false,
  },

  '/promotion/winners': {
    route: '/promotion/winners',
    module: rafflePrizes,
    exact: false,
  },

  '/order/notification-patterns': {
    route: '/order/notification-patterns',
    module: smsPattern,
    exact: false,
  },

  '/odinAss': {
    route: '/odinAss',
    module: odinAss,
  },

  '/shops-list': {
    route: '/shops-list',
    module: shopsList,
  },

  '/payment-recipients': {
    route: '/payment-recipients',
    module: paymentRecipients,
  },

  '/promotion': {
    route: '/promotion',
    module: mailingBot,
  },

  '/call-center/missed-calls': {
    route: '/call-center/missed-calls',
    module: missedCalls,
    exact: false,
  },

  '/chat/instagram/reminders/list': {
    route: '/chat/instagram/reminders/list',
    module: instagramReminders,
    exact: false,
  },

  '/tariffs': {
    route: '/tariffs',
    module: tariff,
    exact: false,
  },

  '/': {
    route: '/',
    module: home,
  },

  '/help/currency-updates': {
    route: '/help/currency-updates',
    module: currencies,
    exact: false,
  },

  // TODO: remove me
  // '/emails': {
  //   route: '/emails',
  //   module: email,
  //   exact: false,
  // },

  '/crm/appeal/create': {
    route: '/crm/appeal/create',
    module: newEmail,
  },

  '/admin-accesses': {
    route: '/admin-accesses',
    module: access,
  },

  '/reports': {
    route: '/reports',
    module: reports,
    exact: false,
  },

  // TODO: remove me
  // '/email/relations/list': {
  //   url: '/email/relations/list',
  //   route: '/email/relations/:id',
  //   module: relations,
  // },

  // '/email/relations/my': {
  //   url: '/email/relations/my',
  //   route: '/email/relations/:id',
  //   module: relations,
  // },

  '/fast_responses/my': {
    route: '/fast_responses/my/',
    module: responses,
    exact: false,
  },

  '/fast_responses/system': {
    route: '/fast_responses/system/',
    module: responses,
    exact: false,
  },

  '/crm/spam': {
    route: '/crm/spam',
    module: spam,
  },

  // TODO: remove me
  // '/email/list': {
  //   route: '/email/list',
  //   module: unanswered,
  // },

  '/options': {
    route: '/options',
    module: options,
    exact: false,
  },

  '/crm/relations': {
    url: '/crm-relations/list',
    route: '/crm-relations/:id',
    module: crmRelations,
  },

  '/crm/relations/my': {
    url: '/crm-relations/my',
    route: '/crm-relations/:id',
    module: crmRelations,
  },

  '/crm/appeals/store/emails': {
    url: '/crm-appeals/store/emails',
    route: '/crm-appeals/store/:id',
    module: crmRelationsUnanswered,
  },

  '/crm/appeals/store/chats': {
    url: '/crm-appeals/store/chats',
    route: '/crm-appeals/store/:id',
    module: crmRelationsUnanswered,
  },

  '/crm/appeals/store/instagram': {
    url: '/crm-appeals/store/instagram',
    route: '/crm-appeals/store/:id',
    module: crmRelationsUnanswered,
  },

  '/crm': {
    route: '/crm',
    module: crm,
    exact: false,
  },

  '/crm-x-zibit': {
    route: '/crm-x-zibit',
    module: crmXZibit,
    // exact: false,
  },

  '/crm-x-zibit-new': {
    route: '/crm-x-zibit-new',
    module: crmXZibitNew,
    // exact: false,
  },

  '/chat/instagram': {
    route: '/chat/instagram/media/index',
    url: '/chat/instagram/media/index',
    module: chatInstagramMedia,
    exact: true,
  },

  '/chat/instagram/media/index': {
    route: '/chat/instagram/media/index',
    url: '/chat/instagram/media/index',
    module: chatInstagramMedia,
    exact: true,
  },

  // '/chat/instagram/media/index': {
  //   route: '/chat/instagram/media/index',
  //   url: '/chat/instagram/media/index',
  //   module: chatInstagramMedia,
  // },

  '/chat/whatsapp': {
    route: '/chat/whatsapp/instances/index',
    url: '/chat/whatsapp/instances/index',
    module: chatWhatsappInstances,
    exact: true,
  },

  '/chat/whatsapp/instances/index': {
    route: '/chat/whatsapp/instances/index',
    url: '/chat/whatsapp/instances/index',
    module: chatWhatsappInstances,
    exact: true,
  },

  '/chat/instagram/partner_media/index': {
    route: '/chat/instagram/partner_media/index',
    url: '/chat/instagram/partner_media/index',
    module: chatInstagramPartnersMedia,
    exact: true,
  },

  '/crm/client-info-predicted/status_admin/index': {
    route: '/crm/client-info-predicted/status_admin/index',
    url: '/crm/client-info-predicted/status_admin/index',
    module: clientInfoStatus,
  },

  '/chat/instagram/push_setting/index': {
    route: '/chat/instagram/push_setting/index',
    url: '/chat/instagram/push_setting/index',
    module: ChatInstagramPushSources,
  },

  '/chat/instagram/tags/index': {
    route: '/chat/instagram/tags/index',
    url: '/chat/instagram/tags/index',
    module: ChatInstagramTags,
  },

  '/chat/instagram/accounts/index': {
    route: '/chat/instagram/accounts/index',
    url: '/chat/instagram/accounts/index',
    module: ChatInstagramAccounts,
  },

  '/call-center/call-reason/group/list': {
    route: '/call-center/call-reason/:group',
    url: '/call-center/call-reason/group/list',
    module: callsReasonGroup,
    exact: false,
  },

  '/telegram/channel_members': {
    route: '/telegram/channel_members',
    module: telegram,
    exact: false,
  },

  '/telegram/members_counts': {
    route: '/telegram/members_counts',
    module: telegram,
    exact: false,
  },

  '/telegram/channel': {
    route: '/telegram/channel',
    module: telegram,
    exact: false,
  },

  '/call-center': {
    route: '/call-center',
    module: callCenter,
    exact: false,
  },

  '/call-center/admin_sips': {
    url: '/call-center-admin',
    route: '/call-center-admin',
    module: callCenterAdmin,
    exact: false,
  },

  '/call-center/sip_relations': {
    url: '/call-center-operators',
    route: '/call-center-operators',
    module: callCenterOperators,
    exact: false,
  },

  '/call-center/source/list_admin': {
    url: '/call-center-source/list_admin',
    route: '/call-center-source/list_admin',
    module: callCenterSources,
    exact: false,
  },

  '/media': {
    route: '/media',
    module: mediaUploader,
  },

  '/call-center/tasks/my': {
    url: '/call-center/tasks/my',
    route: '/call-center/tasks/:id',
    module: callCenterTasks,
  },

  '/call-center/tasks/all': {
    url: '/call-center/tasks/all',
    route: '/call-center/tasks/:id',
    module: callCenterTasks,
  },

  '/call-center/tasks/my_opened': {
    url: '/call-center/tasks/my_opened',
    route: '/call-center/tasks/:id',
    module: callCenterTasks,
  },

  '/call-center/tasks/in_process': {
    url: '/call-center/tasks/in_process',
    route: '/call-center/tasks/:id',
    module: callCenterTasks,
  },

  '/call-center/dynamic-source': {
    url: '/dynamic-source',
    route: '/dynamic-source',
    module: callCenterDynamicList,
    exact: false,
  },

  '/call-center/dynamic-upload': {
    url: '/call-center/dynamic-upload',
    route: '/call-center/dynamic-upload',
    module: dynamicCallList,
    exact: false,
  },

  '/call-center/template/list': {
    route: '/call-center/template/list',
    module: callСenterSourcesTemplates,
    exact: false,
  },

  '/job-accounting/assignment': {
    route: '/job-accounting/assignment',
    module: finesAndBonuses,
    exact: false,
  },

  '/job-accounting/attendance-by-month': {
    route: '/job-accounting/attendance-by-month',
    module: salaries,
    exact: false,
  },

  '/calendar/week': {
    route: '/calendar/week',
    module: salaries,
    exact: false,
  },

  '/job-accounting/motivation': {
    route: '/job-accounting/motivation',
    module: finesAndBonusesConstructor,
    exact: false,
  },

  '/collision': {
    route: '/collision',
    module: collisions,
    exact: false,
  },

  '/collision/admin': {
    route: '/collision/admin',
    module: collisions,
    exact: false,
  },

  '/staff/employers': {
    url: '/staff/employers',
    route: '/staff/employers',
    module: staff,
    exact: false,
  },

  '/help': {
    route: '/help',
    module: help,
    exact: false,
  },
};
