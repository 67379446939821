import { toastr } from 'lib/react-redux-toastr';
import Raven from 'raven-js';
import history from '../store/history';
import { USER_LOGGED_OUT, USER_KEY } from '../modules/Login';

/* global apiHost */
export const apiUrl = typeof apiHost !== 'undefined' ? apiHost : process.env.REACT_APP_API_URL;

/**
 *
 * @param error
 */
export function errorHandler(error) {
  if (error.name === 'TypeError') {
    const err = {
      title: 'Oops! Something wrong',
    };

    if (error.message === 'Failed to fetch') {
      err.message = 'Server is unavailable (Failed to fetch)';
    } else {
      err.message = error.message;
    }
    throw err;
  } else {
    throw error;
  }
}

/**
 *
 * @param response
 * @returns {*}
 */
export function status(response) {
  const { status } = response;
  if (status >= 200 && status < 300) {
    return Promise.resolve(response, response.status);
  }

  if (status === 403) {
    toastr.error('Ой!', 'У вас нет прав для данного запроса');
    return Promise.reject(response);
  }

  if (status === 409) {
    return response
      .json()
      .then(res => {
        if (res.errorMessage) {
          toastr.error('Ой!', res.errorMessage);
        } else {
          toastr.error('Ой!', 'Данное действие закреплено за другим менеджером');
        }
      })
      .finally(() => Promise.reject(response));
  }

  if (status === 400) {
    return Promise.reject(response);
  }

  // Пропускаем без уведомления на верхний слой обработок ошибок
  if (status === 401 || status === 404 || status === 412 || status === 422) {
    return Promise.reject(response);
  }

  if (status === 429) {
    // toastr.error('Oops! 429', `Too Many Requests ${response.url}`);
    Raven.captureMessage('== 429 ==', { level: 'info' });
    return Promise.reject(response);
  }

  if (status === 500) {
    toastr.error('Ой!', '500 Ошибка сервера');
    Raven.captureMessage('== 500 ==', { level: 'info' });
    return Promise.reject(response);
  }

  if (status === 502) {
    toastr.error('Ой!', '502 Что-то с сервером');
    Raven.captureMessage('== 502 ==', { level: 'info' });
    return Promise.reject(response);
  }

  response
    .json()
    .then(data => {
      console.error(data);
      toastr.error('Oops!', 'Something wrong. Check console from detail information');
      throw new Error(JSON.stringify(data));
    })
    // .catch(err => toastr.error('Oops!', 'Something wrong'))
    .finally(() => Promise.reject(response));
}

/**
 *
 * @param data
 * @returns {object}
 */
export function json(data) {
  if (data.status === 204) return false;
  if (data.status === 409) return false;
  return data.json();
}

/**
 * @returns {object}
 */
const getOptions = () => ({
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Auth-Token': localStorage.getItem(USER_KEY) || '',
  },
  // mode: 'no-cors',
});

/**
 * @param body
 * @returns {object}
 */
export const postOptions = (body = {}, method = 'post') => ({
  method,
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Auth-Token': localStorage.getItem(USER_KEY) || '',
  },
  body: JSON.stringify(body),
});

/**
 *
 * @param promises
 * @return {Promise.<TResult>}
 */
export function multiplyFetchWrapper(promises) {
  return (
    Promise.all(promises)
      // .then(multiStatus)
      // .then(multiJson)
      // .then(success => success)
      .catch(errorHandler)
  );
}

/**
 *
 * @param url
 */
export function getFetchWrapper(url = '', apiBaseUrl = '') {
  return fetch(`${apiBaseUrl || apiUrl}${url}`, getOptions())
    .then(status)
    .then(json)
    .catch(errorHandler);
}

/**
 *
 * @param url
 * @param body
 */
export function postFetchWrapper(url = '', body) {
  return fetch(`${apiUrl}${url}`, postOptions(body))
    .then(status)
    .then(json)
    .catch(errorHandler);
}

/**
 *
 * @param url
 * @param body
 */
export function deleteFetchWrapper(url = '', body) {
  return fetch(`${apiUrl}${url}`, postOptions(body, 'delete'))
    .then(status)
    .then(json)
    .catch(errorHandler);
}

/**
 *
 * @param url
 * @param body
 */
export function putFetchWrapper(url = '', body) {
  return fetch(`${apiUrl}${url}`, postOptions(body, 'put'))
    .then(status)
    .then(json)
    .catch(errorHandler);
}

/**
 *
 * @param dispatch
 * @returns {function(*)}
 */
export function errorActionHandler(dispatch, errText) {
  return error => {
    if (error.status && error.status === 401) {
      dispatch({ type: USER_LOGGED_OUT });
      history.push('/login');
      throw error;
    }

    if (error.status && error.status === 404) {
      toastr.info('404', `Нет данных для отображения`);
    }

    if (error.status && error.status === 429) {
      toastr.info('429', 'Воу! не так быстро!');
    }

    if (process.env.NODE_ENV !== 'production') {
      toastr.error(
        `Ошибка при обработке ${errText}`,
        `${error.message || 'не обработанное исключение'}`,
      );
    }

    // new Error(error, errText);
    throw error;
  };
}

/**
 *
 * @param dispatch
 * @returns {function(*)}
 */
export function customErrorActionHandler(errText) {
  return error => {
    if (error.status && error.status === 404) {
      toastr.info('404', `Нет данных для отображения`);
    } else {
      toastr.error(
        `Ошибка при обработке ${errText}`,
        `${error.message || 'не обработанное исключение'}`,
      );
    }
    // new Error(error, errText);
    throw error;
  };
}

export const paramsToQueryString = params => {
  const resultObject = {};

  Object.keys(params).forEach(key => {
    // do not apply undefined values
    if (params[key] !== undefined && params[key] !== '') {
      resultObject[key] = params[key];
    }
  });

  return decodeURIComponent(new URLSearchParams(resultObject).toString());
};
