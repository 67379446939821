import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import translatePhrases from '../../../modules/call-center/translatePhrases';

const digitalSize = {
  XS: 40,
  S: 42,
  M: '44/46',
  L: '48/50',
  XL: 52,
  '2XL': '54/56',
  '3XL': 58,
};

const CustomTooltip = forwardRef((props, ref) => (
  <span ref={ref} {...props}>
    {props.children}
  </span>
));

CustomTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

const StyledTableCell = color =>
  withStyles(theme => ({
    head: { color },
    body: { color, padding: '4px' },
  }))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.14)',
    },
  },
}))(TableRow);

export default function ProductTableSizes({ product, currentSize, style, colorText }) {
  const Cell = StyledTableCell(colorText);

  return (
    <Table size="small" style={style}>
      <TableHead>
        <TableRow>
          <Cell padding="none">Разм.</Cell>
          <Cell colSpan={product.stores.length || 5} align="center">
            Остатки (шт.)
          </Cell>
        </TableRow>
        <TableRow>
          <Cell />
          {product.stores.map(store => (
            <Cell key={store} align="center" padding="none">
              <Tooltip title={translatePhrases(store)} placement="top">
                <CustomTooltip>{store}</CustomTooltip>
              </Tooltip>
            </Cell>
          ))}

          {!product.stores.length && (
            <>
              <Cell align="center" padding="none">
                <Tooltip title="Ривьера" placement="top">
                  <CustomTooltip>RIV</CustomTooltip>
                </Tooltip>
              </Cell>
              <Cell align="center" padding="none">
                <Tooltip title="Ситицентр" placement="top">
                  <CustomTooltip>CIT</CustomTooltip>
                </Tooltip>
              </Cell>
              <Cell align="center" padding="none">
                <Tooltip title="Лавина" placement="top">
                  <CustomTooltip>LVN</CustomTooltip>
                </Tooltip>
              </Cell>
              <Cell align="center" padding="none">
                <Tooltip title="Блокбастер" placement="top">
                  <CustomTooltip>BLO</CustomTooltip>
                </Tooltip>
              </Cell>
              <Cell align="center" padding="none">
                Склад
              </Cell>
            </>
          )}

          {/* <Cell align="center" padding="none">
            <Tooltip title="склад / резерв" placement="top">
              <CustomTooltip>Брянск</CustomTooltip>
            </Tooltip>
          </Cell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {product.sizes &&
          product.sizes.map(({ size, balances = {}, stockBalances = {}, isAvailable }) => {
            const styleColor = !isAvailable ? { color: 'red' } : null;
            return (
              <StyledTableRow
                key={size}
                style={{
                  backgroundColor: size === currentSize ? 'var(--color-1-light)' : 'transparent',
                }}>
                <Cell component="th" scope="row">
                  {`${size}  ${digitalSize[size.toUpperCase()] || ''}`}
                </Cell>

                {product.stores.map(store => (
                  <Cell key={store} align="center" style={styleColor}>
                    {balances[store] || 0}
                  </Cell>
                ))}

                {!product.stores.length && (
                  <>
                    <Cell align="center" style={styleColor}>
                      {stockBalances.riviera || 0}
                    </Cell>
                    <Cell align="center" style={styleColor}>
                      {stockBalances.cityCenter || 0}
                    </Cell>
                    <Cell align="center" style={styleColor}>
                      {stockBalances.lavina || 0}
                    </Cell>
                    <Cell align="center" style={styleColor}>
                      {stockBalances.blockbuster || 0}
                    </Cell>
                    <Cell align="center" style={styleColor}>
                      {stockBalances.stockhouse || 0}
                    </Cell>
                  </>
                )}

                {/* <Cell align="center" style={styleColor}>
                  {`${stockBalances.bryansk || 0}/${stockBalances.bryanskReserve || 0}`}
                </Cell> */}
              </StyledTableRow>
            );
          })}
      </TableBody>
    </Table>
  );
}

ProductTableSizes.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
  currentSize: PropTypes.string,
  colorText: PropTypes.string,
};

ProductTableSizes.defaultProps = {
  currentSize: '',
  colorText: '#fff',
  style: null,
};
