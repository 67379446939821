import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import { toastr } from 'lib/react-redux-toastr';
import CreateOrderModal from './create-order-modal';
import { postFetchWrapper } from '../../../utils/apiHelper';
import modalManager from '../../../utils/modalManager';

const styles = theme => ({
  button: {
    padding: 0,
  },
  buttonProgress: {
    color: 'gray',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class CreateOrderButton extends Component {
  state = {
    loading: false,
  };

  handleGetOrder = () => {
    if (this.props.phoneNumber) {
      const toastrConfirmOptions = {
        onOk: () => this.createOrder(this.props.phoneNumber),
        onCancel: () => console.info('CANCEL'),
      };

      toastr.confirm('Вы точно хотите создать новый заказ?', toastrConfirmOptions);
    } else {
      modalManager.registerModal(
        'createOrderModal',
        <CreateOrderModal
          onSubmit={number => {
            this.props.closeModal('createOrderModal');
            this.createOrder(number);
          }}
        />,
        {
          title: 'Создать заказ',
        },
      );

      this.props.openModal('createOrderModal');
    }
  };

  createOrder = phoneNumber => {
    this.setState({ loading: true });

    postFetchWrapper('/app/order/generate_by_phone', { phoneNumber })
      .then(({ taskId }) =>
        window.open(`${window.location.origin}/call-center/one_click/${taskId}`, '_blank'),
      )
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { classes, style, iconStyle } = this.props;
    const { loading } = this.state;

    return (
      <Tooltip title="Создать заказ">
        <div style={{ display: 'inline-block' }}>
          <IconButton
            className={classes.button}
            aria-label="create order"
            disabled={loading}
            onClick={this.handleGetOrder}
            style={{ ...style }}>
            <>
              <ShoppingCart
                className="search__icon"
                style={{ opacity: loading ? 0.3 : 1, ...iconStyle }}
              />
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </>
          </IconButton>
        </div>
      </Tooltip>
    );
  }
}

CreateOrderButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  style: PropTypes.instanceOf(Object),
  iconStyle: PropTypes.instanceOf(Object),
  phoneNumber: PropTypes.string,
};

CreateOrderButton.defaultProps = {
  phoneNumber: '',
  iconStyle: {},
  style: {},
};

export default withStyles(styles)(CreateOrderButton);
