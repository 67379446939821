import React from 'react';
import PropTypes from 'prop-types';
import './data-header.scss';

const DataHeader = props => (
  <div
    className="data-header"
    style={{ ...props.style, boxShadow: '0px 3px 4px 0px var(--color-background)' }}>
    <h3 className="data-header__title" style={{ ...props.titleStyle }}>
      {props.title}
    </h3>
    <div className="data-header__content" style={{ ...props.contentStyle }}>
      {props.children}
    </div>
    <div className="data-header__actions" style={{ ...props.actionsStyle }}>
      {props.actions}
    </div>
  </div>
);

DataHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  actions: PropTypes.element,
  children: PropTypes.element,
  style: PropTypes.instanceOf(Object),
  titleStyle: PropTypes.instanceOf(Object),
  contentStyle: PropTypes.instanceOf(Object),
  actionsStyle: PropTypes.instanceOf(Object),
};

DataHeader.defaultProps = {
  actions: null,
  children: null,
  style: {},
  titleStyle: {},
  contentStyle: {},
  actionsStyle: {},
};

export default DataHeader;
