import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/core/SvgIcon/SvgIcon';
import Pagination from '@material-ui/lab/Pagination';
import RefreshIcon from '@material-ui/icons/Refresh';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Tooltip from '@material-ui/core/Tooltip';
import Preloader from '../../../components/simple/preloader';
import DataTable from '../../../components/composite/data-table';
import DataHeader from '../../../components/composite/data-header';

const styles = {
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inputBase: {
    width: '250px',
    marginLeft: 8,
    flex: 1,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px -3px 4px 0px var(--color-background)',
  },
};

class Instances extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.queryParams);

    const limit = 20;

    let urlPage = 0;

    if (params.get('page')) {
      urlPage = Math.abs(params.get('page') - 1);
    }

    const url = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?page=${urlPage + 1}`;
    window.history.pushState({ path: url }, '', url);

    const offset = Math.ceil(urlPage * limit);

    this.state = {
      filter: {
        filters: {
          phone: {
            name: 'phone',
            title: 'Телефон',
            type: 'input',
            value: null,
            like: '%%%s%%',
            execute: false,
          },
        },
        tabs: {},
        sorters: {
          createdAt: {
            name: 'createdAt',
            title: 'Дата создания',
            value: 'DESC',
            items: [
              {
                label: 'ASC',
                value: 'ASC',
              },
              {
                label: 'DESC',
                value: 'DESC',
              },
            ],
            execute: true,
          },
        },
        control: {
          limit,
          offset,
        },
        class: 'Chat\\WhatsAppBundle\\Entity\\WhatsAppInstance',
      },
      isLoading: true,
      processed: [],
    };

    this.props.setHeaderElements({
      title: 'Инстансы',
    });

    const { filter } = this.state;
    this.props.getItems(filter).then(() => this.setState({ isLoading: false }));
  }

  handleChangePage = page => {
    const { filter } = this.state;
    const offset = Math.ceil(page * filter.control.limit);

    const urlPage = page;

    const url = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?page=${urlPage + 1}`;
    window.history.pushState({ path: url }, '', url);

    const updatedFilter = {
      ...filter,
      control: { ...filter.control, offset },
    };

    this.setState({ filter: updatedFilter, isLoading: true });
    this.props
      .getItems(updatedFilter)
      .then(() => this.setState({ filter: updatedFilter, isLoading: false }));
  };

  handleChangePhone = e => {
    const { filter } = this.state;

    const updatedFilter = {
      ...filter,
      filters: {
        ...filter.filters,
        phone: {
          ...filter.filters.phone,
          value: e.target.value,
          execute: !!e.target.value.length,
        },
      },
      control: { ...filter.control, offset: 0 },
    };

    this.setState({ filter: updatedFilter });
  };

  handleKeyDownSubmitForm = e => {
    if (e.key === 'Enter') {
      this.handleSubmitForm();
    }
  };

  handleSubmitForm = () => {
    const { filter } = this.state;
    this.setState({ isLoading: true });
    const url = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?page=1`;
    window.history.pushState({ path: url }, '', url);
    this.props.getItems(filter).then(() => this.setState({ isLoading: false }));
  };

  handleCheckStatus = (e, instance) => {
    const { processed } = this.state;
    processed.push(instance.id);
    this.setState({ processed });
    this.props.checkStatus(instance.id).finally(() => {
      this.setState({
        processed: processed.filter(item => item !== instance.id),
      });
    });
  };

  handleTakeOver = (e, instance) => {
    const { processed } = this.state;
    processed.push(instance.id);
    this.setState({ processed });

    this.props.takeOver(instance.id).finally(() => {
      this.setState({
        processed: processed.filter(item => item !== instance.id),
      });
    });
  };

  renderInstanceUpdate = itemRow => {
    const { processed } = this.state;
    return (
      <Tooltip title="Проверить статус аккаунта">
        <IconButton
          color="secondary"
          aria-label="Проверить статус аккаунта"
          disabled={processed.includes(itemRow.id)}
          onClick={event => this.handleCheckStatus(event, itemRow)}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    );
  };

  renderInstanceAction = itemRow => {
    const { processed } = this.state;
    const actionsList = [];

    if (itemRow.statusData && itemRow.statusData.actions) {
      Object.keys(itemRow.statusData.actions).forEach(item => {
        actionsList.push(item);
      });
    }

    return (
      <>
        {actionsList.includes('takeover') && (
          <Tooltip title="Использовать здесь">
            <IconButton
              color="secondary"
              aria-label="Использовать здесь"
              disabled={processed.includes(itemRow.id)}
              onClick={event => this.handleTakeOver(event, itemRow)}>
              <ControlPoint />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  renderStatus = (itemRow, item, key) => {
    return itemRow.statusData && itemRow.statusData.msg ? (
      <Tooltip title={itemRow.statusData.msg}>
        <div>{item} (?)</div>
      </Tooltip>
    ) : (
      <div>{item}</div>
    );
  };

  render() {
    const { data } = this.props;
    const { isLoading } = this.state;
    const { limit, offset } = this.state.filter.control;

    const fields = [
      {
        name: 'Id',
        field: 'id',
      },
      {
        name: 'Api Key',
        field: 'apiKey',
      },
      {
        name: 'Телефон',
        field: 'phone',
      },
      {
        name: 'Статус',
        field: 'accountStatus',
        render: this.renderStatus,
      },
      { name: 'Обновлено', field: 'updatedAt' },
      { name: 'Обновление', render: this.renderInstanceUpdate, style: { padding: 0 } },
      { name: 'Действия', render: this.renderInstanceAction },
    ];

    return (
      <Paper
        style={{
          height: '100%',
          flex: '1 1 auto',
          flexDirection: 'column',
        }}>
        <DataHeader title={`Инстансы (${data ? data.count : '--'})`}>
          <div
            style={{
              border: '1px solid var(--color-background-f)',
              background: 'var(--color-background)',
              borderRadius: '4px',
            }}>
            <InputBase
              style={styles.inputBase}
              onKeyDown={this.handleKeyDownSubmitForm}
              onChange={this.handleChangePhone}
              placeholder="Поиск по телефону"
              inputProps={{ 'aria-label': 'Поиск по телефону' }}
            />
            <IconButton onClick={this.handleSubmitForm} style={{ padding: 4 }} aria-label="Поиск">
              <SearchIcon />
            </IconButton>
          </div>
        </DataHeader>

        <div style={{ height: 'calc(100% - 90px)', overflow: 'auto' }}>
          {isLoading ? <Preloader absolute /> : <DataTable fields={fields} data={data.items} />}
        </div>

        {data && (
          <div style={styles.container}>
            <Pagination
              count={Math.ceil(data.count / limit)}
              page={Math.ceil(offset / limit + 1)}
              onChange={(e, page) => this.handleChangePage(page - 1)}
            />
          </div>
        )}
      </Paper>
    );
  }
}
Instances.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  setHeaderElements: PropTypes.func.isRequired,
  getItems: PropTypes.func.isRequired,
  checkStatus: PropTypes.func.isRequired,
  takeOver: PropTypes.func.isRequired,
  queryParams: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  data: state.chatWhatsappInstances.data,
});

export default connect(
  mapStateToProps,
  null,
)(Instances);
