import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from '../../../AsyncComponent';
import registerWidgets from '../../../widgets';
import './widget-panel.scss';

const WidgetPanel = ({ widgets, children, isMobile }) => (
  <div className={isMobile ? 'widget__mobile-panel' : 'widget__panel'}>
    {widgets.map(widget => {
      if (registerWidgets[widget]) {
        return (
          <AsyncComponent
            key={widget}
            moduleProvider={registerWidgets[widget].module}
            widget={widget}
          />
        );
      }
      console.warn(`ERP-warning: the module "${widget}" does not exist`);
      return null;
    })}
    {children}
  </div>
);

WidgetPanel.propTypes = {
  widgets: PropTypes.instanceOf(Array).isRequired,
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
};

WidgetPanel.defaultProps = {
  isMobile: false,
};

export default WidgetPanel;
