import React from 'react';

/**
 * Component is described here.
 *
 */
const SvgContainer = ({ hide } = true) => (
  <div hidden={hide} style={{ display: 'none' }}>
    <svg xmlns="http://www.w3.org/2000/svg">
      <symbol id="icon_help">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" />
      </symbol>

      <symbol id="icon_search">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M15.5,14h-0.8l-0.3-0.3c1-1.1,1.6-2.6,1.6-4.2C16,5.9,13.1,3,9.5,3S3,5.9,3,9.5S5.9,16,9.5,16 c1.6,0,3.1-0.6,4.2-1.6l0.3,0.3v0.8l5,5l1.5-1.5L15.5,14z M9.5,14C7,14,5,12,5,9.5S7,5,9.5,5S14,7,14,9.5S12,14,9.5,14z" />
      </symbol>

      <symbol id="icon_notifications">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M12,22c1.1,0,2-0.9,2-2h-4C10,21.1,10.9,22,12,22z M18,16v-5c0-3.1-1.6-5.6-4.5-6.3V4c0-0.8-0.7-1.5-1.5-1.5 S10.5,3.2,10.5,4v0.7C7.6,5.4,6,7.9,6,11v5l-2,2v1h16v-1L18,16z" />
      </symbol>

      <symbol id="icon_gepur">
        {' '}
        {/* viewBox="0 0 23 28" */}
        <path d="M21,23.3328876c-0.2495613,0.3475933-0.5941925,0.7328434-1.0338936,1.1557484 s-1.0180492,0.8197422-1.7370205,1.1905079c-0.7189732,0.3707676-1.6023369,0.6836014-2.6481152,0.9385033 S13.2814484,27,11.8197374,27c-1.5805502,0-3.0323591-0.2722816-4.3574066-0.8168449 c-1.3255429-0.5445633-2.4663911-1.329546-3.4230399-2.3549461c-0.9566488-1.0254021-1.7023594-2.2825317-2.2371321-3.7713909 S1,16.8792343,1,14.9906416v-1.9812832c0-1.8885918,0.2287638-3.5744209,0.6862915-5.0574865 s1.1289644-2.7401962,2.0143101-3.7713904s1.9756875-1.8190732,3.2705307-2.3636365C8.26647,1.2722816,9.7519493,1,11.4275703,1	c1.4854794,0,2.7867594,0.1940731,3.9038401,0.5822192s2.0677881,0.9269162,2.8521204,1.6163102 c0.7843342,0.6893938,1.4082355,1.5091355,1.8717041,2.4592245C20.5187054,6.6078429,20.8336258,7.6448307,21,8.7687168h-3.4225445 c-0.130722-0.6836014-0.3347282-1.3295455-0.6139984-1.9378343s-0.6635132-1.1412659-1.1507511-1.5989308 c-0.487237-0.4576645-1.0873709-0.8197412-1.8004017-1.0862298c-0.7130299-0.2664883-1.5686655-0.3997326-2.5669079-0.3997326 c-1.2121515,0-2.2579288,0.2143493-3.1373329,0.6430483C7.4286599,4.8177361,6.7037458,5.431818,6.1338167,6.2312832 C5.5633926,7.0307488,5.1385455,7.9982176,4.8592758,9.1336899c-0.2792702,1.1354723-0.4189053,2.4157753-0.4189053,3.840909 v2.0160427c0,1.4598932,0.1782575,2.7633696,0.5347724,3.910428s0.8586073,2.1203213,1.5067716,2.9197865 c0.6476688,0.7994652,1.4260602,1.407753,2.3351736,1.8248653s1.9152784,0.6256695,3.0204744,0.6256695 c0.926939,0,1.7152338-0.0608292,2.3629026-0.1824875s1.1943254-0.2722816,1.6399698-0.4518719 s0.8041401-0.3765583,1.077467-0.5909081c0.2733288-0.2143497,0.4991207-0.414217,0.6773796-0.5995998v-5.6831551h-6.0072794 v-2.7112293H21V23.3328876z" />
      </symbol>

      <symbol id="icon_email">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M20,5H4C2.9000001,5,2.01,5.9000001,2.01,7L2,19c0,1.1000004,0.9000001,2,2,2h16c1.1000004,0,2-0.8999996,2-2V7 C22,5.9000001,21.1000004,5,20,5z M20,9l-8,5L4,9V7l8,5l8-5V9z" />
      </symbol>

      <symbol id="icon_social">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M16.5,13c1.3800049,0,2.4900055-1.0849991,2.4900055-2.421875S17.8800049,8,16.5,8S14,9.2412491,14,10.578125 S15.1199951,13,16.5,13z M9,12.03125c1.6600037,0,2.9900055-1.2981253,2.9900055-2.90625S10.6600037,6,9,6S6,7.5168753,6,9.125 S7.3399963,12.03125,9,12.03125z M16.5,14.9375c-1.8300018,0-5.5,0.8912497-5.5,2.6640625V20h11v-2.3984375	C22,15.8287497,18.3300018,14.9375,16.5,14.9375z M9,13.96875c-2.3300018,0-7,1.1334372-7,3.390625V20h7v-2.3984375	C9,16.7781239,9.9600067,15.0946884,12,14C11.1300049,13.8256264,9.6600037,13.96875,9,13.96875z" />
      </symbol>

      <symbol id="icon_task">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M16.5299988,12.0600014L15.4700012,11l-4.8800011,4.8800011l-2.1199989-2.1200008l-1.0600014,1.0599995 L10.5900002,18C10.5900002,18,16.5299988,12.0599995,16.5299988,12.0600014z M19,4h-1V2h-2v2H8V2H6v2H5	C3.8899994,4,3.0099983,4.8999996,3.0099983,6L3,20c0,1.1000004,0.8899994,2,2,2h14c1.0999985,0,2-0.8999996,2-2V6 C21,4.8999996,20.0999985,4,19,4z M19,20H5V9h14V20z" />
      </symbol>

      <symbol id="icon_sip">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M17,3h-1v5h1V3z M15,5h-2V4h2V3h-3v3h2v1h-2v1h3V5z M18,3v5h1V6h2V3H18z M20,5h-1V4h1V5z M20,15.5 c-1.25,0-2.4500008-0.1999998-3.5699997-0.5699997c-0.3500004-0.1099997-0.7399998-0.0299997-1.0100002,0.2399998 l-2.1999998,2.2000008c-2.8299999-1.4400005-5.1500006-3.75-6.5900002-6.5900002l2.1999998-2.21 c0.2700005-0.2600002,0.3500004-0.6500001,0.2399998-1C8.6999998,6.4499998,8.5,5.25,8.5,4c0-0.55-0.4499998-1-1-1H4 C3.45,3,3,3.45,3,4c0,9.3900003,7.6100006,17,17,17c0.5499992,0,1-0.4500008,1-1v-3.5C21,15.9499998,20.5499992,15.5,20,15.5z" />
      </symbol>

      <symbol id="icon_analitics">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M8.4 0 13.4 0 13.4 24 8.4 24z" />
        <path d="M16.8 7.1 22 7.1 22 24 16.8 24 z" />
        <path d="M0 11.9 4.8 11.9 4.8 24 0 24 z" />
      </symbol>

      <symbol id="icon_del">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M6,19c0,1.1000004,0.9000001,2,2,2h8c1.1000004,0,2-0.8999996,2-2V7H6V19z M19,4h-3.5l-1-1h-5l-1,1H5v2h14V4z" />
      </symbol>

      <symbol id="icon_edit">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M3,17.25V21h3.75L17.8099995,9.9399996l-3.75-3.75L3,17.25z M20.7099991,7.04 c0.3899994-0.3899999,0.3899994-1.02,0-1.4099998l-2.3400002-2.3399999c-0.3899994-0.3900001-1.0200005-0.3900001-1.4099998,0 l-1.8299999,1.8300002l3.75,3.7500005C18.8799992,8.8700008,20.7099991,7.0400009,20.7099991,7.04z" />
      </symbol>

      <symbol id="icon_archive">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M20.5400009,5.23l-1.3899994-1.6799998C18.8799992,3.21,18.4699993,3,18,3H6	C5.5300002,3,5.1199999,3.21,4.8400002,3.55L3.46,5.23C3.1700001,5.5700002,3,6.02,3,6.5V19c0,1.1000004,0.9000001,2,2,2h14	c1.1000004,0,2-0.8999996,2-2V6.5C21,6.02,20.8299999,5.5700002,20.5400009,5.23z M12,17.5L6.5,12H10v-2h4v2h3.5L12,17.5z M5.1199999,5l0.8099999-1h12l0.9400005,1H5.1199999z" />
      </symbol>

      <symbol id="icon_checkbox">
        {' '}
        {/* viewBox="0 0 459 459" */}
        <path d="M408,0H51C22.95,0,0,22.95,0,51v357c0,28.05,22.95,51,51,51h357c28.05,0,51-22.95,51-51V51C459,22.95,436.05,0,408,0z M178.5,357L51,229.5l35.7-35.7l91.8,91.8L372.3,91.8l35.7,35.7L178.5,357z" />
      </symbol>

      <symbol id="icon_attachment">
        {' '}
        {/* viewBox="0 0 18 18" */}
        <path d="M12,4.5v8.625c0,1.6574993-1.3424997,3-3,3s-3-1.3425007-3-3V3.75c0-1.0350001,0.8400002-1.875,1.875-1.875 S9.75,2.7149999,9.75,3.75v7.875c0,0.4124994-0.3374996,0.75-0.75,0.75s-0.75-0.3375006-0.75-0.75V4.5H7.125v7.125 C7.125,12.6599998,7.9650002,13.5,9,13.5s1.875-0.8400002,1.875-1.875V3.75c0-1.6575-1.3424997-3-3-3s-3,1.3425-3,3v9.375 c0,2.2800007,1.8450003,4.125,4.125,4.125s4.125-1.8449993,4.125-4.125V4.5H12z" />
      </symbol>

      <symbol id="icon_comment">
        {' '}
        {/* viewBox="0 0 18 18" */}
        <path d="M16.4925003,3c0-0.8249998-0.6674995-1.5-1.4925003-1.5H3C2.1750002,1.5,1.5,2.1750002,1.5,3v9 c0,0.8250008,0.6750002,1.5,1.5,1.5h10.5l3,3L16.4925003,3z" />
      </symbol>

      <symbol id="icon_close">
        {' '}
        {/* viewBox="0 0 15 15" */}
        <polygon points="10.3000002,4 7.5,6.8000002 4.6999998,4 4,4.6999998 6.8000002,7.5 4,10.3000002 4.6999998,11 7.5,8.1999998 10.3000002,11 11,10.3000002 8.1999998,7.5 11,4.6999998 " />
      </symbol>

      <symbol id="icon_arrow_left">
        {' '}
        {/* viewBox="0 0 18 18" */}
        <path d="M15.4099998,16.0900002L10.8299999,11.5l4.5799999-4.5900002L14,5.5l-6,6l6,6L15.4099998,16.0900002z" />
      </symbol>

      <symbol id="icon_arrow_right">
        {' '}
        {/* viewBox="0 0 18 18" */}
        <path d="M8.5900002,16.3400002L13.1700001,11.75L8.5900002,7.1599998L10,5.75l6,6l-6,6L8.5900002,16.3400002z" />
      </symbol>

      <symbol id="icon_radio_btn">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M12,7c-2.7600002,0-5,2.2399998-5,5s2.2399998,5,5,5s5-2.2399998,5-5S14.7600002,7,12,7z M12,2" />
      </symbol>

      <symbol id="icon_excel">
        {' '}
        {/* viewBox="0 0 140.4937286 140" */}
        <path d="M25.162,3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162 C25.623,23,26,22.609,26,22.13V3.87C26,3.391,25.623,3,25.162,3z M24,20h-4v-2h4V20z M24,16h-4v-2h4V16z M24,12h-4v-2h4V12z M24,8 h-4V6h4V8z" />
        <path d="M0,2.889v20.223L15,26V0L0,2.889z M9.488,18.08l-1.745-3.299c-0.066-0.123-0.134-0.349-0.205-0.678 H7.511C7.478,14.258,7.4,14.494,7.277,14.81l-1.751,3.27H2.807l3.228-5.064L3.082,7.951h2.776l1.448,3.037 c0.113,0.24,0.214,0.525,0.304,0.854h0.028c0.057-0.198,0.163-0.492,0.318-0.883l1.61-3.009h2.542l-3.037,5.022l3.122,5.107 L9.488,18.08L9.488,18.08z" />
      </symbol>

      <symbol id="icon_smile">
        {' '}
        {/* viewBox="0 0 26 26" */}
        <path
          d="M70.4937363,0C31.8338223,0,0.4938024,31.3400879,0.4938024,70s31.3400192,70,69.9999313,70
								c38.6599731,0,69.9999924-31.3400879,69.9999924-70S109.1537094,0,70.4937363,0z M70.4937363,130.333313
								c-33.3211708,0-60.3333168-27.012146-60.3333168-60.333313S37.1725655,9.666687,70.4937363,9.666687
								c33.321228,0,60.3333664,27.012146,60.3333664,60.333313S103.8149643,130.333313,70.4937363,130.333313z"
        />
        <ellipse cx="48.7708321" cy="53.291687" rx="8.6875" ry="11.625" />
        <ellipse cx="91.6458359" cy="53.291687" rx="8.6875" ry="11.625" />
        <path
          d="M100.185997,93.0252838c-0.3680725-0.4197998-1.0117493-0.4353638-1.4078064-0.0418549
								c-7.7256622,7.6758728-16.2885284,12.7665863-27.5844421,12.7665863c-12.2239723,0-21.6485786-6.0766144-28.9902382-14.6236801
								c-0.2814789-0.3277054-0.7845154-0.333168-1.0673676-0.0066299l-0.4149933,0.4791183
								c-0.3546753,0.4094772-0.3834686,1.0165253-0.0567474,1.4486389C47.6471596,102.2825165,58.7235756,108.25,71.1937485,108.25
								c11.6044922,0,22.0020142-5.1677017,29.0168152-13.3273621C100.6783524,94.3785019,100.6590805,93.564827,100.185997,93.0252838
								L100.185997,93.0252838z"
        />
      </symbol>

      <symbol id="icon_sent">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M2.01,21L23,12L2.01,3L2,10l15,2L2,14L2.01,21z" />
      </symbol>

      <symbol id="icon_pinnedman">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <g>
          <circle cx="8" cy="8" r="4" />
          <path
            d="M8.5,16c0-0.6903687,0.1327515-1.3483276,0.3649902-1.9571533C8.5577393,14.0168457,8.2632446,14,8,14
								c-2.6699829,0-8,1.3400269-8,4v2h10.2329102C9.1682739,18.9970093,8.5,17.578064,8.5,16z"
          />
        </g>
        <path
          d="M23.2752953,12.1720219l-6.0987968,6.0987968c-1.1720295,1.1720276-3.070611,1.1720295-4.2426405,0
							s-1.1720285-3.070612,0-4.2426414l6.6291265-6.6291261c0.7318554-0.7318554,1.9197941-0.7318554,2.6516495,0
							s0.7318554,1.9197946,0,2.6516509l-5.5684662,5.5684652c-0.2916794,0.2916813-0.7689772,0.2916822-1.0606594,0
							c-0.2916813-0.2916813-0.2916813-0.7689791,0-1.0606594l5.0381355-5.0381365l-0.7954941-0.795495l-5.0381365,5.0381355
							c-0.7318554,0.7318554-0.7318554,1.919795,0,2.6516514c0.7318554,0.7318554,1.919795,0.7318554,2.6516504,0l5.5684662-5.5684662
							c1.1720295-1.1720295,1.1720295-3.0706115,0-4.242641s-3.070612-1.1720295-4.2426414,0l-6.6291256,6.6291261
							c-1.6122036,1.6122036-1.6122036,4.221427,0,5.8336306s4.2214279,1.6122036,5.8336315,0l6.0987949-6.0987959L23.2752953,12.1720219z
							"
        />
      </symbol>

      <symbol id="icon_arrow_down">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M0,2 24,2 12,24Z" />
      </symbol>

      <symbol id="icon_arrow_up">
        {' '}
        {/* viewBox="0 0 24 24" */}
        <path d="M12,0 24,22 0,22Z" />
      </symbol>
    </svg>
  </div>
);

export default SvgContainer;
