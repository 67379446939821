import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class HotKeysInfo extends Component {
  #renderNameRows = hotKeys => {
    const keyModule = Object.keys(hotKeys);
    const keysRows = [];

    keyModule.forEach(item => {
      keysRows.push(
        <TableRow key={item} style={{ height: '36px' }}>
          <TableCell
            align="center"
            colSpan="2"
            style={{
              height: '36px',
              backgroundColor: 'var(--color-1-light)',
              color: 'var(--color-text)',
              padding: 6,
            }}>
            {hotKeys[item].moduleName || '--'}
          </TableCell>
        </TableRow>,
      );

      for (const key in hotKeys[item].keyMap) {
        if ({}.hasOwnProperty.call(hotKeys[item].keyMap, key)) {
          keysRows.push(
            <TableRow key={key + hotKeys[item].keyMap[key]}>
              <TableCell>{hotKeys[item].description[key]} </TableCell>
              <TableCell align="center">{hotKeys[item].keyMap[key]}</TableCell>
            </TableRow>,
          );
        }
      }
    });

    return keysRows;
  };

  render() {
    const { hotKeys } = this.props;

    return (
      <div style={{ height: '100%', padding: '0 15px' }}>
        <h3
          style={{
            fontSize: '18px',
            padding: '20px 0',
          }}>
          Список доступных горячих клавиш
        </h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell tooltip="Описание">Описание</TableCell>
              <TableCell tooltip="Клавиши" align="center">
                Клавиши
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.#renderNameRows(hotKeys)}</TableBody>
        </Table>
      </div>
    );
  }
}

HotKeysInfo.propTypes = {};

const mapStateToProps = ({ hotKeys }) => ({ hotKeys });

export default connect(mapStateToProps)(HotKeysInfo);
