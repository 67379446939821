import { toastr } from 'lib/react-redux-toastr';
import { getFetchWrapper, postFetchWrapper, errorActionHandler } from 'utils/apiHelper';

/**
 *
 * @param {string} url url
 * @param {string} query text query
 */
export const makeSomeSearch = (url, query) => dispatch =>
  getFetchWrapper(`/crm/search/${encodeURIComponent(url)}?query=${query.trim()}`)
    .then(result => result || [])
    .catch(errorActionHandler(dispatch, `/crm/search/${url}?query=${query}`));

/**
 *
 * @param {string} phone phone
 */
export const searchCallTasksByPhone = phone => dispatch =>
  getFetchWrapper(`/call-center/search/by_phone?phone=${phone}`)
    .then(res => res || [])
    .catch(errorActionHandler(dispatch, `/call-center/search/by_phone?phone=${phone}`));

/**
 *
 * @param {string} phone phone
 */
export const searchOrdersAll = text => dispatch =>
  getFetchWrapper(`/order/orders/simple_search/${text}`)
    .then(res => {
      if (!res) {
        return postFetchWrapper('/onec/sync-order-by-number', { orderNumber: text });
      }
      return res;
    })
    .then(res => res || [])
    .catch(errorActionHandler(dispatch, `/order/orders/simple_search/${text}`));

/**
 *
 * @param {string} orderByNumber order number
 */
export const searchByOrderNumber = orderByNumber => dispatch =>
  getFetchWrapper(`/call-center/search/by_order_number?orderNumber=${orderByNumber}`)
    .then(result => result || [])
    .catch(
      errorActionHandler(
        dispatch,
        `/call-center/search/by_order_number?orderNumber=${orderByNumber}`,
      ),
    );

/**
 *
 * @param {string} orderNumber order number
 */
// export const searchEmailsByOrderNumber = orderNumber => dispatch =>
//   getFetchWrapper(`/crm/search/by_order_number?query=${orderNumber}`)
//     .then(result => result || [])
//     .catch(errorActionHandler(dispatch, `/crm/search/by_order_number?query=${orderNumber}`));

/**
 *
 * @param id
 * @param email
 */
// export const getRelationBody = (id, email) => dispatch => (
//   getFetchWrapper(`/email/relations/get/${id}`)
//     .then((res) => {
//       dispatch({ type: 'SAVE_MAIL', payload: { mail: res } });
//       getMailsByEmail(email)(dispatch)
//         .then(data => history.push('/emails'));
//     })
//     .catch((err) => {
//       if (err === 403) {
//         dispatch(addNotification('Ошибка! У вас не прав доступа', 'error'));
//       } else {
//         dispatch(addNotification(errText('/email/relations/get', err), 'error'));
//       }
//       throw err;
//     })
// );

/**
 * Function showEmailContentBody
 * @Method ({"GET"})
 * @param emailId
 * @param html
 * @Return response
 */
export const showEmailContentBody = (emailId, html = true) => dispatch =>
  getFetchWrapper(`/emails/${emailId}/${html ? 'body' : 'plain_body'}`)
    .then(res => res)
    .catch(errorActionHandler(dispatch, '/emails/emailId'));

/**
 *
 * @param {string} emailId email id
 * @param {string} emailName email name
 */
export const chooseEmailFromSearchResult = (emailId, emailName) => dispatch =>
  postFetchWrapper(`/email/relations/add/${emailId}`)
    .then(res => {
      toastr.success('Успех!', 'Письмо успешно взято');
      // dispatch(getRelationBody(res.id, res.emailAddress))
      return res;
    })
    .catch(errorActionHandler(dispatch, `/email/relations/add/${emailId}`));

/**
 *
 * @param {number} article article product
 */
export const getProductByArticle = article => dispatch =>
  getFetchWrapper(`/app/product-api/article/${article}`)
    .then(data => data)
    .catch(errorActionHandler(dispatch, `/app/product-api/article/${article}`));
