import React, { Component } from 'react';
import bg from '../../assets/images/winter.png';

const styles = {
  main: {
    width: '100%',
    height: '100%',
    background: `#2e303c url(${bg}) center no-repeat`,
  },
};

class WinterBackground extends Component {
  componentDidMount() {
    setTimeout(() => this.setState({ move: true }), 500);

    const canvas = this.ref;
    const $ = canvas.getContext('2d');
    let w = window.innerWidth;
    let h = window.innerHeight;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const makeItSnow = () => {
      const arr = [];
      const num = 50;
      const tsc = 1;
      const sp = 1;

      const sc = 1.3;
      const mv = 20;
      const min = 1;
      for (let i = 0; i < num; ++i) {
        const snow = new Flake();
        snow.y = Math.random() * (h + 50);
        snow.x = Math.random() * w;
        snow.t = Math.random() * (Math.PI * 2);
        snow.sz = 100 / (10 + Math.random() * 100) * sc;
        snow.sp = Math.pow(snow.sz * 0.8, 2) * 0.15 * sp;
        snow.sp = snow.sp < min ? min : snow.sp;
        arr.push(snow);
      }

      const go = () => {
        this.animation = window.requestAnimationFrame(go);
        $.clearRect(0, 0, w, h);
        $.fillRect(0, 0, w, h);
        $.fill();
        for (let i = 0; i < arr.length; ++i) {
          const f = arr[i];
          f.t += 0.05;
          f.t = f.t >= Math.PI * 2 ? 0 : f.t;
          f.y += f.sp;
          f.x += Math.sin(f.t * tsc) * (f.sz * 0.3);
          if (f.y > h + 50) f.y = -10 - Math.random() * mv;
          if (f.x > w + mv) f.x = -mv;
          if (f.x < -mv) f.x = w + mv;
          f.draw();
        }
      };

      go();

      function Flake() {
        this.draw = () => {
          this.gradient = $.createRadialGradient(this.x, this.y, 0, this.x, this.y, this.sz);
          this.gradient.addColorStop(0, 'hsla(255,255%,255%,1)');
          this.gradient.addColorStop(1, 'hsla(255,255%,255%,0)');
          $.moveTo(this.x, this.y);
          $.fillStyle = this.gradient;
          $.beginPath();
          $.arc(this.x, this.y, this.sz, 0, Math.PI * 2, true);
          $.fill();
        };
      }
    };

    window.addEventListener(
      'resize',
      () => {
        w = window.innerWidth;
        h = window.innerHeight;
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      },
      false,
    );

    makeItSnow();
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animation);
  }

  render() {
    return (
      <div style={{ ...styles.main, backgroundColor: '#2e303c' }}>
        <canvas
          id="canvas"
          style={{ width: '100%', height: '100%', position: 'absolute' }}
          ref={ref => (this.ref = ref)}
        />
        {this.props.children}
      </div>
    );
  }
}

export default WinterBackground;
