import React, { Component } from 'react';
import { propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
// import logo from 'assets/images/gepur_logo.svg';
import logo from 'assets/images/gepur.gif';
import { logIn, clearUser } from 'modules/Login';
import DefaultBackground from './defaultBackground';
import { TextInput } from '../../lib/redux-form-inputs';
import './Login.scss';

class Login extends Component {
  constructor(props) {
    super(props);

    this.login = this.login.bind(this);

    this.state = {
      show: false,
    };

    this.props.clearUser();
  }

  componentDidMount() {
    setTimeout(() => this.setState({ show: true }), 400);
  }

  login({ username, password }) {
    const { userLogin, state } = this.props;
    userLogin({ _username: username, _password: password }, state ? state.location.pathname : '/');
  }

  renderForm = () => {
    const { handleSubmit, submitting } = this.props;
    return (
      <Paper elevation={3} className={`login__card ${this.state.show && 'login__card--show'}`}>
        <div>
          <img src={logo} alt="logo" className="login__logo" />
        </div>
        <form onSubmit={handleSubmit(this.login)}>
          <div className="login__form">
            <div className="login__input">
              <TextInput fullWidth name="username" label="логин" />
            </div>
            <div className="login__input">
              <TextInput fullWidth type="password" name="password" label="пароль" />
            </div>
          </div>
          <div style={{ padding: 8 }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={submitting}
              fullWidth>
              отправить
            </Button>
          </div>
          <p className="login__version">
            {`Version ${process.env.REACT_APP_VERSION}`}
            <span>{`Build: ${process.env.REACT_APP_BUILD}`}</span>
          </p>
        </form>
      </Paper>
    );
  };

  render() {
    return <DefaultBackground>{this.renderForm()}</DefaultBackground>;
  }
}

Login.propTypes = {
  ...propTypes,
};

Login.defaultProps = {
  theme: {},
};

const mapDispatch = {
  userLogin: logIn,
  clearUser,
};

const enhance = compose(
  reduxForm({
    form: 'signIn',
    validate: values => {
      const errors = {};
      if (!values.username) errors.username = 'это поле обязательно!';
      if (!values.password) errors.password = 'это поле обязательно!';
      return errors;
    },
  }),
  connect(
    null,
    mapDispatch,
  ),
);

export default enhance(Login);
