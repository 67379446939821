import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import SortIcon from '@material-ui/icons/Sort';
import ReactList from 'react-list';
import { toastr } from 'lib/react-redux-toastr';
import Preloader from '../../simple/preloader';
import FilterButton from '../filter-button';
import FilterInput from '../filter-input';
import './data-table.scss';

const style = {
  buttonFilter: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
    textTransform: 'lowercase',
    color: 'var(--color-text)',
    cursor: 'pointer',
  },
};

class DataTable extends Component {
  constructor(props) {
    super(props);

    this.staticTable = [];
    this.tableHeader = null;

    if (!Array.isArray(this.props.data) || !this.props.data) {
      console.error('ERP error! Invalid prop data in DataTable component!', this.props.data);
    }

    this.state = {
      sortingField: null,
      filter: [],
      result: this.props.data || [],
      sortData: true,
      forceUpdate: props.forceUpdate || [],
      filteringFields: {},
      selectedRows: {},
      selectAllRows: false,
    };
  }

  componentDidMount() {
    this.renderHeader();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.data) return;

    if (!Array.isArray(nextProps.data)) {
      console.error('ERP error! Invalid prop data in DataTable component!', this.props.data);
    }

    if (nextProps.data.length === 0) {
      this.setState({ result: [] });
      return;
    }

    if (nextProps.data !== this.props.data) {
      // const { sortData, sortingField } = this.state;
      this.setState({ forceUpdate: [], selectedRows: {}, selectAllRows: false });

      this.filterContent(null, null, nextProps.data);

      // this.sortContent(sortData, sortingField, nextProps.data);
    }

    if (nextProps.forceUpdate !== this.props.forceUpdate) {
      this.setState({ forceUpdate: nextProps.forceUpdate });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  sortContent = (state, sortingField, data) => {
    const { resultDataCb } = this.props;
    let result = data;
    if (sortingField !== null) {
      result = data.sort((a, b) => {
        if (a[sortingField] > b[sortingField]) {
          return state ? 1 : -1;
        }
        if (a[sortingField] < b[sortingField]) {
          return state ? -1 : 1;
        }
        return 0;
      });
    } else {
      result = data;
    }

    this.setState({ sortData: state, result, sortingField }, this.renderHeader);
    resultDataCb && resultDataCb(result);
  };

  filterContent = (filteringField, filterValues, data) => {
    let filterObj;

    if (!filteringField && !filterValues) {
      filterObj = {
        ...this.state.filteringFields,
      };
    } else {
      filterObj = {
        ...this.state.filteringFields,
        [filteringField]: filterValues,
      };
    }

    const filterKeys = Object.keys(filterObj);

    const filter = data.filter(item => {
      const include = filterKeys.reduce((acc, fieldName) => {
        const matchVal = filterObj[fieldName].reduce(
          (accum, currentFilter) =>
            new RegExp(currentFilter, 'i').test(item[fieldName]) ? accum + 1 : accum,
          0,
        );

        return matchVal !== 0 ? acc + 1 : acc;
      }, 0);

      return include === filterKeys.length;
    });

    this.setState({ filteringFields: filterObj });

    if (!filter.length) {
      toastr.info('Список пуст', 'Нет полей удовлетворяющих поиску');
    } else {
      this.sortContent(this.state.sortData, this.state.sortingField, filter);
    }
  };

  handleFilter = (filteringField, filterValues) => {
    this.filterContent(filteringField, filterValues, this.props.data);
    this.setState({ forceUpdate: [], selectedRows: {}, selectAllRows: false });

    if ((this.props.serverSorting || this.props.serverFilter) && this.props.onRowSelection) {
      this.props.onRowSelection([]);
    }
  };

  handleSort = sortingItem => {
    if (this.props.serverSorting && typeof sortingItem.sort === 'function') {
      sortingItem.sort(!this.state.sortData);

      this.setState(
        old => ({
          ...old,
          sortData: !old.sortData,
          sortingField: sortingItem.field,
          forceUpdate: [],
          selectedRows: {},
          selectAllRows: false,
        }),
        this.renderHeader,
      );
    } else {
      this.sortContent(!this.state.sortData, sortingItem.field, this.state.result);
      this.setState({ forceUpdate: [], selectedRows: {}, selectAllRows: false });
    }

    if ((this.props.serverSorting || this.props.serverFilter) && this.props.onRowSelection) {
      this.props.onRowSelection([]);
    }
  };

  staticRenderBody = () => {
    const { forceUpdate, result } = this.state;
    if (forceUpdate.length && this.staticTable) {
      forceUpdate.forEach(index => {
        this.staticTable[index] = this.renderTableBody(result[index], index);
      });
    } else {
      const arr = [];
      result.forEach((item, ind) => {
        arr.push(this.renderTableBody(item, ind));
      });

      this.staticTable = arr;
    }
  };

  renderInfinityBody = number => this.staticTable[number];

  handleSelectAll = e => {
    const bool = e.target.checked;

    this.setState(old => ({
      selectAllRows: bool,
      selectedRows: bool ? old.result.reduce((acc, i, ind) => ({ ...acc, [ind]: true }), {}) : {},
    }));

    this.props.onRowSelection(bool ? 'all' : 'none');
  };

  handleSelection = key => {
    if (this.state.selectedRows[key]) {
      const data = { ...this.state.selectedRows };
      delete data[key];
      this.setState(old => ({
        ...old,
        selectedRows: data,
        selectAllRows: false,
      }));

      this.props.onRowSelection(Object.keys(data));
    } else {
      this.setState(old => ({
        ...old,
        selectedRows: { ...old.selectedRows, [key]: true },
        selectAllRows: false,
      }));

      if (this.props.onRowSelection) {
        this.props.onRowSelection(Object.keys({ ...this.state.selectedRows, [key]: true }));
      }
    }
  };

  renderTableBody = (itemRow, externalKey) => {
    const key = itemRow[this.props.keyField] || externalKey;

    if (this.props.renderRow) {
      return this.props.renderRow(this.props.fields, itemRow, key);
    }

    const isRowSelection = !!this.props.onRowSelection;

    return (
      <TableRow
        key={key}
        hover
        onClick={isRowSelection ? () => this.handleSelection(key) : undefined}
        style={this.props.rowColor ? { backgroundColor: this.props.rowColor(itemRow, key) } : null}>
        {isRowSelection && (
          <TableCell padding="checkbox">
            <Checkbox checked={!!this.state.selectedRows[key]} />
          </TableCell>
        )}
        {this.props.fields.map((item, ind) => {
          if (this.props.fields[ind].render) {
            return (
              <TableCell
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  ...item.style,
                }}
                key={item.field + ind}>
                {this.props.fields[ind].render(itemRow, itemRow[item.field], key)}
              </TableCell>
            );
          }
          return (
            <TableCell key={item.field} style={item.style}>
              {typeof itemRow[item.field] !== 'object'
                ? itemRow[item.field] || '--'
                : itemRow[item.field].title || '--'}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  renderSortIcon = sortingFieldName => {
    const { sortingField, sortData } = this.state;
    if (sortingField === sortingFieldName) {
      return <SortIcon style={{ transform: `rotate(${sortData ? 180 : 0}deg)` }} />;
    }

    return null;
  };

  renderSortButtons = (item, ind) => (
    <TableCell
      key={ind}
      style={{
        height: 36,
        padding: '0 10px',
        ...item.style,
      }}>
      {item.noSort ? (
        <div style={style.buttonFilter}>
          <span
            style={{
              fontSize: '10px',
              marginRight: '5px',
              width: '100%',
              wordWrap: 'break-word',
              textTransform: 'uppercase',
            }}>
            {item.name}
          </span>
          {item.filter && !this.props.serverFilter && (
            <FilterButton
              handleSubmit={this.handleFilter}
              data={item.filter}
              field={item.field}
              localStorage={item.localStorage}
              checked={
                this.state.filteringFields[item.field]
                  ? this.state.filteringFields[item.field]
                  : Object.keys(item.filter)
              }
            />
          )}
        </div>
      ) : (
        <div style={style.buttonFilter}>
          <span
            onClick={() => this.handleSort(item)}
            style={{
              fontSize: '10px',
              marginRight: '5px',
              width: '100%',
              wordWrap: 'break-word',
              textTransform: 'uppercase',
            }}>
            {item.name}
          </span>
          {item.filter && !this.props.serverFilter && (
            <FilterButton
              handleSubmit={this.handleFilter}
              data={item.filter}
              field={item.field}
              localStorage={item.localStorage}
              checked={
                this.state.filteringFields[item.field]
                  ? this.state.filteringFields[item.field]
                  : Object.keys(item.filter)
              }
            />
          )}
          {this.renderSortIcon(item.field)}
        </div>
      )}
    </TableCell>
  );

  renderHeader = () => {
    const { fields, onRowSelection, serverFilter } = this.props;
    this.tableHeader = (
      <TableHead style={{ backgroundColor: 'var(--color-1-light)' }}>
        <TableRow style={{ height: 36 }}>
          {!!onRowSelection && (
            <TableCell padding="checkbox">
              <Checkbox
                name="selectedAll"
                checked={this.state.selectAllRows}
                onChange={this.handleSelectAll}
              />
            </TableCell>
          )}
          {fields.map(this.renderSortButtons)}
        </TableRow>

        {serverFilter && (
          <TableRow>
            {!!onRowSelection && (
              <TableCell padding="checkbox">
                <Checkbox disabled />
              </TableCell>
            )}
            {fields.map(item => (
              <TableCell
                key={item.field}
                style={{
                  height: 36,
                  padding: '0 10px',
                  ...item.style,
                }}>
                <FilterInput filterCb={item.filter} />
              </TableCell>
            ))}
          </TableRow>
        )}
      </TableHead>
    );
  };

  renderTable = (items, refLink = () => null) => {
    return (
      <Table
        // height={
        //   this.props.wrapper && this.props.fixedHeader
        //     ? this.props.wrapper.offsetHeight - 40 + 'px'
        //     : null
        // }
        style={this.props.style}
        stickyHeader={this.props.stickyHeader}>
        {this.tableHeader}
        <TableBody ref={ref => refLink(ref)}>{items}</TableBody>
      </Table>
    );
  };

  renderIfResult = result =>
    result.length || this.props.renderStatefullRow || this.props.serverFilter ? (
      this.renderTable(result.map(this.renderTableBody))
    ) : (
      <div
        style={{
          textAlign: 'center',
          lineHeight: '100px',
          backgroundColor: 'var(--color-background)',
        }}>
        Список пуст
      </div>
    );

  render() {
    const { result } = this.state;
    this.staticRenderBody();
    this.renderHeader();

    return (
      <Preloader isLoad={!this.props.data}>
        {result.length > 150 ? (
          <ReactList
            itemsRenderer={(items, ref) => this.renderTable(items, ref)}
            itemRenderer={this.renderInfinityBody}
            length={result.length}
            type="uniform"
          />
        ) : (
          this.renderIfResult(result)
        )}
      </Preloader>
    );
  }
}

DataTable.propTypes = {
  render: PropTypes.func,
  onRowSelection: PropTypes.func,
  fields: PropTypes.instanceOf(Array).isRequired,
  forceUpdate: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
  style: PropTypes.instanceOf(Object),
  rowColor: PropTypes.func,
  resultDataCb: PropTypes.func,
  renderStatefullRow: PropTypes.func,
  renderRow: PropTypes.func,
  stickyHeader: PropTypes.bool,
  serverFilter: PropTypes.bool,
  serverSorting: PropTypes.bool,
  keyField: PropTypes.string,
};

DataTable.defaultProps = {
  rowColor: null,
  render: null,
  onRowSelection: null,
  resultDataCb: null,
  forceUpdate: [],
  data: [],
  renderStatefullRow: null,
  renderRow: null,
  style: null,
  stickyHeader: false,
  serverFilter: false,
  serverSorting: false,
  keyField: null,
};

export default DataTable;
