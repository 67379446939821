import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InputSlider from 'react-input-slider';
import './time.scss';

const sliderStyles = {
  track: {
    width: '255px',
  },
  active: {
    backgroundColor: 'var(--color-1)',
  },
};

class Time extends Component {
  static propTypes = {
    moment: PropTypes.instanceOf(Object).isRequired,
    header: PropTypes.string,
    className: PropTypes.string,
    hourStep: PropTypes.number,
    minStep: PropTypes.number,
    style: PropTypes.instanceOf(Object),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    style: null,
    className: '',
    header: '',
    hourStep: 1,
    minStep: 1,
  };

  changeHours = pos => {
    const m = this.props.moment;
    m.hours(pos.x);
    this.props.onChange(m);
  };

  changeMinutes = pos => {
    const m = this.props.moment;
    m.minutes(pos.x);
    this.props.onChange(m);
  };

  render() {
    const m = this.props.moment;
    const { style, className, header, hourStep, minStep } = this.props;
    return (
      <div className={cx('m-time', className)} style={style}>
        <span className="time-header-text">{header}</span>
        <div className="showtime">
          <input
            type="number"
            step="1"
            min="0"
            max="23"
            className="time"
            onChange={e => this.changeHours({ x: e.target.value })}
            value={m.format('HH')}
          />
          <span className="separater">:</span>
          <input
            type="number"
            step="1"
            min="0"
            max="59"
            onChange={e => this.changeMinutes({ x: e.target.value })}
            value={m.format('mm')}
            className="time"
          />
        </div>

        <div className="sliders">
          <div className="time-text">Часы:</div>
          <InputSlider
            styles={sliderStyles}
            className="u-slider-time"
            xmin={0}
            xmax={23}
            xstep={hourStep}
            x={m.hour()}
            onChange={this.changeHours}
          />
          <div className="time-text">Минуты:</div>
          <InputSlider
            styles={sliderStyles}
            className="u-slider-time"
            xmin={0}
            xmax={59}
            xstep={minStep}
            x={m.minute()}
            onChange={this.changeMinutes}
          />
        </div>
      </div>
    );
  }
}

export default Time;
