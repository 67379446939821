import React, { Component } from 'react';
import { TweenMax, Linear, Sine } from 'gsap';
import bg from '../../assets/images/velentine.jpg';
import heart from '../../assets/images/heart.png';

const styles = {
  main: {
    width: '100%',
    height: '100%',
    background: `#2e303c url(${bg}) center no-repeat`,
  },
};

const R = (min, max) => min + Math.random() * (max - min);

class ValentineBackground extends Component {
  componentDidMount() {
    const startTar8 = new Date('2019-03-07');
    const endTar8 = new Date('2019-03-09');

    const d = new Date();

    const isMar = startTar8.getTime() < d.getTime() && endTar8.getTime() > d.getTime();

    if (!isMar) {
      const total = 40;
      const container = document.getElementById('container');
      const w = container.offsetWidth;
      const h = container.offsetHeight;

      for (let i = 0, div; i < total; i += 1) {
        div = document.createElement('div');
        div.className = 'dot';
        container.appendChild(div);
        TweenMax.set(div, {
          x: R(0, w),
          y: R(-100, 100),
          opacity: 1,
          scale: R(0, 0.5) + 0.5,
          background: `transparent url(${heart}) no-repeat center`,
        });
        this.animm(div, h);
      }
    }
  }

  componentWillUnmount() {
    TweenMax.killAll();
  }

  animm = (elm, h) => {
    TweenMax.to(elm, R(0, 5) + 3, {
      y: h,
      ease: Linear.easeNone,
      repeat: -1,
      delay: -5,
    });
    TweenMax.to(elm, R(0, 5) + 1, {
      x: '+=70',
      repeat: -1,
      yoyo: true,
      ease: Sine.easeInOut,
    });
    TweenMax.to(elm, R(0, 1) + 0.5, {
      opacity: 0,
      repeat: -1,
      yoyo: true,
      ease: Sine.easeInOut,
    });
  };

  render() {
    return (
      <div style={{ ...styles.main, backgroundColor: '#2e303c' }}>
        <div id="container" />
        {this.props.children}
      </div>
    );
  }
}

export default ValentineBackground;
