const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const DELETE_MODAL = 'DELETE_MODAL';
const RESET_ID_MODAL = 'RESET_ID_MODAL';

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL: {
      return {
        ...state,
        [payload.id]: {
          modalId: payload.id,
          open: true,
        },
      };
    }

    case CLOSE_MODAL: {
      const modals = state;
      modals[payload.id].open = false;
      return {
        ...state,
        ...modals,
      };
    }

    case DELETE_MODAL: {
      const modals = state;
      delete modals[payload.id];
      return {
        ...state,
        ...modals,
      };
    }

    case RESET_ID_MODAL: {
      return initialState;
    }

    default:
      return state;
  }
};

/**
 * @param {string} id
 */
export const openModal = id => dispatch => {
  dispatch({ type: OPEN_MODAL, payload: { id } });
};

export const closeModal = id => (dispatch, getState) => {
  const { modal } = getState('modal');

  if (!modal[id]) {
    console.info(`ERP warning: closeModal is missing id! (${id})`);
    return false;
  }

  dispatch({ type: CLOSE_MODAL, payload: { id } });
  setTimeout(() => {
    dispatch({ type: DELETE_MODAL, payload: { id } });
  }, 500);

  return false;
};
