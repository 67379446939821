import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Popover from '@material-ui/core/Popover';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import PreLoader from '../../simple/preloader';
import SearchList from '../search-list';
import * as searchActions from '../../../modules/Search';
import './search-box.scss';

const styles = {
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
};

class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      tabId: 'by_account',
      searchText: '',
      searchResult: null,
      isLoad: false,
      type: null,
    };
  }

  handleTouchTap = event => {
    if (!this.state.open) {
      this.setState({
        open: true,
        anchorEl: event.currentTarget,
      });
    }
  };

  handleClosePopover = () => {
    this.setState({
      open: false,
    });
  };

  handleTabChange = (e, tabId) => {
    this.setState({ tabId });
  };

  handleSearchText = ({ target }) => {
    this.setState({ searchText: target.value });
  };

  handleSubmitForm = e => {
    let req;
    const { tabId, searchText } = this.state;

    e.preventDefault();

    if (!searchText) {
      return false;
    }

    this.setState({
      isLoad: true,
    });

    switch (tabId) {
      case 'by_order_number': {
        req = this.props.searchByOrderNumber(searchText);
        break;
      }

      case 'by_account': {
        req = this.props.makeSomeSearch(tabId, searchText);
        break;
      }

      case 'by_account_data': {
        req = this.props.makeSomeSearch(tabId, searchText);
        break;
      }

      case 'by_callTask_phone': {
        req = this.props.searchCallTasksByPhone(searchText);
        break;
      }

      case 'by_orders': {
        req = this.props.searchOrdersAll(searchText);
        break;
      }

      case 'product_by_article': {
        req = this.props.getProductByArticle(searchText);
        break;
      }

      default:
        req = this.props.makeSomeSearch(tabId, searchText);
        break;
    }

    return req
      .then(data =>
        this.setState({
          isLoad: false,
          searchResult: data,
          type: tabId,
        }),
      )
      .catch(() => {
        this.setState({
          isLoad: false,
          type: null,
        });
      });
  };

  handleReset = () => {
    this.setState({
      searchResult: null,
      searchText: '',
    });
  };

  renderResult = (searchResult, tabId, type) => {
    if (searchResult === null) {
      return null;
    }

    if (searchResult.length === 0) {
      return (
        <>
          <Divider />
          <div style={{ textAlign: 'center', padding: '15px', lineHeight: '1.5em' }}>
            нет данных для отображения
            <br />
            ¯\_(ツ)_/¯
          </div>
        </>
      );
    }

    if (tabId === type) {
      return (
        <>
          <Divider />
          <SearchList searchResult={searchResult} type={type} />
        </>
      );
    }

    return null;
  };

  render() {
    const { open, anchorEl, type, searchResult, tabId } = this.state;
    const { classes } = this.props;

    return (
      <>
        <IconButton onClick={this.handleTouchTap} aria-label="Search">
          <SearchIcon className="search__icon" />
        </IconButton>
        <Popover
          open={open}
          onClose={this.handleClosePopover}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <Tabs
            value={this.state.tabId}
            onChange={this.handleTabChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary">
            <Tab label="По аккаунту" value="by_account" />
            <Tab label="По данным аккаунта" value="by_account_data" />
            <Tab label="задачи по № заказа" value="by_order_number" />
            <Tab label="задачи по № телефона" value="by_callTask_phone" />
            <Tab label="заказы" value="by_orders" />
            <Tab label="Товар по артиклу" value="product_by_article" />
          </Tabs>
          <Divider />
          <div>
            <PreLoader isLoad={this.state.isLoad} absolute background />
            <div style={{ padding: '5px 10px' }}>
              <form
                onSubmit={this.handleSubmitForm}
                style={{ display: 'flex', alignItems: 'center' }}>
                <InputBase
                  autoFocus
                  className={classes.input}
                  value={this.state.searchText}
                  onChange={this.handleSearchText}
                  placeholder="Введите запрос"
                />
                <IconButton type="submit" className={classes.iconButton} aria-label="Search">
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} />
                <IconButton
                  onClick={this.handleReset}
                  className={classes.iconButton}
                  aria-label="Empty">
                  <CloseIcon />
                </IconButton>
              </form>
            </div>
            <div style={{ width: '1135px' }}>{this.renderResult(searchResult, tabId, type)}</div>
          </div>
        </Popover>
      </>
    );
  }
}

SearchBox.propTypes = {
  makeSomeSearch: PropTypes.func.isRequired,
  searchByOrderNumber: PropTypes.func.isRequired,
  searchCallTasksByPhone: PropTypes.func.isRequired,
  searchOrdersAll: PropTypes.func.isRequired,
  getProductByArticle: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ system: { theme } }) => ({ theme });

const mapDispatchToProps = {
  ...searchActions,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
);

export default enhance(SearchBox);
