import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '@material-ui/core/Input';

const renderInput = ({ input, meta: { touched, invalid, error }, ...custom }) => (
  <Input
    error={touched && invalid}
    // helperText={touched && error}
    {...input}
    {...custom}
  />
);

renderInput.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default props => <Field component={renderInput} {...props} />;
