import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';

const ActionAccessibility = React.forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    style={{ color: 'inherit', ...props.style }}
    viewBox="-45 0 116.51 116.51">
    <g>
      <path
        d="M0.06,3.41C-1.55,33.66,23.29,38,23.29,38,22,4.84.06,3.41,0.06,3.41Z"
        transform="translate(0.1 -3.41)"
      />
      <path
        d="M20.83,41.81H2.49a2.45,2.45,0,0,0-1.73.83A2.44,2.44,0,0,0-.07,44.4v6.44a2.52,2.52,0,0,0,.83,1.81,2.47,2.47,0,0,0,1.73.78H11.8V99.82a8.53,8.53,0,0,1-7.45,8.49H2.49a2.47,2.47,0,0,0-1.81.78,2.43,2.43,0,0,0-.78,1.81v6.44a2.73,2.73,0,0,0,.7,1.81,2.46,2.46,0,0,0,1.86.78H3.37a20,20,0,0,0,19.9-18.73,5.92,5.92,0,0,0,.1-1.22V44.4a2.47,2.47,0,0,0-.78-1.81A2.28,2.28,0,0,0,20.83,41.81Z"
        transform="translate(0.1 -3.41)"
      />
    </g>
  </SvgIcon>
));

ActionAccessibility.displayName = 'ActionAccessibility';
ActionAccessibility.muiName = 'SvgIcon';

ActionAccessibility.propTypes = {
  style: PropTypes.instanceOf(Object),
};

ActionAccessibility.defaultProps = {
  style: {},
};

export default ActionAccessibility;
