import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReduxToastr from 'lib/react-redux-toastr';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { getNewMuiTheme, getLocalStyles } from 'config/muiTheme';
import Aside from 'components/composite/aside';
import SvgContainer from 'components/simple/svg-container/index';
import Header from 'components/composite/header';
import {
  getMenu,
  setMenuPosition,
  getManagersList,
  getStorekeepersList,
  connectSysWS,
  getChats,
  getAutoTakeSources,
  getActiveReminders,
  getPermissions,
} from 'modules/System';
import Routes from 'routes';
import { ruRu } from '@material-ui/core/locale';
import clippy from 'clippyjs';
import getWidgetsHOC from '../../AsyncWidget.HOC';
import HelpPanel from '../../components/composite/help-panel';
import WidgetPanel from '../../components/composite/widget-panel';
import AttachmentsViewer from '../../components/composite/attachments-viewer';
import Login from '../Login/Login';
import ModalUniversal from '../../components/composite/modal-universal';
import './App.scss';
import MobileMenu from './MobileMenu';
import MobileHeader from '../../components/composite/header/headerMobile';

if (!localStorage.getItem('CLIPPY_ENABLED_SOUND')) {
  // hotfix disable sound
  clippy.Animator.prototype._playSound = function() {
    return false;
  };
}

const Widgets = getWidgetsHOC(WidgetPanel);

const App = props => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(props.menuIsOpen !== 'close');
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (props.user) {
      try {
        Notification.requestPermission();
      } catch (error) {
        console.warn('Notifications are not supported!');
      }

      if (
        !localStorage.getItem('CLIPPY_DISABLED') &&
        !document.querySelectorAll('.clippy').length &&
        process.env.NODE_ENV !== 'development'
      ) {
        const randPos = () => 0.2 + Math.random() * 0.75;
        const body = document.querySelectorAll('body')[0];

        clippy.load(localStorage.getItem('CLIPPY_NAME') || 'Merlin', agent => {
          agent.show();

          setInterval(() => {
            agent.animate();
          }, 30000 + Math.random() * 10000);

          if (!localStorage.getItem('CLIPPY_DISABLE_FLY')) {
            setInterval(() => {
              agent.moveTo(body.scrollWidth * randPos(), body.scrollHeight * randPos());
            }, 30000 + Math.random() * 50000);
          }
        });
      }

      props.getChats();
      props.connectSysWS();
      props.getActiveReminders();
      props.getPermissions();

      props.getMenu().then(res => {
        let arr = res;

        const typeCRM = localStorage.getItem('CRM_INTERFACE') || 'crm';

        arr = res.map(item =>
          item.url === '/crm'
            ? {
                label: 'CRM',
                url: `/${typeCRM}`,
                children: item.children,
              }
            : item,
        );

        if (process.env.NODE_ENV === 'production') {
          // FIXME: Временные костыли см. ERP-2607
          setMenu([
            ...arr,
            {
              label: 'Магазины',
              url: '/shops-list',
              children: [],
            },
            {
              label: 'Реквизиты к оплате',
              url: '/payment-recipients',
              children: [],
            },
          ]);
        } else {
          setMenu([
            ...arr,
            {
              label: 'Магазины',
              url: '/shops-list',
              children: [],
            },
            {
              label: 'Реквизиты к оплате',
              url: '/payment-recipients',
              children: [],
            },
            {
              label: '1C',
              url: '/odinAss',
              children: [],
            },
          ]);
        }
      });
      setTimeout(() => {
        props.getManagersList();
      }, 500);

      props.getAutoTakeSources();
      props.getStorekeepersList();
    }
  }, [props.user]);

  const handleToogleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
    props.setMenuPosition(!sidebarIsOpen);
  };

  const renderColorStyles = () => {
    const { color, theme } = props;
    const rootStyle = getLocalStyles(color, theme);
    return `
      html {
        --color-1: ${rootStyle['--color-1']};
        --color-1-light: ${rootStyle['--color-1-light']};
        --color-1-contrast: ${rootStyle['--color-1']};
        --color-1-contrast-light: ${rootStyle['--color-1-light']};
        --color-text: ${rootStyle['--color-text']};
        --color-sidebar: ${rootStyle['--color-sidebar']};
        --color-sidebar-d: ${rootStyle['--color-sidebar-d']};
        --color-separator: ${rootStyle['--color-separator']};
        --color-background: ${rootStyle.backgroundColor};
        --color-background-f: ${rootStyle['--color-background-f']};
        background-color: ${rootStyle.backgroundColor};
        color: ${rootStyle.color};
      }`;
  };

  const { color, theme, user, location, isMobile } = props;
  const muiTheme = createMuiTheme(getNewMuiTheme(color, theme), ruRu);

  return (
    <ThemeProvider theme={muiTheme}>
      {/* <StylesProvider generateClassName={generateClassName}> */}
      <div style={getLocalStyles(color, theme)}>
        {user ? (
          <>
            {isMobile && menu && (
              <div className="app-container">
                <MobileHeader />
                <MobileMenu
                  data={menu}
                  widgets={
                    <Widgets isMobile={isMobile}>
                      <HelpPanel isMobile={isMobile} />
                    </Widgets>
                  }
                />
                <Routes data={menu} isMobile={isMobile} location={location} />
              </div>
            )}
            {!isMobile && (
              <div className={`app-container ${sidebarIsOpen && 'open'}`}>
                <Aside data={menu} open={!!sidebarIsOpen} handleToggle={handleToogleSidebar} />
                {menu && (
                  <>
                    <Header open={!!sidebarIsOpen} handleToggle={handleToogleSidebar} />
                    <Routes data={menu} location={location} />
                    <Widgets>
                      <HelpPanel />
                    </Widgets>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <Login location={location} state={location} />
        )}
        <SvgContainer />
        <AttachmentsViewer />
        <ModalUniversal />
        <ReduxToastr
          disabledFocus
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="bottom-right"
          transitionOut="fadeOut"
          transitionIn="fadeIn"
        />
        <style>{renderColorStyles()}</style>
      </div>
      {/* </StylesProvider> */}
    </ThemeProvider>
  );
};

App.propTypes = {
  color: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  menuIsOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  user: PropTypes.string,
  getMenu: PropTypes.func.isRequired,
  connectSysWS: PropTypes.func.isRequired,
  setMenuPosition: PropTypes.func.isRequired,
  getManagersList: PropTypes.func.isRequired,
  getActiveReminders: PropTypes.func.isRequired,
  getStorekeepersList: PropTypes.func.isRequired,
  getChats: PropTypes.func.isRequired,
  getAutoTakeSources: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

App.defaultProps = {
  user: '',
};

const mapDispatch = {
  getMenu,
  setMenuPosition,
  getManagersList,
  getStorekeepersList,
  connectSysWS,
  getChats,
  getAutoTakeSources,
  getActiveReminders,
  getPermissions,
};

const mapState = state => ({
  user: state.user.username,
  color: state.system.color,
  theme: state.system.theme,
  isMobile: state.system.isMobile,
  menuIsOpen: state.system.menuIsOpen,
});

export default connect(
  mapState,
  mapDispatch,
)(App);
