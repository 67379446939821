import store, { registerReducer } from './index';
// import store from '../store';
import { reducerWrapper } from './reduxNamespaces';

const modules = {};

export function deleteModule(name) {
  delete modules[name];
}

const module = store2 => (nameModule, moduleProvider, requirements) => {
  const name = typeof nameModule === 'string' ? nameModule : nameModule.name;
  const nameSpace = nameModule.nameSpace || '';

  if (modules.hasOwnProperty(nameSpace || name)) {
    return Promise.resolve(modules[name]);
  }

  modules[nameSpace || name] = moduleProvider;

  return moduleProvider.then(mod => {
    if (requirements) {
      requirements.forEach(item => item());
    }
    if (mod.reducer) {
      registerReducer(store, nameSpace || name, reducerWrapper(nameSpace, mod.reducer));
    }
    return mod;
  });
};

export default module;
