import { connect } from 'react-redux';

const wrapperAction = (fn, nameSpace) => {
  if (!nameSpace) {
    return fn;
  }

  if (typeof fn === 'function') {
    return (dispatch, getState, extraArgument) =>
      // если поле nameSpace есть в dispatchResult, то оно перезапишет декорируемый nameSpace
      fn(
        dispatchResult =>
          dispatch({
            nameSpace,
            ...dispatchResult,
            /* type: `${nameSpace}/${dispatchResult.type}`, */
          }),
        getState,
        extraArgument,
      );
  }

  return { ...fn, nameSpace /* type: `${nameSpace}/${fn.type}` */ };
};

const prepareActions = (data, dispatch, name) =>
  !data
    ? {}
    : Object.entries(data).reduce(
        (acc, [fnName, fn]) =>
          typeof fn === 'function'
            ? { ...acc, [fnName]: (...args) => dispatch(wrapperAction(fn(...args), name)) }
            : acc,
        {},
      );

const dispatchWrapper = (objDecorate, objStatic) => (dispatch, props) => {
  const res1 = prepareActions(objDecorate, dispatch, props.customName);
  const res2 = prepareActions(objStatic, dispatch);

  return { ...res1, ...res2 };
};

/**
 * На данный момент изолируются только редюсеры имеющие namespace остальное все отрабатывает штатно
 * системные редюсеры отрабатывают всегда, не зависимо от наличия action.nameSpace
 * @param {String} nameSpace nameSpace reducer
 * @param {Function} reducer Reducer function
 */
export const reducerWrapper = (nameSpace, reducer) => (state, action) => {
  if (
    (action.nameSpace && !(nameSpace === action.nameSpace || action.nameSpace === 'all')) ||
    (nameSpace && !action.nameSpace)
  ) {
    // если первый запуск, то дергаем редюсер для initialState
    return state || reducer(state, { type: '@@initialState_nameSpaces' });
  }

  return reducer(state, action);
};

export const nameSpaceState = fn => (state, props) =>
  fn(state, props, props.customName ? state[props.customName] : undefined);

export const nameSpaceDispatch = (isolateActions, staticActions) => {
  if (typeof isolateActions === 'function') {
    return (dispatch, props) => {
      if (props.customName) {
        return isolateActions(
          dispatchResult => dispatch({ ...dispatchResult, nameSpace: props.customName }),
          props,
        );
      }
      return isolateActions(dispatch, props);
    };
  }

  return dispatchWrapper(isolateActions, staticActions);
};

const customConnect = (mapStateToProps, mapDispatchToProps, ...extraArgs) => {
  return connect(
    mapStateToProps && nameSpaceState(mapStateToProps),
    mapDispatchToProps && nameSpaceDispatch(mapDispatchToProps),
    ...extraArgs,
  );
};

export default customConnect;
