import React, { Component } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import DashboardIcon from '@material-ui/icons/Apps';
import EmailIcon from '@material-ui/icons/Email';
import ReportsIcon from '@material-ui/icons/TrendingUp';
import AdminIcon from '@material-ui/icons/Warning';
import CrmIcon from '@material-ui/icons/Announcement';
import PersonalIcon from '@material-ui/icons/SupervisorAccount';
import FileIcon from '@material-ui/icons/Folder';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import PhoneIcon from '@material-ui/icons/Phone';
import HelpIcon from '@material-ui/icons/LiveHelp';
import ChromeReaderMode from '@material-ui/icons/AssignmentInd';
import CollisionIcon from '@material-ui/icons/Shuffle';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import StoreIcon from '@material-ui/icons/Store';
import PaymentIcon from '@material-ui/icons/Payment';
import SmsIcon from '@material-ui/icons/Sms';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TelegramIcon from '../../simple/icons/telegram';
import JivoIcon from '../../simple/icons/jivosite';
import InstagramIcon from '../../simple/icons/instagram';
import WhatsappIcon from '../../simple/icons/whatsapp';
import OdinAssIcon from '../../simple/icons/odin-ass';

export default {
  '/': <DashboardIcon />,
  '/emails': <EmailIcon />,
  '/reports': <ReportsIcon />,
  '/admin-accesses': <AdminIcon />,
  '/crm': <CrmIcon />,
  '/crm-x-zibit': <CrmIcon />,
  '/crm-x-zibit-new': <CrmIcon />,
  '/telegram/channel': <TelegramIcon />,
  '/telegram/bot/patterns': <TelegramIcon />,
  '/call-center': <PhoneIcon />,
  '/media': <FileIcon />,
  '/passports': <ChromeReaderMode />,
  '/job-accounting/index': <MoneyIcon />,
  '/collision': <CollisionIcon />,
  '/staff/employers': <PersonalIcon />,
  '/help': <HelpIcon />,
  '/chat/jivosite': <JivoIcon />,
  '/chat/instagram': <InstagramIcon />,
  '/chat/whatsapp': <WhatsappIcon />,
  '/promotion': <RecentActorsIcon />,
  '/raffle-prizes': <CardGiftcardIcon />,
  '/shops-list': <StoreIcon />,
  '/payment-recipients': <PaymentIcon />,
  '/client/index': <ChromeReaderMode />,
  '/odinAss': <OdinAssIcon />,
  '/order/notification-patterns': <SmsIcon />,
  '/calendar/week': <EventNoteIcon />,
  default: <MenuIcon />,
};
