import Dexie from 'dexie';

import { getFetchWrapper } from 'utils/apiHelper';

import database from './database';

/**
 * @type {UserProfilesStorage|null}
 */
let instance = null;

/**
 * @type {OrderStorage}
 */
class UserProfilesStorage {
  /**
   * @type {{get: string, bulk: string}}
   */
  routes = {
    one: '/profile/names?userIds=:id',
    bulk: '/profile/names?userIds=:ids',
  };

  /**
   * @type {Dexie.Table}
   */
  table = database.table('user_profiles');

  /**
   *
   * @type {number}
   */
  killMePleaseAdd = 10 * 24 * 60 * 60 * 1000;

  constructor() {
    if (!instance) {
      instance = this;
    }

    setInterval(() => {
      this.killExpired();
    }, 60 * 60 * 1000);

    return instance;
  }

  /**
   *
   * @param id
   * @param force
   * @returns {Promise<*|any>}
   */
  async get(id, force = false) {
    const killMePleaseAt = new Date().getTime() + this.killMePleaseAdd;

    let object = null;

    if (force === false) {
      object = await this.table.get(id);

      if (object) {
        await this.table.update(id, { killMePleaseAt });
        return object;
      }
    }

    object = await getFetchWrapper(this.routes.one.replace(':id', id));

    object = { ...object[id], killMePleaseAt };

    await this.table.add(object);

    return object;
  }

  async getAllManagers() {
    return this.getByAccess('Отображается в списке менеджеров');
  }

  async getAllOperators() {
    return this.getByAccess('Отображается в списке операторов');
  }

  async getByAccess(access) {
    const encodeUri = encodeURI(`/user/managers/by-access/${access}`);

    const res = await getFetchWrapper(encodeUri);
    return this.bulkGet(res);
  }

  /**
   *
   * @param ids
   * @param force
   * @returns {Promise<Array>}
   */
  async bulkGet(ids, force = false) {
    // @todo Since v3.0.0-alpha.8 implement native bulkGet
    const objects = [];

    const bulks = [];

    const killMePleaseAt = new Date().getTime() + this.killMePleaseAdd;

    if (!force) {
      for (const id of ids) {
        const object = await this.table.get(id);

        if (object) {
          await this.table.update(id, { killMePleaseAt });
          objects.push(object);
        } else {
          bulks.push(id);
        }
      }
    } else {
      for (const id of ids) {
        bulks.push(id);
      }
    }

    if (bulks.length) {
      const rawBulkObjects = await getFetchWrapper(
        this.routes.bulk.replace(':ids', bulks.join(',')),
      );

      let bulkObjects = [];

      for (const index in rawBulkObjects) {
        bulkObjects.push(rawBulkObjects[index]);
      }

      bulkObjects = bulkObjects.map(bulkObject => {
        return { ...bulkObject, killMePleaseAt: new Date().getTime() + this.killMePleaseAdd };
      });

      if (bulkObjects.length) {
        await this.table.bulkPut(bulkObjects);
      }

      bulkObjects.forEach(bulkObject => {
        objects.push(bulkObject);
      });
    }

    return objects;
  }

  /**
   * @returns {Promise<void>}
   */
  async killExpired() {
    database
      .transaction('rw', this.table, async () => {
        this.table
          .where('killMePleaseAt')
          .below(new Date().getTime())
          .each(object => {
            this.table.delete(object.managerId).then(() => {
              console.info(`${object.managerId} was deleted`);
            });
          })
          .catch(error => {
            console.error(error);
          });
      })
      .then(() => {
        console.info('Delete expire user_profiles transaction committed');
      })
      .catch(error => {
        console.error(error);
      });
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async clear() {
    this.table
      .clear()
      .then(() => true)
      .catch(error => console.error(error));
  }
}

export default new UserProfilesStorage();
