import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { NavLink, useLocation } from 'react-router-dom';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Dialog from '@material-ui/core/Dialog';
import HomeIcon from '@material-ui/icons/Home';
import Divider from '@material-ui/core/Divider';
import AppsIcon from '@material-ui/icons/Apps';
import List from '@material-ui/core/List';
import SearchIcon from '@material-ui/icons/Search';
import WidgetsIcon from '@material-ui/icons/Widgets';
import SettingsIcon from '@material-ui/icons/Settings';
import history from '../../store/history';
import ListItemAside from '../../components/composite/aside/ListItemAside';
import SearchBoxMobile from '../../components/composite/search-box/search-box-mobile';
import { addReminder as addReminderAction } from '../../modules/System';
import CreateOrderButton from '../../components/composite/create-order-button';
import Notification from '../../components/composite/notification';
import Reminders from '../../components/composite/reminders';
import { openModal as openModalAction, closeModal as closeModalAction } from '../../modules/Modal';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    zIndex: '99999',
    bottom: 0,
    left: 0,
  },
  action: {
    minWidth: 52,
  },
});

const ActionContainer = ({ isShow, children, style }) => (
  <div
    style={{
      visibility: isShow ? 'visible' : 'hidden',
      top: 0,
      position: 'absolute',
      width: '100%',
      height: 'calc(100% - 56px)',
      left: 0,
      overflow: 'auto',
      ...style,
    }}>
    {children}
  </div>
);

function SimpleBottomNavigation({ data, widgets, openModal, closeModal, addReminder }) {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const [value, setValue] = useState('/menu');

  const location = useLocation();

  useEffect(() => {
    if (['/', '/menu', '/widgets', '/search', '/options'].includes(location.pathname)) {
      setShow(true);
    } else {
      setShow(false);
    }
    setValue(location.pathname);
  }, [location]);

  return (
    <>
      <ActionContainer isShow={value === '/widgets'}>
        <div
          style={{
            lineHeight: '1.4em',
            position: 'absolute',
            width: 'calc(100% - 56px)',
            textAlign: 'center',
            height: '56px',
            display: 'flex',
            alignItems: 'center',
            opacity: 0.7,
            padding: '0 12px',
          }}>
          Для вызова меню телефонии нажмите на иконку
        </div>
        {widgets}
        <Divider />
        <div style={{ paddingTop: 16, display: 'flex', gap: 30 }}>
          <Notification />
          <Reminders openModal={openModal} closeModal={closeModal} addReminder={addReminder} />
          <CreateOrderButton
            openModal={openModal}
            closeModal={closeModal}
            style={{ padding: '12px' }}
          />
        </div>
      </ActionContainer>
      <ActionContainer
        isShow={value === '/search'}
        style={{ background: 'var(--color-background)' }}>
        <SearchBoxMobile />
      </ActionContainer>
      <ActionContainer isShow={value === '/menu'} style={{ background: 'var(--color-sidebar)' }}>
        <div className="scroll" style={{ height: '100%', overflow: 'hidden auto' }}>
          <h2
            style={{
              fontSize: '22px',
              lineHeight: '1.4em',
              padding: '12px 0',
              textAlign: 'center',
              color: '#eee',
            }}>
            ERP Menu
          </h2>
          <Divider />
          <List>
            {data &&
              data.map((item, index) => (
                <ListItemAside item={item} index={index} key={item.label + item.url} />
              ))}
          </List>
        </div>
      </ActionContainer>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
        style={{
          visibility: show ? 'visible' : 'hidden',
          background: 'var(--color-background-f)',
        }}>
        {/* <BottomNavigationAction className={classes.action} value="/" onClick={() => history.push('/')} label="Главная" icon={<HomeIcon />} /> */}
        <BottomNavigationAction
          className={classes.action}
          value="/menu"
          onClick={() => history.push('/menu')}
          label="Меню"
          icon={<AppsIcon />}
        />
        <BottomNavigationAction
          className={classes.action}
          value="/search"
          label="Поиск"
          onClick={() => history.push('/search')}
          icon={<SearchIcon />}
        />
        <BottomNavigationAction
          className={classes.action}
          value="/widgets"
          label="Виджеты"
          onClick={() => history.push('/widgets')}
          icon={<WidgetsIcon />}
        />
        <BottomNavigationAction
          className={classes.action}
          value="/options"
          label="Опции"
          onClick={() => history.push('/options')}
          icon={<SettingsIcon />}
        />
      </BottomNavigation>
    </>
  );
}

const mapDispatch = {
  openModal: openModalAction,
  closeModal: closeModalAction,
  addReminder: addReminderAction,
};

export default connect(
  null,
  mapDispatch,
)(SimpleBottomNavigation);
