import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import CopyLink from '../../simple/copy-link';
import useResizeObserver from '../../hooks/useResizeObserver';
import ProductCardSizes from '../product-card/product-table-sizes';
import ProductCardMeasurements from '../product-card/product-table-measurements';
import translatePhrases from '../../../modules/call-center/translatePhrases';

const digitalSize = {
  XS: 40,
  S: 42,
  M: '44/46',
  L: '48/50',
  XL: 52,
  '2XL': '54/56',
  '3XL': 58,
};

const CustomTooltip = React.forwardRef((props, ref) => (
  <span ref={ref} {...props}>
    {props.children}
  </span>
));

CustomTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

const measurePhrases = {
  breast: {
    ru: 'Грудь',
    uk: 'Груди',
    en: 'Chest',
  },
  waist: {
    ru: 'Талия',
    uk: 'Талія',
    en: 'Waist',
  },
  hips: {
    ru: 'Бедра',
    uk: 'Стегна',
    en: 'Hip',
  },
  strap: {
    ru: 'Длина бретелей',
    uk: 'Довжина бретелей',
    en: 'Straps length',
  },
  shoulderWidth: {
    ru: 'Ширина спинки по плечам',
    uk: 'Ширина спинки по плечах',
    en: 'Back width over shoulders',
  },
  backWidth: {
    ru: 'Ширина спинки',
    uk: 'Ширина спинки',
    en: 'Back width',
  },
  backLength: {
    ru: 'Длина по спинке',
    uk: 'Довжина спинки',
    en: 'Back length',
  },
  lengthBySideSeam: {
    ru: 'Длина по бок.шву',
    uk: 'Довжина бічного шва',
    en: 'Side seam',
  },
  lengthByStepperSeam: {
    ru: 'Дл.рукава по внутр.шву',
    uk: 'Довжина рукава по внутрішньому шву',
    en: 'Inner seam of sleeve',
  },
  sleeveOuterSeam: {
    ru: 'Дл.рукава по внешн.шву',
    uk: 'Довжина рукава по зовнішньому шву',
    en: 'Outside seam of sleeve',
  },
  lengthOfShot: {
    ru: 'shot',
    uk: 'shot',
    en: 'shot',
  },
  height: {
    ru: 'Высота',
    uk: 'Висота',
    en: 'Height',
  },
  width: {
    ru: 'Ширина',
    uk: 'Ширина',
    en: 'Width',
  },
  length: {
    ru: 'Длина',
    uk: 'Довжина',
    en: 'Length',
  },
  diameter: {
    ru: 'Диаметр',
    uk: 'Діаметр',
    en: 'Diameter',
  },
};

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    // height: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
  },
  cover: {
    width: 250,
  },
  controls: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  chip: {
    color: '#333',
  },
  chipFitting: {
    color: 'white',
    background: 'red',
    fontWeight: '800',
  },

  langBtn: {
    cursor: 'pointer',
    fontSize: '14px',
    margin: '0 10px',
    textTransform: 'uppercase',
  },
  langBtnActive: {
    fontWeight: 600,
    color: 'var(--color-1)',
  },
  priceName: {
    display: 'inline-block',
    width: '60px',
    verticalAlign: 'top',
  },
  priceDesc: {
    display: 'inline-block',
    width: 'calc(100% - 85px)',
    verticalAlign: 'top',
  },
  subtitle: {
    flex: '1 1 100%',
    color: 'inherit',
  },
}));

ProductTableDetailCard.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  currentSize: PropTypes.string,
};

ProductTableDetailCard.defaultProps = {
  currentSize: '',
};

function ProductTableDetailCard({ product, currentSize, noImage, style }) {
  const [lang, setLang] = useState('ru');
  const containerRef = useRef(null);
  const width = useResizeObserver(containerRef);
  const classes = useStyles();

  function renderPrice(prod, price) {
    return `${prod[price].actual} ${
      prod[price].discountPercent ? `(${prod[price].discountPercent} %)` : ''
    } грн | ${prod[price].actualRub} ${
      prod[price].discountPercent ? `(${prod[price].discountPercent} %)` : ''
    } руб | ${prod[price].actualUsd} ${
      prod[price].discountPercent ? `(${prod[price].discountPercent} %)` : ''
    } usd`;
  }

  const retailPrice = renderPrice(product, 'retailPrice');
  const wholesalePrice = renderPrice(product, 'wholesalePrice');

  return (
    <Card
      className={classes.card}
      ref={containerRef}
      style={{ height: noImage ? '100%' : 'auto', ...style }}>
      {width > 1400 && (
        <CardMedia
          className={classes.cover}
          image={product.images.jpg.fastOrder388[0]}
          title="Подробно о продукте"
        />
      )}
      <div
        className={classes.details}
        style={{ flexWrap: 'wrap', flexDirection: 'row', height: '100%' }}>
        {noImage ||
          (width > 600 && width < 1400 && (
            <img
              style={{ maxHeight: '280px', maxWidth: '200px' }}
              src={product.images.jpg.fastOrder388[0]}
              alt="Подробно о продукте"
            />
          ))}
        <div style={{ flex: '1 1 calc(100% - 200px)', position: 'relative' }}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5" color="textPrimary">
              <a
                href={`${process.env.REACT_APP_SITE_URL}/p/${product.article}`}
                style={{ color: 'var(--color-1)' }}
                rel="noopener noreferrer"
                target="_blank">
                {product.name}
              </a>
            </Typography>

            <div>
              {['uk', 'ru', 'en'].map((item, index) => (
                <button
                  key={item}
                  type="button"
                  className={`${classes.langBtn} ${lang === item ? classes.langBtnActive : ''}`}
                  onClick={() => setLang(item)}>
                  {item}
                </button>
              ))}
            </div>

            {!product.fittingAvailable && (
              <Chip label="Не доступен для примерки" className={classes.chipFitting} />
            )}
            <Chip label={product.status || '--'} color="primary" className={classes.chip} />

            <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
              {`${product.article}  ${product.provider} (${product.providerName})`}
            </Typography>
          </CardContent>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              lineHeight: '1.4em',
              flexWrap: 'wrap',
              padding: '8px 16px',
              fontSize: '12px',
              fontWeight: 400,
            }}>
            <div style={{ minWidth: '150px', width: '100%' }}>
              {product.fabricStructure && <div>{`Состав: ${product.fabricStructure[lang]}`}</div>}
              {product.color && <div>{`Цвет: ${product.color[lang]}`}</div>}
              {product.textile && <div>{`Материал: ${product.textile[lang]}`}</div>}
            </div>
            <div style={{ minWidth: '250px', width: '100%' }}>
              <div>
                <span className={classes.priceName}>Розница:</span>
                <CopyLink
                  currentLink={retailPrice}
                  style={{
                    color: 'var(--color-1)',
                    display: 'inline-block',
                    width: 'calc(100% - 85px)',
                    verticalAlign: 'top',
                  }}
                />
              </div>
              <div>
                <span className={classes.priceName}>Опт:</span>
                <CopyLink
                  currentLink={wholesalePrice}
                  style={{
                    color: 'var(--color-1)',
                    display: 'inline-block',
                    width: 'calc(100% - 85px)',
                    verticalAlign: 'top',
                  }}
                />
              </div>
            </div>
          </div>
          {product.description && (
            <div
              style={{
                margin: '8px 16px',
                lineHeight: '1.4em',
                fontSize: '12px',
                fontWeight: 400,
              }}>
              {product.description[lang]}
            </div>
          )}
        </div>

        <div className={classes.controls} style={{ flex: '1 1 100%' }}>
          {width < 800 ? (
            <>
              <ProductCardSizes
                currentSize={currentSize}
                colorText="inherit"
                product={product}
                style={{ marginBottom: 15, color: 'inherit' }}
              />
              <ProductCardMeasurements
                currentSize={currentSize}
                colorText="inherit"
                product={product}
                lang={lang}
              />
            </>
          ) : (
            <Table size="small">
              <TableHead style={{ color: 'inherit' }}>
                <TableRow>
                  <TableCell style={{ width: '70px', padding: '6px 10px' }}>Размеры</TableCell>
                  <TableCell
                    style={{ width: '500px' }}
                    colSpan={product.stores.length}
                    align="center">
                    Остатки (шт.)
                  </TableCell>
                  <TableCell align="center">Замеры</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: '70px' }} />
                  {product.stores.map(store => (
                    <TableCell key={store} align="center" style={{ padding: '6px 6px' }}>
                      <Tooltip title={translatePhrases(store)} placement="top">
                        <CustomTooltip>{store}</CustomTooltip>
                      </Tooltip>
                    </TableCell>
                  ))}
                  {!product.stores.length && (
                    <>
                      <TableCell align="center" style={{ padding: '6px 6px' }}>
                        <Tooltip title="Ривьера" placement="top">
                          <CustomTooltip>RIV</CustomTooltip>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" style={{ padding: '6px 6px' }}>
                        <Tooltip title="СИТИК" placement="top">
                          <CustomTooltip>CIT</CustomTooltip>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" style={{ padding: '6px 6px' }}>
                        <Tooltip title="Лавина" placement="top">
                          <CustomTooltip>LVN</CustomTooltip>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" style={{ padding: '6px 6px' }}>
                        <Tooltip title="Блокбастер" placement="top">
                          <CustomTooltip>BLO</CustomTooltip>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" style={{ padding: '6px 6px' }}>
                        Склад
                      </TableCell>
                    </>
                  )}

                  {/* <TableCell align="center" style={{ padding: '6px 10px' }}>
                    <Tooltip title="склад / резерв" placement="top">
                      <CustomTooltip>Брянск</CustomTooltip>
                    </Tooltip>
                  </TableCell> */}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {product.sizes.map(
                  ({ isAvailable, size, balances = {}, stockBalances = {}, measurements = {} }) => {
                    const styleCell = !isAvailable ? { color: 'red' } : { color: 'inherit' };
                    const currSize = currentSize ? currentSize.toLowerCase() : null;
                    return (
                      <TableRow
                        key={size}
                        style={{
                          color: 'inherit',
                          backgroundColor:
                            size.toLowerCase() === currSize
                              ? 'var(--color-1-light)'
                              : 'transparent',
                        }}
                        hover>
                        <TableCell
                          style={{ width: '120px', color: 'inherit' }}
                          component="th"
                          scope="row">
                          {`${size}  ${digitalSize[size.toUpperCase()] || ''}`}
                        </TableCell>
                        {product.stores.map(store => (
                          <TableCell
                            key={store}
                            align="center"
                            style={{ ...styleCell, width: '70px' }}>
                            {balances[store] || 0}
                          </TableCell>
                        ))}

                        {!product.stores.length && (
                          <>
                            <TableCell align="center" style={{ ...styleCell, width: '70px' }}>
                              {stockBalances.riviera || 0}
                            </TableCell>
                            <TableCell align="center" style={{ ...styleCell, width: '70px' }}>
                              {stockBalances.cityCenter || 0}
                            </TableCell>
                            <TableCell align="center" style={{ ...styleCell, width: '70px' }}>
                              {stockBalances.lavina || 0}
                            </TableCell>
                            <TableCell align="center" style={{ ...styleCell, width: '70px' }}>
                              {stockBalances.blockbuster || 0}
                            </TableCell>
                            <TableCell align="center" style={{ ...styleCell, width: '70px' }}>
                              {stockBalances.stockhouse || 0}
                            </TableCell>
                          </>
                        )}

                        {/* <TableCell align="center" style={{ ...style, width: '70px' }}>
                          {`${stockBalances.bryansk || 0}/${stockBalances.bryanskReserve || 0}`}
                        </TableCell> */}
                        <TableCell component="th">
                          <CopyLink
                            currentLink={Object.entries(measurements).map(([key, value]) =>
                              measurePhrases[key]
                                ? `${measurePhrases[key][lang]} : ${value} `
                                : null,
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    </Card>
  );
}

export default ProductTableDetailCard;
