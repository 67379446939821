import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { getFetchWrapper } from 'utils/apiHelper';

export default function Ping() {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [text, setText] = useState('');

  function pingPong(i = 10) {
    if (i < 1) {
      setLoad(false);
    } else {
      setLoad(true);
      const t0 = performance.now();
      getFetchWrapper('/crm/relations/xzibit/my/active').then(() => {
        setData(old => [...old, `${Math.round(performance.now() - t0)}`]);
        pingPong(i - 1);
      });
    }
  }

  function tell(command) {
    const formData = new FormData();

    // ?method=call

    // formData.append('command', 'calls');
    formData.append('command', command);

    // https://admin:admin@192.168.0.94/linphonec
    fetch('/calls/linphonec', {
      method: 'POST',
      credentials: 'include',
      mode: 'no-cors',
      headers: {
        Accept:
          'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
        Authorization: `Basic ${window.btoa('admin:admin')}`,
      },
      body: formData,
    })
      .then(res => {
        console.log({ res });
        return res.text();
      })
      .then(resText => {
        console.log({ resText });
        setText(resText);
      });
  }

  return (
    <>
      <Button
        color="primary"
        style={{ margin: '10px' }}
        onClick={() => {
          setData([]);
          pingPong(10);
        }}
        disabled={load}
        variant="contained">
        Ping
      </Button>
      <Button
        color="primary"
        style={{ margin: '10px' }}
        onClick={() => setText('')}
        variant="contained">
        reset
      </Button>

      <Button
        color="primary"
        style={{ margin: '10px' }}
        onClick={() => tell('call 60990140515')}
        variant="contained">
        call 60990140515
      </Button>

      <Button
        color="primary"
        style={{ margin: '10px' }}
        onClick={() => tell('calls')}
        variant="contained">
        call status
      </Button>

      <Button
        color="primary"
        style={{ margin: '10px' }}
        onClick={() => tell('terminate')}
        variant="contained">
        terminate
      </Button>

      <Button
        color="primary"
        style={{ margin: '10px' }}
        onClick={() => tell('answer')}
        variant="contained">
        answer
      </Button>

      <div style={{ margin: '10px' }}>
        {data.map(item => (
          <p style={{ color: item < 200 ? 'green' : 'red' }}>
            {item}
            ms
          </p>
        ))}
      </div>
      {text}
    </>
  );
}
