import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import modalManager from '../../../utils/modalManager';
import ResizableAndDragComponent from './ResizableAndDragComponent';

function PaperComponent(props) {
  return <Paper {...props} />;
}

const SingleModal = memo(
  ({ open, modalId, closeModalAction, options }) => {
    return (
      <Dialog
        disableEnforceFocus
        fullWidth={options.fullWidth}
        maxWidth={options.maxWidth}
        open={open}
        onClose={() => closeModalAction(modalId)}
        PaperComponent={
          options.draggable || options.resizable
            ? ResizableAndDragComponent({
                name: modalId,
                draggable: options.draggable,
                resizable: options.resizable,
                rndOptions: options.rndOptions,
                width: options.width,
                height: options.height,
              })
            : PaperComponent
        }
        PaperProps={options.PaperProps}
        aria-labelledby={`draggable-dialog-title-${modalId}`}
        {...options.dialogProps}>
        {options.title && (
          <DialogTitle
            style={options.draggable ? { cursor: 'move' } : null}
            id={`${options.draggable ? 'draggable-' : ''}dialog-title-${modalId}`}>
            {options.title}
          </DialogTitle>
        )}

        <DialogContent style={options.DialogContent ? options.DialogContent.style : undefined}>
          {modalManager.modals[modalId].modalComponent}
        </DialogContent>
        {options.actions && <DialogActions>{options.actions}</DialogActions>}
      </Dialog>
    );
  },
  (prevProps, nextProps) =>
    prevProps.modalId === nextProps.modalId && prevProps.open === nextProps.open,
);

SingleModal.propTypes = {
  closeModalAction: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  modalId: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
};

export default SingleModal;
