import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as hotKeysActions from '../../../modules/HotKeys';
import Mousetrap from '../../../config/mouse';

Mousetrap.init();

const keyBinding = (keyMap, handlers) => {
  for (const key in keyMap) {
    if (keyMap[key] && handlers[key]) {
      Mousetrap.bindGlobal(keyMap[key], handlers[key], key);
    } else {
      console.error(`Расхождение в именах горячих клавиш: ${key}`);
    }
  }
};

const keyUnBinding = keyMap => {
  for (const key in keyMap) {
    if (Object.prototype.hasOwnProperty.call(keyMap, key)) {
      Mousetrap.unbindGlobal(keyMap[key], key);
    }
  }
};

class HotKey extends Component {
  componentDidMount() {
    const { keysObj } = this.props;

    this.props.registerHotKeys(keysObj);

    for (const key in keysObj) {
      if (Object.prototype.hasOwnProperty.call(keysObj, key)) {
        keyBinding(keysObj[key].keyMap, keysObj[key].handlers, key);
      }
    }
  }

  componentDidUpdate(nextProps) {
    const { keysObj } = nextProps;

    for (const key in keysObj) {
      if (Object.prototype.hasOwnProperty.call(keysObj, key)) {
        keyBinding(keysObj[key].keyMap, keysObj[key].handlers, key);
      }
    }
  }

  componentWillUnmount() {
    const { keysObj } = this.props;

    for (const key in keysObj) {
      if (Object.prototype.hasOwnProperty.call(keysObj, key)) {
        keyUnBinding(keysObj[key].keyMap);
      }
    }
  }

  render() {
    return this.props.children || null;
  }
}

HotKey.propTypes = {
  keysObj: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.element,
  registerHotKeys: PropTypes.func.isRequired,
};

HotKey.defaultProps = {
  children: null,
};

const mapStateToProps = ({ hotKeys }) => ({ hotKeys });

const mapDispatchToProps = {
  ...hotKeysActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HotKey);
