import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import './preloader.scss';

const Preloader = ({ isLoad = true, absolute, children, background, size, style }) =>
  isLoad ? (
    <div
      style={style}
      className={`preloader__container ${absolute && 'abs'} ${background && 'background'}`}>
      <div className="preloader" style={{ width: size, height: size }}>
        <CircularProgress size={size} />
      </div>
    </div>
  ) : (
    children || null
  );

Preloader.propTypes = {
  absolute: PropTypes.bool,
  background: PropTypes.bool,
  size: PropTypes.number,
  style: PropTypes.instanceOf(Object),
  isLoad: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

Preloader.defaultProps = {
  absolute: false,
  isLoad: true,
  children: null,
  size: 40,
  style: {},
  background: null,
};

export default Preloader;
