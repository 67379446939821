// export {default as TextInput} from './text-input';
import TextInput from './text-input';
import DateInput from './date-input';
import TimeInput from './time-input';
import DateTimeInput from './date-time-input';
import SelectInput from './select-input';
import MultiSelectInput from './multi-select-input';
import AsyncSelectInput from './async-select-input';
import AutocompleteInput from './autocomplete-input';
import AutocompleteField from './autocomplete-field';
import CounterInput from './counter-input';
import Input from './input';
import Checkbox from './checkbox';
import Switch from './switch';

export {
  TextInput,
  DateInput,
  DateTimeInput,
  TimeInput,
  Switch,
  SelectInput,
  MultiSelectInput,
  AutocompleteInput,
  AutocompleteField,
  AsyncSelectInput,
  CounterInput,
  Checkbox,
  Input,
};
