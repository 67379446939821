import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SourceList from 'modules/crm/source.list';
import get from 'lodash/get';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import Tooltip from '@material-ui/core/Tooltip';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import * as searchActions from '../../../modules/Search';
import { attachAppeal } from '../../../modules/System';
import { openModal, closeModal } from '../../../modules/Modal';
import history from '../../../store/history';
import translatePhrases from '../../../modules/call-center/translatePhrases';
import modalManager from '../../../utils/modalManager';
// import OrderDetail from '../order-detail';
import CopyLink from '../../simple/copy-link';
import dateMaker from '../../../utils/dataMaker';
import ProductCard from '../product-table-advanced/productTableDetailCard';
// import orderItemHOC from '../../hocs/order-item.HOC';
// import OrderItem from '../../../modules/call-center/components/orderItem';
import { orderItem } from '../../../modules/index';
import AsyncComponent from '../../../AsyncComponent';
import './search-list.scss';

// const OrderItemWrapper = orderItemHOC(OrderItem);

SearchList.propTypes = {
  attachAppeal: PropTypes.func.isRequired,
  searchResult: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.instanceOf(Object)]),
  type: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

SearchList.defaultProps = {
  searchResult: [],
};

function SearchList(props) {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleRequestChange = (id, clientId) => {
    setSelectedIndex(id);
    history.push(`/crm/show/${clientId}/${id}`);
  };

  const handleOrderDetails = order => {
    modalManager.registerModal(
      'OrderDetail',
      <AsyncComponent moduleProvider={orderItem} order={order} />,
      {
        title: `Просмотр заказа №${order.orderNumber}`,
        fullWidth: true,
        maxWidth: 'xl',
      },
    );

    props.openModal('OrderDetail');
  };

  const handleAddRelationBody = (id, email) => props.attachAppeal(id, email);

  const renderIcons = item => SourceList.getSourceIcon(item.source, item.replied);

  const renderItemProduct = item => <ProductCard product={item} />;

  const renderItems = (item, ind) => (
    <Fragment key={ind}>
      <ListItem
        button
        onClick={() => handleRequestChange(item.id, item.clientId)}
        selected={selectedIndex === item.id}>
        <ListItemAvatar>
          <Avatar>{renderIcons(item)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={get(item, ['client', 'accounts', item.source, 0, 'lastName'], item.accountId)}
          secondary={`Дата: ${dateMaker(item.receivedAt, true, true, true)}`}
        />
        <ListItemSecondaryAction>
          <Button onClick={() => handleAddRelationBody(item.id, item.clientId)} variant="contained">
            Взять
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </Fragment>
  );

  const renderItemsCallTasks = (item, ind) => (
    <Fragment key={ind}>
      <ListItem
        button
        component="a"
        target="_blank"
        rel="noopener noreferrer"
        href={`/call-center/${item.type}/${item.taskId}`}
        selected={selectedIndex === item.id}>
        <ListItemAvatar>
          <Avatar>
            <SwapVertIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${translatePhrases(item.type)} ${
            item.orderStatus ? `: ${item.orderStatus}` : ''
          }`}
          secondary={
            <div style={{ fontSize: '12px' }}>
              {translatePhrases(item.status)}
              {!!item.paymentStatus && `, ${item.paymentStatus}`}
              {!!item.orderNumber && `, ${item.orderNumber}`}
              <br />
              {`Дата создания: ${dateMaker(item.createdAt, true, true, true) || '--:--'}`}
              {item.trackNumber && (
                <CopyLink currentLink={item.trackNumber} style={{ color: 'var(--color-1)' }} />
              )}
            </div>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </Fragment>
  );

  const renderItemsOrderTasks = (item, ind) => (
    <Fragment key={ind}>
      <ListItem
        button
        onClick={() => history.push(`/call-center/${item.type}/${item.taskId}`)}
        selected={selectedIndex === item.id}>
        <ListItemAvatar>
          <Avatar>
            <SwapVertIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`Статус: ${item.status ? translatePhrases(item.status) : 'неизвестен'}`}
          secondary={
            <>
              {`Тип: ${translatePhrases(translatePhrases(item.mark))}`}
              <br />
              {`Дата создания: ${dateMaker(item.createdAt, true, true, true)}`}
            </>
          }
        />
        <ListItemSecondaryAction>
          <Tooltip
            placement="bottom-start"
            title={<span style={{ fontSize: '14px' }}>История переписки</span>}>
            <Link to={`/crm/show/${item.clientId}`} style={{ width: '21px', height: '21px' }}>
              <MailOutlineIcon />
            </Link>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </Fragment>
  );

  const renderDropBasket = dropNumber =>
    dropNumber === 0 ? 'дроп (все корзины)' : `корзина ${dropNumber}`;

  const renderItemsOrder = (item, ind) => (
    <Fragment key={ind}>
      <ListItem
        button
        onClick={() => handleOrderDetails(item)}
        selected={selectedIndex === item.id}>
        <ListItemAvatar>
          <Avatar>
            <SwapVertIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${item.orderNumber} (${item.status}) ${translatePhrases(item.typePrice)} ${
            typeof item.dropNumber === 'number' ? renderDropBasket(item.dropNumber) : ''
          } ${item.parentId !== '' ? ' доотправка ' : ''}`}
          secondary={
            <>
              {`Оплата: ${item.paymentStatus}`}
              <br />
              {`Дата оформления: ${dateMaker(item.receivedAt, true, true, true)}`}
              {item.delivery && item.delivery.declaration && (
                <>
                  <br />
                  {'Трек: '}
                  <CopyLink
                    currentLink={item.delivery.declaration}
                    style={{ color: 'var(--color-1)' }}
                  />
                </>
              )}
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </Fragment>
  );

  const itemByType = type => {
    switch (type) {
      case 'product_by_article':
        return renderItemProduct;

      case 'by_callTask_phone':
        return renderItemsCallTasks;

      case 'by_order_number':
        return renderItemsOrderTasks;

      case 'by_orders':
        return renderItemsOrder;

      default:
        return renderItems;
    }
  };

  const resultItems = itemByType(props.type);
  const { searchResult } = props;

  if (!searchResult) {
    return null;
  }

  return Array.isArray(searchResult) ? (
    <List>{searchResult.map(resultItems)}</List>
  ) : (
    resultItems(searchResult)
  );
}

const mapStateToProps = ({ system }) => ({
  theme: system.theme,
});

const mapDispatchToProps = {
  ...searchActions,
  attachAppeal,
  openModal,
  closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchList);
