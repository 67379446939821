/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { string, instanceOf, bool, oneOfType } from 'prop-types';
import { Field } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

Counter.propTypes = {
  limit: instanceOf(Array),
  disabled: oneOfType([string, bool]),
  input: instanceOf(Object).isRequired,
  meta: instanceOf(Object).isRequired,
  touched: string,
  invalid: string,
  error: string,
  disableCounters: bool,
};

Counter.defaultProps = {
  limit: [0, 999],
  disabled: false,
  touched: '',
  invalid: '',
  error: '',
  disableCounters: false,
};

function Counter({
  limit = [0, 999],
  disabled,
  input,
  meta: { touched, invalid, error },
  change,
  helperText,
  disableCounters,
  ...props
}) {
  function handleChange(e) {
    if (/\d/.test(e.target.value) && e.target.value >= limit[0] && e.target.value <= limit[1]) {
      change && change(e.target.value);
      input.onChange(e.target.value);
    }
  }

  function autoChange(number) {
    if (!disabled) {
      change && change(number);
      input.onChange(number);
    }
  }

  return (
    <TextField
      {...props}
      {...input}
      disabled={disabled}
      onChange={handleChange}
      error={touched && invalid}
      helperText={(touched && error) || helperText}
      inputProps={{ style: { textAlign: 'center' } }}
      InputProps={
        disableCounters
          ? null
          : {
              startAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => autoChange(input.value > limit[0] ? +input.value - 1 : limit[0])}>
                  <RemoveIcon style={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => autoChange(input.value < limit[1] ? +input.value + 1 : limit[1])}>
                  <AddIcon style={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),
            }
      }
    />
  );
}

export default props => <Field component={Counter} {...props} />;
