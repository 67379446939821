const ws = {};
let instance = null;

/* global apiHost */
export const apiUrl = typeof apiHost !== 'undefined' ? apiHost : process.env.REACT_APP_API_URL;

class WebSocketManager {
  constructor() {
    if (!instance) {
      instance = this;
    } else {
      console.info('WebSocket manager has been register!');
    }

    return instance;
  }

  ws = () => ws;

  registerWebsocket = (id, url, firstMessage) => {
    if (ws[id]) {
      console.warn(`ws ${id} already exists`);
      return ws[id];
    }

    ws[id] = new WebSocket(url);

    if (firstMessage) {
      ws[id].onopen = () => ws[id].send(firstMessage);
    }

    // console.info(`ws ${id} registered`);

    return ws[id];
  };

  unRegisterWebsocket = id => {
    console.info('unRegisterWebsocket');
    if (ws[id]) {
      ws[id].close();
      delete ws[id];
    } else {
      console.warn(`ERP: Websocket id - "${id} is not register in websocket manager"`);
    }
  };
}

export default new WebSocketManager();
