// Старые константы
export const AUTO_TAKE_LOCALSTORAGE_KEY_CHANGED = 'isAutoTakeChanged';

// Новые константы полученные в результате парсинга
export const CALL_NUMBER_TRIGGER = 'callNumberTrigger';
export const SEARCH_ORDERS_BY_ID = 'searchOrdersById';
export const SEARCH_PRODUCT_BY_ARTICLE = 'searchProductByArticle';
export const FIND_INFO = 'findInfo';
export const SEND_EMAIL_TRIGGER = 'sendEmailTrigger';
export const SEND_SMS = 'sendSms';
export const THREAD_BEING_REFRESH = 'threadBeingRefresh'; // for instagram
export const ADD_RELATION = 'addRelation';
export const ADD_APPEAL = 'addAppeal';
export const CLOSE_APPEAL = 'closeAppeal';
export const CREATE_RELATION = 'createRelation';
export const CLOSE_RELATION = 'closeRelation';
export const UPDATE_RELATION = 'updateRelation';
export const JIVOSITE_GET_APPEAL = 'jivositeGetAppeal';
export const ATTACH_APPEAL = 'attachAppeal';
export const TRIGERED_CALL = 'trigeredCall';
export const HISTORY_MANAGER = 'historyManager';
export const ADD_CHAT_MESSAGE = 'addChatMessage';

// ! Предлагаю оставить этот код (в закомментированном виде).
// Возможно позже появятся новые константы и нужно будет снова их парсить.

// const text =
// `callNumberTrigger
// searchOrdersById
// searchOrdersById
// searchProductByArticle
// searchOrdersById
// searchProductByArticle
// searchOrdersById
// searchOrdersById
// searchOrdersById
// searchProductByArticle
// searchOrdersById
// searchProductByArticle
// searchOrdersById
// searchOrdersById
// searchProductByArticle
// callNumberTrigger
// searchOrdersById
// searchOrdersById
// searchProductByArticle
// searchOrdersById
// searchProductByArticle
// findInfo
// sendEmailTrigger
// callNumberTrigger
// sendSms
// callNumberTrigger
// callNumberTrigger
// callNumberTrigger
// callNumberTrigger
// callNumberTrigger
// callNumberTrigger
// callNumberTrigger
// callNumberTrigger
// callNumberTrigger
// callNumberTrigger
// findInfo
// findInfo
// threadBeingRefresh
// searchOrdersById
// searchOrdersById
// addRelation
// addRelation
// addAppeal
// closeAppeal
// createRelation
// closeRelation
// closeRelation
// createRelation
// updateRelation
// jivositeGetAppeal
// attachAppeal
// callNumberTrigger
// callNumberTrigger
// findInfo
// trigeredCall
// historyManager
// callNumberTrigger`;

// const namesAll = text.split('\n');
// const namesUnique = Array.from(new Set(namesAll));

// const upperCaseLetters = [65, 90];
// const lowerCaseLetters = [97, 122];

// function getLetterCase(letter) {
//   if(typeof letter !== 'string')
//     throw new Error(`'${letter}' is not a string type`);

//   if(letter.length !== 1)
//     throw new Error(`'${letter}' must have only 1 char`);

//   const charCode = letter.charCodeAt(0);

//   if(charCode >= lowerCaseLetters[0] && charCode <= lowerCaseLetters[1])
//     return -1; // lower case

//   if(charCode >= upperCaseLetters[0] && charCode <= upperCaseLetters[1])
//     return 1; // upper case

//   return 0; // this char is not a letter, and it don't have a case
// }

// const result = namesUnique.map(name => {

//   let newName = name;
//   for(let index=0; index < newName.length-2; ++index) {
//     const xChar = newName[index];
//     const yChar = newName[index+1];

//     if(getLetterCase(xChar) === -1 && getLetterCase(yChar) === 1) {
//       newName = newName.replace(xChar+yChar, `${xChar}_${yChar}`);
//     }
//   }

//   return `export const ${newName.toUpperCase()} = '${name}';`;
// });

// result.forEach(el=>console.info(el));
