const modals = {};
let instance = null;

class ModalManager {
  constructor() {
    if (!instance) {
      instance = this;
    } else {
      console.info('Modal manager has been register!');
    }

    return instance;
  }

  get modals() {
    return modals;
  }

  registerModal = (id, modalComponent, options = {}) => {
    modals[id] = {
      modalComponent,
      options,
    };
    return () => this.unRegisterModal(id);
  };

  unRegisterModal = id => {
    if (modals[id]) {
      delete modals[id];
    } else {
      console.warn(`ERP: modal id - "${id} is not register in modal manager"`);
    }
  };
}

export default new ModalManager();
