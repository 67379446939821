import * as Mousetrap from 'mousetrap';

const _globalCallbacks = {};
const _globalNames = {};
const _originalStopCallback = Mousetrap.prototype.stopCallback;

Mousetrap.prototype.stopCallback = function stopCallback(e, element, combo, sequence) {
  if (this.paused) {
    return true;
  }

  if (_globalCallbacks[combo] || _globalCallbacks[sequence]) {
    return false;
  }

  return _originalStopCallback.call(this, e, element, combo);
};

Mousetrap.prototype.bindGlobal = function bindF(keys, callback, name) {
  if (_globalNames[name]) {
    return;
  }

  this.bind(keys, callback);
  if (keys instanceof Array) {
    for (let i = 0; i < keys.length; i += 1) {
      _globalCallbacks[keys[i]] = true;
    }
    return;
  }

  _globalNames[name] = true;
  _globalCallbacks[keys] = true;
};

Mousetrap.prototype.unbindGlobal = function unbindF(keys, name) {
  if (keys instanceof Array) {
    for (let i = 0; i < keys.length; i += 1) {
      _globalCallbacks[keys[i]] = false;
    }
    return;
  }

  _globalCallbacks[keys] = false;
  _globalNames[name] = false;
};

export default Mousetrap;
