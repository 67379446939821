// TODO: переделать на нативный метод new Date().toLocaleString();

/**
 * @param date дата
 * @param utc конвертировать в utc
 * @param isTime отображение часов и минут
 * @return string
 */
function dateMaker(date, utc = true, isTime = true, isYear = false, isSeconds = false) {
  const utcOffset = utc ? new Date(date).getTimezoneOffset() / 60 : 0;

  const nDate = typeof date === 'number' ? date : new Date(date).getTime();

  const time = new Date(nDate - 3600000 * utcOffset);

  if (typeof time !== 'object') {
    console.warn('ERP error: date is not a number.');
    return 'invalid date';
  }

  let seconds = time.getSeconds().toString();
  let minutes = time.getMinutes().toString();
  let hours = time.getHours();
  const day = time.getDate();
  const month = time.getMonth();
  const year = time.getUTCFullYear();

  hours = hours.toString();

  const getCurrentMonth = el => {
    switch (el) {
      case 0:
        return 'Января';

      case 1:
        return 'Февраля';

      case 2:
        return 'Марта';

      case 3:
        return 'Апреля';

      case 4:
        return 'Мая';

      case 5:
        return 'Июня';

      case 6:
        return 'Июля';

      case 7:
        return 'Августа';

      case 8:
        return 'Сентября';

      case 9:
        return 'Октября';

      case 10:
        return 'Ноября';

      case 11:
        return 'Декабря';

      default:
        return '';
    }
  };

  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }

  if (hours.length === 1) {
    hours = `0${hours}`;
  }

  const monthName = getCurrentMonth(month);

  const getFullData = (seconds, minutes, hours, day, monthName) => {
    const dateString = isYear ? `${day} ${monthName} ${year}` : `${day} ${monthName}`;
    return isTime ? `${dateString} ${hours}:${minutes} ${isSeconds ? seconds : ''}` : dateString;
  };

  return getFullData(seconds, minutes, hours, day, monthName);
}

export default dateMaker;
