import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import Button from '@material-ui/core/Button';
import BugIcon from '@material-ui/icons/BugReport';
import DeadIcon from './components/simple/icons/dead';
import Preloader from './components/simple/preloader';
import { unRegisterReducer } from './store';

export default class extends PureComponent {
  static propTypes = {
    moduleProvider: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      Component: null,
      error: false,
    };
  }

  componentWillMount() {
    if (!this.state.Component) {
      if (this.props.customName) {
      }

      this.props
        .moduleProvider(this.props.customName)
        .then(({ Component }) => this.setState({ Component }));
    }
  }

  componentWillUnmount() {
    if (this.props.customName) {
      unRegisterReducer(this.props.customName);
    }
  }

  handleReportMessage = name => {
    Raven.captureMessage(`==ERROR== , body: ${this.state.error}`, {
      level: 'error',
    });

    Raven.showReportDialog({
      lang: 'ru',
      name: name || '',
      email: `${localStorage.getItem('USER_NAME')}@gepur.com`,
      labelComments: 'что случилось?',
      title: 'Похоже, что-то пошло не так...',
      subtitle: 'Разработчики уже в курсе.',
      subtitle2: 'Если вы хотите ускорить процесс, расскажите, что произошло ниже',
      labelSubmit: 'Отправить',
      successMessage: 'Ваш отзыв был отправлен. Спасибо!',
      errorFormEntry: 'Некоторые поля не заполнены. Исправьте ошибки и повторите попытку.',
    });
  };

  componentDidCatch(error) {
    this.setState({ error });

    Raven.captureMessage(`==ERROR: Component failed = , body: ${JSON.stringify(error)}`, {
      level: 'error',
    });
  }

  render() {
    const { Component, error } = this.state;
    const { widget } = this.props;

    if (error) {
      return widget ? (
        <div
          className="widget__error"
          onClick={() => this.handleReportMessage(`ошибка в виджете ${widget}`)}>
          <DeadIcon viewBox="0 0 44 44" />
        </div>
      ) : (
        <div className="error">
          <p>
            <BugIcon
              style={{
                width: '50px',
                height: '50px',
                float: 'left',
              }}
            />
            Ой! Что-то пошло не так...
            <br />
            Попробуйте обновить страницу.
            <br />
            Если не помогло, отправьте баг-репорт.
          </p>
          <Button color="primary" onClick={this.handleReportMessage}>
            Отправить баг-репорт
          </Button>
        </div>
      );
    }

    return Component ? <Component {...this.props} /> : <Preloader />;
  }
}
