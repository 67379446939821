import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import { convertToFlatArray } from 'utils/treeHelper';
import registerModules from 'modules';
import AsyncComponent from 'AsyncComponent';
import AnimationContainer from 'AnimationContainer';

import { ANIMATE_ACTIVITY } from './modules/System';

const renderRoute = ({ route, module, exact = true }) => (
  <Route
    key={route}
    path={route}
    exact={exact}
    component={location => <AsyncComponent moduleProvider={module} />}
  />
);

renderRoute.propTypes = {
  route: PropTypes.string.isRequired,
  module: PropTypes.func.isRequired,
  exact: PropTypes.bool,
};

renderRoute.defaultProps = {
  exact: true,
};

class Routes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      routes: [],
      animation: localStorage.getItem(ANIMATE_ACTIVITY) === 'active',
    };
  }

  componentWillReceiveProps(next) {
    const { data, location, isMobile } = this.props;

    if (next.data !== data || next.location !== location) {
      const arr = convertToFlatArray(next.data);

      // Переключение фич
      const typeCRM = localStorage.getItem('CRM_INTERFACE') || 'crm';
      const result = arr.map(item =>
        item.url === '/crm'
          ? {
              label: 'CRM',
              url: `/${typeCRM}`,
              children: [],
            }
          : item,
      );

      result.push(
        {
          label: 'Опции',
          url: '/options',
          children: [],
        },
        // {
        //   label: 'Расписание',
        //   url: '/staff',
        //   children: [],
        // },
      );

      if (isMobile) {
        result.push({ label: 'Menu', url: '/menu', children: [] });
        result.push({ label: 'Search', url: '/search', children: [] });
        result.push({ label: 'widgets', url: '/widgets', children: [] });
      }

      this.setState({ routes: result.map(this.renderRouting) });
    }
  }

  shouldComponentUpdate(next, nextState) {
    return (
      next.data !== this.props.data ||
      next.location !== this.props.location ||
      nextState.routes !== this.state.routes
    );
  }

  getAnimationStateBeforeRender() {
    const { animation } = this.state;
    const localStorageAnimation = localStorage.getItem(ANIMATE_ACTIVITY) === 'active';
    return animation || localStorageAnimation;
  }

  renderRouting = item => {
    const moduleComponent = registerModules[item.url];
    if (!moduleComponent) {
      // console.warn(`module not found for ${item.url}`);
      return false;
    }
    return renderRoute(moduleComponent);
  };

  render() {
    const { location, isMobile } = this.props;
    if (isMobile) {
      return (
        <div
          className="wrapper"
          style={{ padding: 0, width: '100vw', height: '100vh', overflow: 'auto' }}>
          <Switch location={location}>{this.state.routes}</Switch>
        </div>
      );
    }

    // const animation = this.getAnimationStateBeforeRender();
    return (
      <AnimationContainer animation={false} location={location}>
        <div className="wrapper" style={{ width: 'calc(100% - 56px)' }}>
          <div style={{ height: '100%' }}>
            <Switch location={location}>{this.state.routes}</Switch>
          </div>
        </div>
      </AnimationContainer>
    );
  }
}

Routes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withRouter(Routes);
