export const mainColors = [
  {
    color: '#83b0ff',
    text: 'Light Blue',
  },
  {
    color: '#efaf42',
    text: 'Orange',
  },
  {
    color: '#ff758f',
    text: 'Pink',
  },
  {
    color: '#cb91f0',
    text: 'Purple',
  },
  {
    color: '#4c9647',
    text: 'Green',
  },
];

export const widgetColors = ['#FF7055', '#ffd54f', '#97CE68'];

export const getNewMuiTheme = (color, theme) => ({
  palette: {
    type: theme,
    background: {
      paper: theme === 'dark' ? '#3a3a3a' : '#fff',
      default: theme === 'dark' ? '#2e2e2e' : '#efefef',
    },
    primary: {
      main: color,
      contrastText: theme === 'dark' ? '#fff' : '#000',
    },
    secondary: {
      main: color,
      contrastText: theme === 'dark' ? '#fff' : '#000',
    },
    error: {
      light: theme === 'dark' ? '#ff6e6e' : '#943838',
      main: theme === 'dark' ? '#ff6e6e' : '#943838',
      dark: theme === 'dark' ? '#ff6e6e' : '#943838',
      contrastText: theme === 'dark' ? '#fff' : '#000',
    },
  },
  typography: {
    fontSize: 14,
    useNextVariants: true,
  },
  overrides: {
    MuiAccordion: {
      root: {
        background: theme === 'dark' ? 'var(--color-background-f)' : 'var(--color-background)',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: theme === 'dark' ? '#26262a' : '#fff',
      },
    },
  },
});

export const invertColor = h => {
  let hex = h;
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);

  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);

  return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
};

export const padZero = (str, l) => {
  const len = l || 2;
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};

export const getLocalStyles = (color, theme) => {
  if (theme === 'light') {
    return {
      '--color-1': color,
      '--color-1-light': `${color}4d`,
      '--color-1-contrast': invertColor(color),
      '--color-1-contrast-light': `${invertColor(color)}4d`,
      '--color-text': '#000',
      '--color-sidebar': '#2e303c',
      '--color-sidebar-d': '#242531',
      '--color-separator': '#ddd',
      '--color-background': '#efefef',
      '--color-background-f': '#fff',
      backgroundColor: '#efefef',
      color: 'var(--color-text)',
    };
  }

  return {
    '--color-1': color,
    '--color-1-light': `${color}4d`,
    '--color-1-contrast': invertColor(color),
    '--color-1-contrast-light': `${invertColor(color)}4d`,
    '--color-text': '#fff',
    '--color-sidebar': '#25252a',
    '--color-sidebar-d': '#1b1b23',
    '--color-separator': '#4b4b50',
    '--color-background': '#15151b',
    '--color-background-f': '#25252a',
    backgroundColor: '#15151b',
    color: 'var(--color-text)',
  };
};
