import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { NavLink } from 'react-router-dom';
import { logOut as logOutAction } from 'modules/Login';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from '../../../modules/Modal';
import { addReminder as addReminderAction } from '../../../modules/System';
import Notification from '../notification';
import Reminders from '../reminders';
import SearchBox from '../search-box';
import UserMenu from '../../simple/user-menu';
import CreateOrderButton from '../create-order-button';
import './header.scss';

function Header({
  handleToggle,
  title,
  toolbar,
  user,
  fixed,
  open,
  logOut,
  openModal,
  closeModal,
  addReminder,
}) {
  return (
    <>
      <header className={`header ${fixed && 'fixed'} ${open && 'open'}`}>
        <AppBar
          position="static"
          style={{ background: 'var(--color-background-f)', height: '56px' }}>
          <Toolbar
            style={{
              minHeight: '56px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 8px 0 0',
            }}>
            <div className="header__title">
              {!open && (
                <IconButton onClick={handleToggle}>
                  <MenuIcon />
                </IconButton>
              )}
              <Typography style={{ padding: '0 22px' }} variant="h6" noWrap>
                {title || 'Gepur: Менеджер'}
              </Typography>
              {toolbar}
            </div>

            <div style={{ display: 'flex', alignItems: 'center', flex: '0 0 auto' }}>
              <CreateOrderButton
                openModal={openModal}
                closeModal={closeModal}
                style={{ padding: '12px' }}
              />
              <Notification />
              <Reminders openModal={openModal} closeModal={closeModal} addReminder={addReminder} />
              <SearchBox />
              <span className="header__separator" />
              <UserMenu>
                <NavLink to="/options">
                  <MenuItem>{user}</MenuItem>
                </NavLink>
                <NavLink to="/options">
                  <MenuItem>Опции</MenuItem>
                </NavLink>
                <Divider />
                <ListItem button onClick={logOut}>
                  <ListItemText>Выйти</ListItemText>
                </ListItem>
              </UserMenu>
            </div>
          </Toolbar>
        </AppBar>
      </header>
      {fixed && <div className="header__fake" style={{ height: '56px' }} />}
    </>
  );
}

Header.propTypes = {
  /**
   * LogOut
   */
  logOut: PropTypes.func.isRequired,
  /** Header title  */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Header toolbar actions  */
  toolbar: PropTypes.element,
  fixed: PropTypes.bool,
  open: PropTypes.bool,
  user: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  addReminder: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

Header.defaultProps = {
  title: 'Gepur',
  fixed: true,
  open: true,
  toolbar: '',
};

const mapState = state => ({
  title: state.header.title,
  subHeaderActions: state.header.subHeaderActions,
  toolbar: state.header.toolbar,
  user: state.user.username,
  theme: state.system.theme,
  color: state.system.color,
});

const mapDispatch = {
  logOut: logOutAction,
  openModal: openModalAction,
  closeModal: closeModalAction,
  addReminder: addReminderAction,
};

export default connect(
  mapState,
  mapDispatch,
)(Header);
